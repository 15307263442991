import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const Input = ({
  className,
  name,
  width,
  value,
  placeholder,
  type,
  disabled,
  min,
  state = "normal",
  onChange = () => {},
  onChangeRaw = () => {},
  onEnterKeyPress = () => {},
  onBlur = () => {},
}, ref) => {
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onEnterKeyPress(e);
    }
  };

  const handleOnBlur = (e) => {
    onBlur(e);
  };

  const handleOnChange = (e) => {
    onChange(e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    onChangeRaw(e);
  };

  const checked = () => {
    return type === 'checkbox' && value === true ? 'checked' : '';
  };

  return (
    <input
      className={classnames(
        styles.input,
        styles[state],
        className,
      )}
      style={
        width && {
          width,
        }
      }
      value={value}
      name={name}
      min={min}
      type={type}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleOnChange}
      onKeyPress={handleKeyPress}
      onBlur={handleOnBlur}
      ref={inputRef}
      checked={checked()}
    />
  );
};

const ForwardedInput = forwardRef(Input);

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeRaw: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  className: '',
  name: undefined,
  value: '',
  placeholder: '',
  type: 'text',
  disabled: false,
  onChange: () => {},
  onChangeRaw: () => {},
  onEnterKeyPress: () => {},
  onBlur: () => {},
};

export default ForwardedInput;
