import { useState, useEffect } from 'react';

import { isNull } from '../utils/utils';
import { isEmptyOrWhiteSpace } from '../utils/stringUtils';

const useInput = (initialValue) => {
  const [value, setValue] = useState(!isNull(initialValue) ? initialValue : '');
  const [validation, setValidation] = useState({
    isEmpty: false,
  });

  const onChange = (newValue) => {
    setValue(newValue);
  };

  const onChangeRaw = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const newValidation = {
      isEmpty: isEmptyOrWhiteSpace(value),
    };

    setValidation(newValidation);
  }, [value]);

  return {
    value,
    onChange,
    onChangeRaw,
    validation,
    setValue,
  };
};

export default useInput;
