import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const HamburgerIcon = ({
  onClick = () => {},
}) => (
  <div className={styles.container} onClick={onClick} onKeyDown={onClick}>
    <div className={styles.bar1} />
    <div className={styles.bar2} />
    <div className={styles.bar3} />
  </div>
);

HamburgerIcon.propTypes = {
  onClick: PropTypes.func,
};

export default HamburgerIcon;
