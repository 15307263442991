import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';
import themes from './themes.module.css';

const Button = ({
  className,
  theme,
  size,
  type,
  onClick = () => {},
  disabled = false,
  children,
}) => (
  <button
    className={classnames(
      styles.button,
      styles[size],
      themes[theme],
      className,
    )}
    type={type}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

Button.propTypes = {
  theme: PropTypes.oneOf('default', 'red', 'blue', 'action', 'filter'),
  size: PropTypes.oneOf('small', 'medium', 'large'),
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  className: '',
  theme: 'default',
  size: 'medium',
  type: 'button',
  onClick: () => {},
  disabled: false,
  children: '',
};

export default Button;
