import React, {
  createElement, createContext, useReducer,
} from 'react';
import PropTypes from 'prop-types';

import localesReducer from 'Common/reducers/localesReducer';

export const LocalesContext = createContext();

const initialState = {
  loading: false,
  locales: [],
};

export const LocalesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(localesReducer, initialState);

  return (
    <LocalesContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </LocalesContext.Provider>
  );
};

LocalesProvider.propTypes = {
  children: PropTypes.node,
};

LocalesProvider.defaultProps = {
  children: createElement('div'),
};

export const LocalesConsumer = LocalesContext.Consumer;
