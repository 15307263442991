import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useToasts } from 'react-toast-notifications';

import AccountManagerCreationForm from 'Admin/components/AccountManagerCreationForm';

import useInput from 'Common/hooks/useInput';
import useAuth from 'Common/hooks/useAuth';

import { isUndefined, isEmpty } from 'Common/utils/utils';
import { isAlphaOnly, isNumericalOnly, removeSpaces, isEmailAddress } from 'Common/utils/stringUtils';

const AccountManagerCreationFormContainer = ({
  open,
  onRequestClose,
  onCreationSuccess,
}) => {
  const { addToast } = useToasts();

  const {
    tokens: { accessToken },
  } = useAuth();

  const { value: firstname, onChange: onFirstnameChange } = useInput('');
  const { value: lastname, onChange: onLastnameChange } = useInput('');
  const { value: title, onChange: onTitleChange } = useInput('');
  const { value: emailAddress, onChange: onEmailAddressChange } = useInput('');
  const { value: telephone, onChange: onTelephoneChange } = useInput('');
  const { value: mobile, onChange: onMobileChange } = useInput('');

  const strings = {
    responseMessages: {
      failed: 'Unable to create account manager. Please check your inputs.',
    },
    validationMessages: {
      firstnameEmpty: 'A firstname must be provided',
      firstnameWhiteSpaceTrailing: 'Firstname cannot end with a space',
      firstnameNotAlpha: 'Firstname must only contain letters',
      lastnameWhiteSpaceTrailing: 'Lastname cannot end with a space',
      lastnameNotAlpha: 'Lastname must only contain letters',
      titleEmpty: 'A title must be provided',
      titleWhiteSpaceTrailing: 'Title cannot end with a space',
      titleNotAlpha: 'Title must only contain letters',
      emailAddressEmpty: 'An email address must be provided',
      emailAddressInvalid: 'Invalid email address',
      telephoneInvalid: 'Invalid telephone',
      mobileInvalid: 'Invalid mobile',
    },
  };

  const [inputErrors, setInputErrors] = useState({
    firstname: undefined,
    lastname: undefined,
    title: undefined,
    emailAddress: undefined,
    telephone: undefined,
    mobile: undefined,
  });
  const [errorMessage, setErrorMessage] = useState(undefined);

  const resetForm = () => {
    onFirstnameChange('');
    onLastnameChange('');
    onTitleChange('');
    onEmailAddressChange('');
    onTelephoneChange('');
    onMobileChange('');
    setInputErrors({
      firstname: undefined,
      lastname: undefined,
      title: undefined,
      emailAddress: undefined,
      telephone: undefined,
      mobile: undefined,
    });
    setErrorMessage(undefined);
  };

  const validateInputs = () => {
    const newInputErrors = {
      firstname: (() => {
        if (isEmpty(firstname)) {
          return strings.validationMessages.firstnameEmpty;
        }

        if (firstname.endsWith(' ')) {
          return strings.validationMessages.firstnameWhiteSpaceTrailing;
        }

        if (!isAlphaOnly(removeSpaces(firstname))) {
          return strings.validationMessages.firstnameNotAlpha;
        }

        return undefined;
      })(),
      lastname: (() => {
        if (isEmpty(lastname)) {
          return undefined;
        }

        if (lastname.endsWith(' ')) {
          return strings.validationMessages.lastnameWhiteSpaceTrailing;
        }

        if (!isAlphaOnly(removeSpaces(lastname))) {
          return strings.validationMessages.lastnameNotAlpha;
        }

        return undefined;
      })(),
      title: (() => {
        if (isEmpty(title)) {
          return strings.validationMessages.titleEmpty;
        }

        if (title.endsWith(' ')) {
          return strings.validationMessages.titleWhiteSpaceTrailing;
        }

        if (!isAlphaOnly(removeSpaces(title))) {
          return strings.validationMessages.titleNotAlpha;
        }

        return undefined;
      })(),
      emailAddress: (() => {
        if (isEmpty(emailAddress)) {
          return strings.validationMessages.emailAddressEmpty;
        }

        if (!isEmailAddress(emailAddress)) {
          return strings.validationMessages.emailAddressInvalid;
        }

        return undefined;
      })(),
      telephone: (() => (!isEmpty(telephone) && !isNumericalOnly(telephone)
        ? strings.validationMessages.telephoneInvalid
        : undefined))(),
      mobile: (() => (!isEmpty(mobile) && !isNumericalOnly(mobile)
        ? strings.validationMessages.mobileInvalid
        : undefined))(),
    };

    setInputErrors(newInputErrors);

    return (
      isUndefined(newInputErrors.firstname)
      && isUndefined(newInputErrors.lastname)
      && isUndefined(newInputErrors.title)
      && isUndefined(newInputErrors.emailAddress)
      && isUndefined(newInputErrors.telephone)
      && isUndefined(newInputErrors.mobile)
    );
  };

  const handleRequestClose = () => {
    resetForm();
    onRequestClose();
  };

  const handleCreateClick = async () => {
    try {
      if (!validateInputs()) {
        return;
      }

      const body = {
        firstName: firstname,
        lastName: lastname,
        emailAddress,
      };

      if (!isEmpty(title)) {
        body.title = title;
      }

      if (!isEmpty(telephone)) {
        body.telephone = telephone;
      }

      if (!isEmpty(mobile)) {
        body.mobile = mobile;
      }

      // TODO: Convert to null where appropriate
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/account-managers/`,
        body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

      addToast('Account Manager Successfully Created', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      resetForm();
      onCreationSuccess();
    } catch (e) {
      if (!isUndefined(e.response) && e.response.status === 400) {
        setErrorMessage(strings.responseMessages.failed);
      } else {
        addToast('Something went wrong when trying to create account manager', {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  };

  // Effects
  useEffect(() => {
    setInputErrors({
      firstname: undefined,
      lastname: undefined,
      title: undefined,
      emailAddress: undefined,
      telephone: undefined,
      mobile: undefined,
    });
    setErrorMessage(undefined);
  }, [firstname, lastname, title, emailAddress, telephone, mobile]);

  return (
    <AccountManagerCreationForm
      open={open}
      onRequestClose={handleRequestClose}
      onCreateClick={handleCreateClick}
      inputErrors={inputErrors}
      errorMessage={errorMessage}
      firstname={firstname}
      lastname={lastname}
      title={title}
      emailAddress={emailAddress}
      telephone={telephone}
      mobile={mobile}
      onFirstnameChange={onFirstnameChange}
      onLastnameChange={onLastnameChange}
      onTitleChange={onTitleChange}
      onEmailAddressChange={onEmailAddressChange}
      onTelephoneChange={onTelephoneChange}
      onMobileChange={onMobileChange}
    />
  );
};

AccountManagerCreationFormContainer.propTypes = {

};

AccountManagerCreationFormContainer.defaultProps = {

};

export default AccountManagerCreationFormContainer;
