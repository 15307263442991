import React, {
  createElement, createContext, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import categoriesReducer from '../reducers/categoriesReducer';

export const CategoriesContext = createContext();

const initialState = {
  loading: false,
  categories: [],
};

export const CategoriesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(categoriesReducer, initialState);

  return (
    <CategoriesContext.Provider
      value={{
        state,
        dispatch: useCallback(
          (...args) => {
            dispatch(...args);
          },
          [dispatch],
        ),
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

CategoriesProvider.propTypes = {
  children: PropTypes.node,
};

CategoriesProvider.defaultProps = {
  children: createElement('div'),
};

export const CategoriesConsumer = CategoriesContext.Consumer;
