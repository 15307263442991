const categoriesReducer = (state, action) => {
  const { payload } = action;

  console.log(action.type);
  switch (action.type) {
    // Fetch Categories
    case 'fetch_categories_requested':
      return {
        ...state,
        loading: true,
      };
    case 'fetch_categories_successful':
      return {
        ...state,
        loading: false,
        categories: payload.categories
      };
    case 'fetch_categories_failed':
      return {
        ...state,
        loading: false,
        categories: undefined,
      };

    default:
      return state;
  }
};

export default categoriesReducer;
