import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const DashboardOptionsSection = ({
  title,
  glyphicon,
  children,
}) => (
  <div className={styles.section}>
    <div>
      {glyphicon && (
        <span className={styles.icon` glyphicons ${glyphicon}`} />
      )}
      <h1 className={styles.title}>{title.toUpperCase()}</h1>
      <hr className={styles.line} />
    </div>
    {children}
  </div>
);

DashboardOptionsSection.propTypes = {
  title: PropTypes.string,
  glyphicon: PropTypes.string,
  children: PropTypes.node,
};

DashboardOptionsSection.defaultProps = {
  title: '',
  glyphicon: null,
  children: createElement('div'),
};

export default DashboardOptionsSection;
