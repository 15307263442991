import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

import { isEmpty } from 'Common/utils/utils';
import routeMappings from 'Admin/routeMappings.js';

import styles from './styles.module.css';

const OptionGroup = ({
  title = '',
  options = [],
}) => (
  <div className={styles.wrapper}>
    <h2 className={styles.title}>
      {title.toUpperCase()}
    </h2>

    <ul className={styles.list}>

      {options.map((option) => (
        <li
          key={option.title}
        >
          {
            isEmpty(option.redirect)
              ? (
                <Link
                  to={option.redirect}
                  className={styles.option}
                >
                  {option.title}
                </Link>
              ) : (
                <NavLink
                  activeClassName={styles.active}
                  to={option.redirect}
                  className={styles.option}
                >
                  {option.title}
                </NavLink>
              )
        }
        </li>
      ))}
    </ul>
  </div>
);

OptionGroup.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
};

export default OptionGroup;
