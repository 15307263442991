import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

import { useToasts } from 'react-toast-notifications';

import AccountManagerReplacementForm from 'Admin/components/AccountManagerReplacementForm';

import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAccountManagers from 'Admin/hooks/useAccountManagers';

import { isNull, isNullOrEmpty } from 'Common/utils/utils';

const AccountManagerReplacementFormContainer = ({
  open,
  onRequestClose,
  onReplacementSuccess,
  sourceAccountManager,
}) => {
  const { addToast } = useToasts();

  const [destinationAccountManager, setDestinationAccountManager] = useState(undefined);

  const [accountManagerSearchLink, setAccountManagerSearchLink] = useState(null);
  const { results: accountManagers, loading: loadingAccountManagers } = usePagedFetch(accountManagerSearchLink);

  const [accountManagerSearchElapseTimeout, setAccountManagerSearchElapseTimeout] = useState(0);

  const { replace: replaceAccountManager, replacing: replacingAccountManager } = useAccountManagers();

  const [calledFetchDefaultAccountManagers, setCalledFetchDefaultAccountManagers] = useState(false);

  // Functions
  const resetForm = () => {
    setAccountManagerSearchLink(null);
    setDestinationAccountManager(undefined);
    setCalledFetchDefaultAccountManagers(false);
  };

  const replaceSourceAccountManager = async () => {
    try {
      await replaceAccountManager(`${process.env.REACT_APP_API_URL}/v1.0/account-managers/${sourceAccountManager.id}/replace`, destinationAccountManager.id);

      addToast('Successfully transferred account manager\'s reponsibilities', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      resetForm();
      onReplacementSuccess();
    } catch (e) {
      addToast('Something went wrong when trying to transfer account manager\'s reponsibilities', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const fetchAccountManagers = (search) => {
    clearTimeout(accountManagerSearchElapseTimeout);

    setAccountManagerSearchElapseTimeout(setTimeout(
      () => {
        const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/account-managers`);
        const params = {
          sortBy: 'name',
          sortDescending: true,
          pageSize: 20,
        };

        if (!isNullOrEmpty(search)) {
          params.search = search;
        }

        url.search = new URLSearchParams(params);
        setAccountManagerSearchLink(url.href);
      },
      500,
    ));
  };

  // Handlers
  const handleRequestClose = () => {
    onRequestClose();
  };

  const handleAccountManagerSearchChange = (e) => {
    setCalledFetchDefaultAccountManagers(false);

    const { value } = e.target;

    if (value.length > 2) {
      fetchAccountManagers(value);
    }
  };

  const handleAccountManagerSearchSelected = async (event, data) => {
    const { value: accountManagerId } = data;

    const accountManager = accountManagers.find((x) => x.id === accountManagerId);
    setDestinationAccountManager(accountManager);

    setAccountManagerSearchLink(null);
  };

  const handleAccountManagerSearchClick = () => {
    setAccountManagerSearchLink(null);
  };

  const handleReplaceClick = () => {
    confirmAlert({
      title: 'Confirm to replace',
      message: 'Are you sure you want to transfer all responsibilities to this account manager?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => replaceSourceAccountManager(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  // Effects
  useEffect(() => {
    if (isNull(accountManagerSearchLink) && !calledFetchDefaultAccountManagers) {
      fetchAccountManagers(null);
      setCalledFetchDefaultAccountManagers(true);
    }
  }, [accountManagerSearchLink, calledFetchDefaultAccountManagers, fetchAccountManagers]);

  return (
    <AccountManagerReplacementForm
      open={open}
      onRequestClose={handleRequestClose}
      sourceAccountManager={sourceAccountManager}
      destinationAccountManager={destinationAccountManager}
      onAccountManagerSearchChange={handleAccountManagerSearchChange}
      onAccountManagerSearchSelected={handleAccountManagerSearchSelected}
      onAccountManagerSearchClick={handleAccountManagerSearchClick}
      onReplaceClick={handleReplaceClick}
      replacing={replacingAccountManager}
      accountManagersOptions={
        accountManagers
          .filter((accountManager) => accountManager.id !== sourceAccountManager.id)
          .map((accountManager) => ({
            key: accountManager.id,
            text: `${accountManager.firstName} ${accountManager.lastName}`,
            value: accountManager.id,
          }))
      }
    />
  );
};

AccountManagerReplacementFormContainer.propTypes = {

};

AccountManagerReplacementFormContainer.defaultProps = {

};

export default AccountManagerReplacementFormContainer;
