import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImage from './styles';

const BackgroundImageComponent = ({
  src,
}) => (
  <BackgroundImage src={src} />
);

BackgroundImageComponent.propTypes = {
  src: PropTypes.node.isRequired,
};

export default BackgroundImageComponent;
