import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import axios from 'axios';

import { Row, Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert';

import PageTitle from 'Common/components/PageTitle';
import Input from 'Common/components/Input';
import Space from 'Common/components/Space';
import Paragraph from 'Common/components/Paragraph';
import CategoryTree from 'Admin/components/CategoryTree';
import Button from 'Common/components/Button';
import TreeNode from 'Common/components/TreeNode';
import Text from 'Common/components/Text';
import Heading from 'Common/components/Heading';
import Tag from 'Common/components/Tag';
import TranslationInput from 'Admin/components/TranslationInput';
import SearchableDropdown from 'Common/components/SearchableDropdown';
import Panel from 'Admin/components/Panel';

import CategoryCreationFormContainer from 'Admin/containers/CategoryCreationFormContainer';

import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useCategories from 'Admin/hooks/useCategories';
import useAuth from 'Common/hooks/useAuth';
import useLocales from 'Common/hooks/useLocales';
import useInput from 'Common/hooks/useInput';

import { isUndefined, isNull, isEmpty, nestedCopy } from 'Common/utils/utils';
import { containsString } from 'Common/utils/stringUtils';

const CategoriesPage = () => {
  const { addToast } = useToasts();

  const { categories: { strings, loading: loadingStrings }, fetchCategoriesStrings } = useStrings();
  const { user } = useUser();
  const {
    tokens: { accessToken },
  } = useAuth();

  const { categories, loading: loadingCategories, fetch: fetchCategories, delete: deleteCategories } = useCategories();
  const { locales, loading: loadingLocales, fetch: fetchLocales } = useLocales();

  const [tagAttributesLink, setTagAttributesLink] = useState(null);
  const [tagTranslationsLink, setTagTranslationsLink] = useState(null);
  const { results: tagAttributes, loading: loadingTagAttributes, refetch: refetchTagAttributes } = usePagedFetch(tagAttributesLink);
  const { results: tagTranslations, loading: loadingTagTranslations } = usePagedFetch(tagTranslationsLink);

  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const [tagTranslationInputs, setTagTranslationInputs] = useState([]);

  const [calledFetchCategories, setCalledFetchCategories] = useState(false);
  const [calledFetchLocales, setCalledFetchLocales] = useState(false);

  const { value: categorySearch, onChange: onCategorySearchChange } = useInput('');

  const [attributesSearchLink, setAttributeSearchLink] = useState(null);
  const { results: attributes, loading: loadingAttributes } = usePagedFetch(attributesSearchLink);

  const [attributeSearchElapseTimeout, setAttributeSearchElapseTimeout] = useState(0);

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const [creationParentCategory, setCreationParentCategory] = useState(undefined);

  // Functions
  const hasTagTranslationsChanged = () => tagTranslationInputs.some(x => x.originalValue !== x.value);

  const removeCategory = async (id) => {
    try {
      const category = categories.find((x) => x.id === id);
      await deleteCategories(category.links.find((x) => x.rel === 'delete').href);
      // TODO: Remove category from store
      fetchCategories(`${process.env.REACT_APP_API_URL}/v1.0/tags`);

      setSelectedCategory(undefined);

      addToast('Category Successfully Deleted', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      console.log(e);
      addToast('Something went wrong when trying to delete category', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  }

  // Handlers
  const handleCategoryClick = (id) => {
    const category = categories.find((x) => x.id === id);
    setSelectedCategory(category);
  };

  const handleCategoryDeleteClick = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this category?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => removeCategory(id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleTagAttributeDismissClick = async (tagId, attributeId) => {
    try {
      const tagAttribute = tagAttributes.find((x) => x.tag.id === tagId && x.attribute.id === attributeId);

      await axios.delete(tagAttribute.links.find((x) => x.rel === 'delete').href, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      await refetchTagAttributes();
      addToast('Tag Attribute Successfully Removed', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      addToast('Something went wrong when trying to remove tag attribute', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const handleSaveTagTranslations = async () => {
    try {
      const results = [];
      for (const tagTranslation of tagTranslationInputs) {
        if (tagTranslation.originalValue !== tagTranslation.value) {
          const locale = locales.find((x) => x.id === tagTranslation.id);

          results.push(axios.put(`${process.env.REACT_APP_API_URL}/v1.0/tags/${selectedCategory.id}/translations/${locale.isoLocale}`, {
            title: tagTranslation.value,
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }));
        }
      }

      await Promise.all(results);

      setTagTranslationInputs(tagTranslationInputs.map((tagTranslation) => ({
        ...tagTranslation,
        originalValue: tagTranslation.value,
      })))

      addToast('Tag Translations Successfully Updated', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch {
      addToast('Something went wrong when trying to update tag translations', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const handleTagTranslationChange = (localeId, value) => {
    const newInputs = nestedCopy(tagTranslationInputs);

    var index = newInputs.findIndex(x => x.id === localeId);
    newInputs[index].value = value;

    setTagTranslationInputs(newInputs);
  };

  const handleAttributeSearchChange = (e) => {
    const { value } = e.target;

    if (value.length > 2) {
      clearTimeout(attributeSearchElapseTimeout);

      setAttributeSearchElapseTimeout(setTimeout(
        () => {
          const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/attributes`);
          const params = {
            search: value,
            sortBy: 'name',
            sortDescending: true,
          };
          url.search = new URLSearchParams(params);
          setAttributeSearchLink(url.href);
        },
        500,
      ));
    }
  };

  const handleAttributeSearchSelected = async (event, data) => {
    const { value: attributeId } = data;

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tags/${selectedCategory.id}/attributes/${attributeId}`);

      refetchTagAttributes();

      addToast('Attribute Successfully Added To Category', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch {
      addToast('Something went wrong when trying to add attribute to category', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setAttributeSearchLink(null);
  };

  const handleAttributeSearchClick = (e) => {
    setAttributeSearchLink(null);
  };

  const handleAddCategoryClick = (parentId) => {
    const category = categories.find((x) => x.id === parentId);

    setCreationParentCategory(category && {
      id: category.id,
      level: category.level,
      title: category.title
    });

    setCreationFormOpen(true);
  };

  const handleCreationSuccess = async () => {
    try {
      fetchCategories(`${process.env.REACT_APP_API_URL}/v1.0/tags`);
    } catch {

    }
    closeModals();

  };

  const closeModals = () => {
    setCreationFormOpen(false);
  };

  // Effects
  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchCategoriesStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchCategoriesStrings]);

  // Fetch Locales
  useEffect(() => {
    if (!calledFetchLocales) {
      fetchLocales();
      setCalledFetchLocales(true);
    }
  }, [calledFetchLocales, fetchLocales]);

  // Fetch Categories
  useEffect(() => {
    if (!calledFetchCategories) {
      fetchCategories(`${process.env.REACT_APP_API_URL}/v1.0/tags`);
      setCalledFetchCategories(true);
    }
  }, [calledFetchCategories, fetchCategories]);

  useEffect(() => {
    if (!isUndefined(selectedCategory)) {
      setTagAttributesLink(`${process.env.REACT_APP_API_URL}/v1.0/tags/${selectedCategory.id}/attributes`);
      setTagTranslationsLink(`${process.env.REACT_APP_API_URL}/v1.0/tags/${selectedCategory.id}/translations`);
    } else {
      setTagTranslationsLink(null);
      setAttributeSearchLink(null);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const inputs = locales.map((locale) => {
      const tagTranslation = tagTranslations.find((x) => x.isoLocale === locale.isoLocale);

      const value = !isUndefined(tagTranslation) ? tagTranslation.title : '';

      return {
        id: locale.id,
        originalValue: value,
        value,
      };
    });

    setTagTranslationInputs(inputs);
  }, [locales, tagTranslations]);

  const filterCategoryTreeBySearch = (tree, search) => {
    const filteredTree = [];

    let i = 0;
    for (i = 0; i < tree.length; i += 1) {
      const node = tree[i];

      if (containsString(node.title, search)) {
        filteredTree.push(node);
        continue;
      }

      const filteredChildren = filterCategoryTreeBySearch(node.children, search);

      if (filteredChildren.length > 0) {
        node.children = filteredChildren;
        filteredTree.push(node);
      }
    }

    return filteredTree;
  };

  const composeCategoryTree = () => {
    let categoriesTree = nestedCopy(categories);

    categoriesTree.forEach((x) => {
      const category = x;

      category.selected = !isUndefined(selectedCategory) ? category.id === selectedCategory.id : false;

      if (category.children === undefined) {
        category.children = [];
      }

      if (category.parentTagId !== null && category.parentTagId > 0) {
        const parentCategory = categoriesTree.find((y) => y.id === category.parentTagId);

        if (parentCategory.children === undefined) {
          parentCategory.children = [];
        }

        parentCategory.children.push(category);
      }
    });

    categoriesTree = categoriesTree.filter((x) => x.parentTagId === null || x.parentTagId === 0)

    if (!isEmpty(categorySearch)) {
      categoriesTree = filterCategoryTreeBySearch(categoriesTree, categorySearch);
    }

    return categoriesTree;
  };

  let categoryTreeComponent = useMemo(() => {
    return <CategoryTree
      categories={composeCategoryTree()}
      onClick={handleCategoryClick}
      onAddClick={handleAddCategoryClick}
      onDeleteClick={handleCategoryDeleteClick}
    />;
  }, [categories, selectedCategory, categorySearch]);

  console.log("render")

  const tagTranslationComponents = useMemo(() => {
    return locales.map((locale) => {
      const input = tagTranslationInputs.find((x) => x.id === locale.id);

      if (isUndefined(input)) {
        return null;
      }

      return (
        <Row>
          <TranslationInput
            className="my-3"
            title={locale.name}
            value={input.value}
            countryCode={locale.countryCode}
            onChange={(value) => handleTagTranslationChange(locale.id, value)}
          />
        </Row>
      );
    })
  }, [locales, tagTranslationInputs]);

  // Rendering
  if (isNull(strings)) {
    return null;
  }

  return (
    <div>
      <Row>
        <PageTitle>{strings.title}</PageTitle>
        <Space height="20px" />
        <Paragraph>
          {strings.description}
        </Paragraph>
      </Row>

      <Space height="2em" />

      <Row>
        <Col md={4} lg={3}>
          <Row>
            <Panel
              className="p-5"
              theme="grey"
              scrollable
              height="50em"
            >
              <Input
                placeholder="Search"
                value={categorySearch}
                onChange={onCategorySearchChange}
                width="100%"
              />

              <Space height="2em" />

              {categoryTreeComponent}
            </Panel>
          </Row>
        </Col>
        <Col
          md={8}
          className="m-5"
        >
          {!isUndefined(selectedCategory) ? (
            <>
              <Row>
                <Heading
                  title={selectedCategory.title}
                />
              </Row>
              <Row
                className="mx-5"
              >
                <Text
                  bold
                  size="large"
                >
                  Attributes
                </Text>
                <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </Paragraph>

                <Col>
                  <Row
                    className="my-3"
                  >
                    <SearchableDropdown
                      width="20em"
                      onSearchChange={handleAttributeSearchChange}
                      onChange={handleAttributeSearchSelected}
                      onClick={handleAttributeSearchClick}
                      placeholder="Search Attributes"
                      options={
                        attributes
                          .filter((attribute) => !tagAttributes.some((tagAttribute) => tagAttribute.attribute.id === attribute.id))
                          .map((attribute) => ({
                            key: attribute.id,
                            text: attribute.name,
                            value: attribute.id,
                          }))
                      }
                    />
                  </Row>

                  <Space height="1em" />

                  <Row>
                    {tagAttributes.map((tagAttribute) => (
                      <Tag
                        className="mt-3"
                        tagId={tagAttribute.tag.id}
                        attributeId={tagAttribute.attribute.id}
                        text={tagAttribute.attribute.name}
                        onDismissClick={handleTagAttributeDismissClick}
                      />
                    ))}
                    {tagAttributes.length === 0 && (
                      <Text>No attributes have been like to category</Text>
                    )}
                  </Row>
                </Col>
              </Row>

              <Space height="3em" />

              <Row
                className="mx-5 my-2"
              >
                <Col md={9} lg={7}>
                  <Row>
                    <Text
                      bold
                      size="large"
                    >
                      Translations
                    </Text>
                  </Row>

                  <Space height="1em" />

                  {tagTranslationComponents}

                  <Space height="1em" />

                  <Row
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                    }}
                  >
                    <Button
                      theme="action"
                      disabled={!hasTagTranslationsChanged()}
                      onClick={handleSaveTagTranslations}
                    >
                      Save
                    </Button>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Text>No category has been selected.</Text>
            </>
          )}
        </Col>
      </Row>

      <CategoryCreationFormContainer
        open={creationFormOpen}
        parentCategory={creationParentCategory}
        onCreationSuccess={handleCreationSuccess}
        onRequestClose={closeModals}
      />
    </div>
  );
};

export default CategoriesPage;
