import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link';

import styles from './styles.module.css';

import bulletPoint from '../../assets/images/dashboard-option-bullet-point.png';

const DashboardOption = ({
  title,
  description,
  redirect,
}) => (
  <div className={styles.wrapper}>
    <img src={bulletPoint} className={styles.buttetPoint} alt="bullet point" />
    <div className={styles.infoWrapper}>
      <Link
        className={styles.title}
        to={redirect}
      >
        {title}
        {!redirect && (
          <span className={styles.unavilable}> - (unavailable)</span>
        )}
      </Link>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
);

DashboardOption.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  redirect: PropTypes.string,
};

DashboardOption.defaultProps = {
  description: '',
  redirect: '',
};

export default DashboardOption;
