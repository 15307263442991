import React, {
  createElement, createContext, useReducer,
} from 'react';
import PropTypes from 'prop-types';

import clientReducer from 'Common/reducers/clientReducer';

export const ClientContext = createContext();

const initialState = {
  loading: false,
  branch: undefined,
};

export const ClientProvider = ({ children }) => {
  const [state, dispatch] = useReducer(clientReducer, initialState);

  return (
    <ClientContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

ClientProvider.propTypes = {
  children: PropTypes.node,
};

ClientProvider.defaultProps = {
  children: createElement('div'),
};

export const ClientConsumer = ClientContext.Consumer;
