import React, { useState, useEffect, useMemo } from 'react';
import { ToastProvider } from 'react-toast-notifications';

import useAuth from 'Common/hooks/useAuth';

import Routes from './routes';

const App = () => {
  const [intialized, setInitialized] = useState();
  const { refreshTokens } = useAuth();

  useEffect(() => {
    const intiializeApplication = async () => {
      try {
        await refreshTokens();
      } catch (e) {
      }

      setInitialized(true);
    };

    if (!intialized) {
      intiializeApplication();
    }
  }, [intialized, refreshTokens]);

  return useMemo(() => (
    <div>
      {intialized && (
        <ToastProvider>
          <Routes />
        </ToastProvider>
      )}
    </div>
  ), [intialized]);
};
export default App;
