import React, { useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import axios from 'axios';

import useAuth from 'Common/hooks/useAuth';
import useUser from 'Common/hooks/useUser';
import useBranch from 'Common/hooks/useBranch';
import useClient from 'Common/hooks/useClient';

import { isAdmin } from 'Common/utils/authUtils';

import AdminApp from 'Admin';

const Main = () => {
  const { fetch: fetchUser } = useUser();
  const { fetch: fetchBranch } = useBranch();
  const { fetch: fetchClient } = useClient();

  const { claims: { userId, role }, logout, refreshTokens } = useAuth();

  axios.interceptors.response.use((response) => response,
    async (error) => {
      try {
        const originalRequest = error.config;

        if (error.response.status === 401
          && originalRequest.url === `${process.env.REACT_APP_API_URL}/v1.0/tokens/refresh`) {
          logout();
          return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;
          const newTokens = await refreshTokens();
          originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      } catch (e) {
        console.log(e);
        return Promise.reject(error);
      }
    });

  useEffect(() => {
    const fetchInitialUserInfo = async () => {
      const user = await fetchUser(`${process.env.REACT_APP_API_URL}/v1.0/users/${userId}`);
      fetchBranch(user.branch.links.find((x) => x.rel === 'self').href);
      fetchClient(user.client.links.find((x) => x.rel === 'self').href);
    };

    fetchInitialUserInfo();
  }, [userId, fetchUser, fetchBranch, fetchClient]);

  return useMemo(() => (
    <div className="main">
      {isAdmin("Administrator")
        ? (
          <AdminApp />
        ) : (
          <Route
            path="/account"
            component={() => {
              window.location.href = process.env.REACT_APP_LEGACY_ACCOUNT_ROUTE;
              return null;
            }}
          />
        )}
    </div>
  ), [role]);
};

export default Main;
