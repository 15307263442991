import React from 'react';
import { Switch, Route } from 'react-router-dom';

import authRoute from 'Common/hocs/routing/authRoute';

import Dashboard from 'Admin/pages/Dashboard';
import Clients from 'Admin/pages/Clients';
import Orders from 'Admin/pages/Orders';
import Roles from 'Admin/pages/Roles';
import MyAccount from 'Admin/pages/MyAccount';
import Categories from './pages/Categories';
import AccountManagers from './pages/AccountManagers';
import PriceAdjustments from './pages/PriceAdjustments';
import PriceAdjustment from './pages/PriceAdjustment';

const Router = () => (
  <Switch>
    <Route path="/admin/dashboard" component={authRoute(Dashboard)} />
    <Route path="/admin/clients" component={authRoute(Clients)} />
    <Route path="/admin/orders" component={authRoute(Orders)} />
    <Route path="/admin/roles" component={authRoute(Roles)} />
    <Route path="/my-account" component={authRoute(MyAccount)} />
    <Route path="/admin/categories" component={authRoute(Categories)} />
    <Route path="/admin/account-managers" component={authRoute(AccountManagers)} />

    <Route exact path="/admin/price-adjustments" component={authRoute(PriceAdjustments)} />
    <Route exact path="/admin/price-adjustment/create" component={authRoute(PriceAdjustment)} />
    <Route path="/admin/price-adjustment/:id" component={authRoute(PriceAdjustment)} />
  </Switch>
);

export default Router;
