import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const HorizontalOptionSelector = ({
  options,
  selectedOption,
  onClick,
}) => (
  <div>
    {options.map((option) => (
      <button
        type="button"
        className={classnames(
          styles.option,
          'mr-1',
          (option.value === selectedOption) && styles.selected,
        )}
        onClick={() => onClick(option.value)}
      >
        {option.label}
      </button>
    ))}
  </div>
);

HorizontalOptionSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  selectedOption: PropTypes.string,
  onClick: PropTypes.func,
};

HorizontalOptionSelector.defaultProps = {
  options: [],
  selectedOption: null,
  onClick: () => {},
};

export default HorizontalOptionSelector;
