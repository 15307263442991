import React, { createElement, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import stringsReducer from 'Admin/reducers/stringsReducer';

export const StringsContext = createContext();

const subStateTemplate = {
  loading: false,
  strings: null,
};

const initialState = {
  languageCode: undefined,
  sidebar: subStateTemplate,
  dashboard: subStateTemplate,
  orders: subStateTemplate,
  clients: subStateTemplate,
  roles: subStateTemplate,
  myAccount: subStateTemplate,
  categories: subStateTemplate,
  accountManagers: subStateTemplate,
  priceAdjustment: subStateTemplate,
  priceAdjustments: subStateTemplate,
};

export const StringsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stringsReducer, initialState);

  return (
    <StringsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </StringsContext.Provider>
  );
};

StringsProvider.propTypes = {
  children: PropTypes.node,
};

StringsProvider.defaultProps = {
  children: createElement('div'),
};

export const StringsConsumer = StringsContext.Consumer;
