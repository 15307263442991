import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useAuth from 'Common/hooks/useAuth';
import usePagedFetch from 'Common/hooks/usePagedFetch';

import PageSelector from 'Common/components/PageSelector';
import Text from 'Common/components/Text';
import Space from 'Common/components/Space';
import Button from 'Common/components/Button';
import InfoBox from 'Admin/components/PriceAdjustments/InfoBox';
import PriceAdjustmentStatusTag from 'Admin/components/PriceAdjustments/PriceAdjustmentStatusTag';
import GlobalItemAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalItemAdjustmentSettingsBox';
import ItemExclusionsTable from 'Admin/components/PriceAdjustments/ItemExclusionsTable';
import ItemAdjustmentsTable from 'Admin/components/PriceAdjustments/ItemAdjustmentsTable';
import GlobalClientAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalClientAdjustmentSettingsBox';
import ClientExclusionsTable from 'Admin/components/PriceAdjustments/ClientExclusionsTable';
import ClientAdjustmentsTable from 'Admin/components/PriceAdjustments/ClientAdjustmentsTable';

import priceAdjustmentState from 'Admin/constants/priceAdjustmentState';

import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { toLocalDateTime } from 'Common/utils/dateUtils';
import { isEmptyOrWhiteSpace } from 'Common/utils/stringUtils';

const PriceAdjustmentReviewTab = ({
  priceAdjustment,
  updatePriceAdjustment,
  strings: { review: strings, executionTypes: executionTypeStrings },
}) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    tokens: { accessToken },
  } = useAuth();
  const { addToast } = useToasts();


  const [itemAdjustmentsPage, setItemAdjustmentsPage] = useState(1);
  const [clientAdjustmentsPage, setClientAdjustmentsPage] = useState(1);

  const { results: itemAdjustments, loading: loadingItemAdjustments, currentPage: currentItemAdjustmentPage, totalPages: totalCurrentItemAdjustmentPages } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/items?pageSize=30&page=${itemAdjustmentsPage}`);
  const { results: clientAdjustments, loading: loadingClientAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/clients?pageSize=30&page=${clientAdjustmentsPage}`);

  const { results: itemExclusions, loading: loadingItemExclusions, currentPage: currentClientAdjustmentPage, totalPages: totalCurrentClientAdjustmentPages } = usePagedFetch(priceAdjustment.adjustAllItems
    ? `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/exclusions/items?pageSize=1000`
    : '');

  const { results: clientExclusions, loading: loadingClientExclusions } = usePagedFetch(priceAdjustment.adjustAllClients
    ? `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/exclusions/clients?pageSize=1000`
    : '');

  const hasAtLeast1ItemAdjustment = () => {
    if (itemAdjustments.length === 0 && !priceAdjustment.adjustAllItems) {
      return false;
    }

    return true;
  };

  const canBeConfirmed = () => {
    if (priceAdjustment.state !== priceAdjustmentState.draft) {
      return false;
    }

    if (loadingItemAdjustments) {
      return false;
    }

    if (!hasAtLeast1ItemAdjustment()) {
      return false;
    }

    return true;
  };

  // Handlers
  const handleConfirmClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/confirm`, {
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      updatePriceAdjustment({
        ...priceAdjustment,
        state: 'Confirmed',
      });

      addToast(strings.toastMessages.successfulConfirm, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      window.scrollTo(0, 0);
    } catch (e) {
      addToast(strings.toastMessages.failedConfirm, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const handleCancelClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/cancel`, {
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      updatePriceAdjustment({
        ...priceAdjustment,
        state: 'Draft',
      });

      addToast(strings.toastMessages.successfulCancel, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      // TODO: Update
    } catch (e) {
      addToast(strings.toastMessages.failedCancel, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const handleItemAdjustmentPageChange = (pageNumber) => {
    setItemAdjustmentsPage(pageNumber);
  };

  const handleClientAdjustmentPageChange = (pageNumber) => {
    setClientAdjustmentsPage(pageNumber);
  };

  return (
    <div>
      <Row className="justify-content-md-center">
        <Col md={7}>

          {!canBeConfirmed() && (
            <Row>
              <Col>
                <InfoBox className="text-align-center" theme="error">
                  <div className="text-align-center">
                    <Text
                      bold
                      theme="black"
                    >
                      {strings.invalidPriceAdjustmentAlert.title}
                    </Text>
                  </div>
                  <Text
                    className="text-align-center"
                    size="x-small"
                    theme="black"
                  >
                    {strings.invalidPriceAdjustmentAlert.message}
                  </Text>
                </InfoBox>
              </Col>
            </Row>
          )}


          {priceAdjustment.adjustAllClients && (
            <Row>
              <Col>
                <InfoBox theme="warning">
                  <div className="text-align-center">
                    <Text
                      bold
                      theme="black"
                    >
                      {strings.processingDurationWarning.title}
                    </Text>
                  </div>
                  <Text
                    size="x-small"
                    theme="black"
                  >
                    {strings.processingDurationWarning.message}
                  </Text>
                </InfoBox>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <h2>{strings.subHeadings.priceAdjustment}</h2>

              <Row className="my-">
                <Col xs={10}>
                  <Text size="small">{id}</Text>
                </Col>
                <Col xs={2}>
                  <PriceAdjustmentStatusTag
                    status={priceAdjustment.state}
                    statusText={priceAdjustment.state}
                  />
                </Col>
              </Row>

              <div className="my-4">
                <div><Text size="small" theme="black" bold>{strings.labels.title}</Text></div>
                <Text size="small">{priceAdjustment.title}</Text>
              </div>

              <div className="my-4">
                <Text className="mr-2" theme="black" size="small" bold>{`${strings.labels.scheduledFor}:`}</Text>
                <Text size="small">
                  {(priceAdjustment.executionType === priceAdjustmentExecutionType.immediate)
                    ? executionTypeStrings.immediate
                    : (toLocalDateTime(priceAdjustment.scheduledDate))}
                </Text>
              </div>

              {!isEmptyOrWhiteSpace(priceAdjustment.reason) && (
                <div className="my-4">
                  <div><Text size="small" theme="black" bold>{strings.labels.reason}</Text></div>
                  <Text size="small">{priceAdjustment.reason}</Text>
                </div>
              )}

              {priceAdjustment.state !== priceAdjustmentState.draft && (
                <div className="my-5">
                  <h3>{strings.cancel.title}</h3>
                  <div>
                    <Text bold italic size="small">Notice: </Text>
                    <Text italic size="small">{strings.cancel.message}</Text>
                  </div>
                  <div className="mt-2">
                    <Button
                      theme="red"
                      onClick={handleCancelClick}
                    >
                      {strings.cancel.button}
                    </Button>
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Space height="2em" />

          <Row>
            <Col>
              <h2>{strings.subHeadings.itemAdjustments}</h2>

              {priceAdjustment.adjustAllItems && (
                <>
                  <GlobalItemAdjustmentSettingsBox
                    applyToAll={priceAdjustment.adjustAllItems}
                    adjustBasePrice={priceAdjustment.globalItemAdjustment.adjustBasePrice}
                    adjustmentPercentage={priceAdjustment.globalItemAdjustment.adjustmentPercentage}
                    strings={{
                      title: strings.globalItemAdjustment.title,
                      applyToAll: strings.globalItemAdjustment.applyToAll,
                      adjustBasePrice: strings.globalItemAdjustment.adjustBasePrice,
                      adjustmentPercentage: strings.globalItemAdjustment.adjustmentPercentage,
                    }}
                    editable={false}
                  />

                  <Space height="3em" />
                </>
              )}

              <h3>{strings.subHeadings.itemAdjustments}</h3>
              {loadingItemAdjustments && (
                <Text size="small">{strings.loadingMessages.loadingAdjustments}</Text>
              )}

              {!loadingItemAdjustments && (
                <>
                  {itemAdjustments.length > 0 && (
                    <>
                      <Row>
                        <Col>
                          <ItemAdjustmentsTable
                            headingStrings={{
                              productCode: strings.itemAdjustmentTable.headings.productCode,
                              title: strings.itemAdjustmentTable.headings.title,
                              listPrice: strings.itemAdjustmentTable.headings.listPrice,
                              adjustBasePrice: strings.itemAdjustmentTable.headings.adjustBasePrice,
                              adjustmentPercentage: strings.itemAdjustmentTable.headings.adjustmentPercentage,
                            }}
                            itemAdjustments={itemAdjustments}
                            editable={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PageSelector
                            currentPage={currentItemAdjustmentPage}
                            maxPages={totalCurrentItemAdjustmentPages}
                            onChange={handleItemAdjustmentPageChange}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {itemAdjustments.length === 0 && (
                    <Text size="small">{strings.noRecords.noItemAdjustments}</Text>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              {priceAdjustment.adjustAllItems && (
                <>
                  <Space height="3em" />

                  <h3>{strings.subHeadings.itemExclusions}</h3>
                  {loadingItemExclusions && (
                    <Text size="small">{strings.loadingMessages.loadingExclusions}</Text>
                  )}

                  {!loadingItemExclusions && (
                    <>
                      {itemExclusions.length > 0 && (
                        <ItemExclusionsTable
                          headingStrings={{
                            productCode: strings.itemExclusionTable.headings.productCode,
                            title: strings.itemExclusionTable.headings.title,
                            listPrice: strings.itemExclusionTable.headings.listPrice,
                          }}
                          itemExclusions={itemExclusions}
                          editable={false}
                        />
                      )}

                      {itemExclusions.length === 0 && (
                        <Text size="small">{strings.noRecords.noItemExclusions}</Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Space height="5em" />

          <Row>
            <Col>
              <h2>{strings.subHeadings.clientAdjustments}</h2>

              {priceAdjustment.adjustAllClients && (
                <>
                  <GlobalClientAdjustmentSettingsBox
                    applyToAll={priceAdjustment.adjustAllClients}
                    adjustmentPercentage={priceAdjustment.globalClientAdjustment.adjustmentPercentage}
                    strings={{
                      title: strings.globalClientAdjustment.title,
                      applyToAll: strings.globalClientAdjustment.applyToAll,
                      adjustmentPercentage: strings.globalClientAdjustment.adjustmentPercentage,
                    }}
                    editable={false}
                  />

                  <Space height="3em" />
                </>
              )}

              <h3>{strings.subHeadings.clientAdjustments}</h3>
              {loadingClientAdjustments && (
                <Text size="small">{strings.loadingMessages.loadingAdjustments}</Text>
              )}

              {!loadingClientAdjustments && (
                <>
                  {clientAdjustments.length > 0 && (
                    <>
                      <Row>
                        <Col>
                          <ClientAdjustmentsTable
                            headingStrings={{
                              accountNumber: strings.clientAdjustmentTable.headings.accountNumber,
                              name: strings.clientAdjustmentTable.headings.name,
                              adjustmentPercentage: strings.clientAdjustmentTable.headings.adjustmentPercentage,
                            }}
                            clientAdjustments={clientAdjustments}
                            editable={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PageSelector
                            currentPage={currentClientAdjustmentPage}
                            maxPages={totalCurrentClientAdjustmentPages}
                            onChange={handleClientAdjustmentPageChange}
                          />
                        </Col>
                      </Row>
                    </>
                  )}

                  {clientAdjustments.length === 0 && (
                    <Text size="small">{strings.noRecords.noClientAdjustments}</Text>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              {priceAdjustment.adjustAllClients && (
                <>
                  <Space height="3em" />

                  <h3>{strings.subHeadings.clientExclusions}</h3>
                  {loadingClientExclusions && (
                    <Text size="small">{strings.loadingMessages.loadingExclusions}</Text>
                  )}

                  {!loadingClientExclusions && (
                    <>
                      {clientExclusions.length > 0 && (
                        <>
                          <Row>
                            <Col>
                              <ClientExclusionsTable
                                headingStrings={{
                                  accountNumber: strings.clientExclusionTable.headings.accountNumber,
                                  name: strings.clientExclusionTable.headings.name,
                                }}
                                clientExclusions={clientExclusions}
                                editable={false}
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      {clientExclusions.length === 0 && (
                        <Text size="small">{strings.noRecords.noClientExclusions}</Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>

          {canBeConfirmed() && (
            <Row>
              <Col>
                <Space height="5em" />

                <h2>{strings.confirm.title}</h2>
                <div className="mb-3">
                  <Text size="small">{strings.confirm.message}</Text>
                </div>
                <Button
                  theme="action"
                  onClick={handleConfirmClick}
                  disabled={!canBeConfirmed()}
                >
                  {strings.confirm.button}
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PriceAdjustmentReviewTab;
