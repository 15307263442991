import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const PermissionToggler = ({
  name,
  description,
  active,
  onClick,
}) => {
  const handleClick = () => {
    onClick(name);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <button
          className={classnames(
            styles.button,
            active && styles.active,
          )}
          type="button"
          onClick={handleClick}
        >
          <span className={active ? 'glyphicon glyphicon-ok' : 'glyphicon glyphicon-remove'} />
        </button>
      </div>
      <div className={styles.infoWrapper}>
        <span className={styles.name}>{name}</span>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

PermissionToggler.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

PermissionToggler.defaultProps = {
  name: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};


export default PermissionToggler;
