import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.css';

const HorizontalRule = ({
  className,
}) => (
  <hr
    className={classnames(
      styles.horizontalRule,
      className,
    )}
  />
);

export default HorizontalRule;
