import React, { memo } from 'react';
import PropTypes from 'prop-types';

import defaultClientLogo from 'Common/assets/images/default-logo.png';

import Image from 'Common/components/Image';
import HamburgerIcon from './HamburgerIcon';
import UserInfo from './UserInfo';

import styles from './styles.module.css';

const Header = ({
  fullName,
  username,
  emailAddress,
  accountNumber,
  profilePicture,
  logo,
  versionNumber,
  onMenuClick,
}) => (
  <div className={styles.header}>
    <HamburgerIcon onClick={onMenuClick} />
    <Image
      className={styles.logo}
      source={logo}
      defaultSource={defaultClientLogo}
      alt="client logo"
    />
    <div className={styles.rightWrapper}>
      <UserInfo
        fullName={fullName}
        username={username}
        emailAddress={emailAddress}
        accountNumber={accountNumber}
        profilePicture={profilePicture}
      />
      <p className={styles.versionNumber}>{versionNumber}</p>
    </div>
  </div>
);

Header.propTypes = {
  fullName: PropTypes.string,
  username: PropTypes.string,
  emailAddress: PropTypes.string,
  accountNumber: PropTypes.string,
  profilePicture: PropTypes.string,
  logo: PropTypes.string,
  versionNumber: PropTypes.string,
  onMenuClick: PropTypes.func,
};

Header.defaultProps = {
  fullName: '',
  username: '',
  emailAddress: '',
  accountNumber: '',
  profilePicture: '',
  logo: '',
  versionNumber: '',
  onMenuClick: () => {},
};

export default memo(Header);
