import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Dropdown } from 'semantic-ui-react';

import 'semantic-ui-css/semantic.min.css';
import styles from './styles.module.css';

const SearchableDropdown = ({
  className,
  width,
  onSearchChange,
  onChange,
  onClick,
  placeholder,
  options,
}) => (
  <Dropdown
    className={classnames(
      styles.default,
      className,
    )}
    style={
      width && {
        width,
      }
    }
    onSearchChange={onSearchChange}
    onChange={onChange}
    onClick={onClick}
    placeholder={placeholder}
    options={options}
    search
    selection
  />
);

SearchableDropdown.propTypes = {
  className: PropTypes.string,
  onSearchChange: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
    value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    flag: PropTypes.string,
  })),
};

SearchableDropdown.defaultProps = {
  className: '',
  onSearchChange: () => {},
  onChange: () => {},
  onClick: () => {},
  placeholder: '',
  options: [],
};

export default SearchableDropdown;
