import React from 'react';
import PropTypes from 'prop-types';

import Space from './styles';

const SpaceComponent = ({
  width,
  height,
}) => (
  <Space width={width} height={height} />
);

SpaceComponent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default SpaceComponent;
