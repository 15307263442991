import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAuth from 'Common/hooks/useAuth';

import Text from 'Common/components/Text';
import PageTitle from 'Common/components/PageTitle';
import Paragraph from 'Common/components/Paragraph';
import Button from 'Common/components/Button';
import Space from 'Common/components/Space';
import Image from 'Common/components/Image';

import ProcessingPriceAdjustmentsTable from 'Admin/components/PriceAdjustments/ProcessingPriceAdjustmentsTable';
import ScheduledPriceAdjustmentsTable from 'Admin/components/PriceAdjustments/ScheduledPriceAdjustmentsTable';
import DraftPriceAdjustmentsTable from 'Admin/components/PriceAdjustments/DraftPriceAdjustmentsTable';
import HistoricalPriceAdjustmentsTable from 'Admin/components/PriceAdjustments/HistoricalPriceAdjustmentsTable';

import leftArrowIcon from 'Common/assets/icons/left_arrow.png';
import rightArrowIcon from 'Common/assets/icons/right_arrow.png';

import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { isUndefined, isNull } from 'Common/utils/utils';
import { toLocalDateTime } from 'Common/utils/dateUtils';
import { string } from 'prop-types';


const PriceAdjustmentsPage = () => {
  const history = useHistory();
  const { priceAdjustments: { strings, loading: loadingStrings }, fetchPriceAdjustmentsStrings } = useStrings();
  const { user } = useUser();
  const {
    tokens: {accessToken},
  } = useAuth();
  const { addToast } = useToasts();

  const processingPriceAdjustmentsUrl = `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments?state=processing`; // TODO: add failure state
  const { results: processingPriceAdjustments, loading: loadingProcessingPriceAdjustments } = usePagedFetch(processingPriceAdjustmentsUrl);

  const scheduledPriceAdjustmentsUrl = `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments?state=confirmed`; // TODO: add failure state
  const { results: scheduledPriceAdjustments, loading: loadingScheduledPriceAdjustments, refetch: refetchScheduledPriceAdjustments } = usePagedFetch(scheduledPriceAdjustmentsUrl);

  const draftPriceAdjustmentsUrl = `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments?state=draft`; // TODO: add failure state
  const { results: draftPriceAdjustments, loading: loadingDraftPriceAdjustments, refetch: refetchDraftPriceAdjustments } = usePagedFetch(draftPriceAdjustmentsUrl);

  const [historicalPriceAdjustmentsPage, setHistoricalPriceAdjustmentsPage] = useState(1);
  const historicalPriceAdjustmentsUrl = `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments?pageSize=6&page=${historicalPriceAdjustmentsPage}&state=completed`; //TODO: sort by completed date
  const { results: historicalPriceAdjustments, loading: loadingHistoricalPriceAdjustments, currentPage: currentHistoricalPriceAdjustmentPage, totalPages: totalHistoricalPriceAdjustmentPages } = usePagedFetch(historicalPriceAdjustmentsUrl);

  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchPriceAdjustmentsStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchPriceAdjustmentsStrings]);

  const deletePriceAdjustment = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      addToast(strings.toastMessages.successfulDelete, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      if (scheduledPriceAdjustments.some((x) => x.id === id)) {
        await refetchScheduledPriceAdjustments();
      }

      if (draftPriceAdjustments.some((x) => x.id === id)) {
        await refetchDraftPriceAdjustments();
      }
    } catch (e) {
      addToast(strings.toastMessages.failedDelete, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const getPriceAdjustmentType = (adjustment) => {
    if (adjustment.adjustAllItems && adjustment.adjustAllClients) {
      return strings.adjustmentTypes.globalItemClientAdjustment;
    }

    if (adjustment.adjustAllItems) {
      return strings.adjustmentTypes.globalItemAdjustment;
    }

    if (adjustment.adjustAllClients) {
      return strings.adjustmentTypes.globalClientAdjustment;
    }

    return strings.adjustmentTypes.specificAdjustment;
  };

  // Handlers
  const handleOnCreatePriceAdjustmentClick = () => {
    history.push('/admin/price-adjustment/create');
  };

  const handleNextHistoricalPageClick = () => {
    setHistoricalPriceAdjustmentsPage(currentHistoricalPriceAdjustmentPage + 1);
  };

  const handlePreviousHistoricalPageClick = () => {
    setHistoricalPriceAdjustmentsPage(currentHistoricalPriceAdjustmentPage - 1);
  };

  const handleEditClick = (id) => {
    history.push(`/admin/price-adjustment/${id}/info`);
  };

  const handleDeleteClick = (id) => {
    confirmAlert({
      title: strings.deleteConfirmation.message,
      message: '',
      buttons: [
        {
          label: strings.deleteConfirmation.cancel,
        },
        {
          label: strings.deleteConfirmation.confirm,
          onClick: () => deletePriceAdjustment(id)
        },
      ],
    });
  };

  // Rendering
  if (isNull(strings)) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs="12"><PageTitle>{strings.title}</PageTitle></Col>
      </Row>

      <Row>
        <Col md={10} xs={12}>
          <Space height="20px" />
          <Paragraph>
            {strings.description}
          </Paragraph>
          <Space height="30px" />
        </Col>
        <Col md={2} xs={12}>
          <Button
            theme="blue"
            onClick={() => handleOnCreatePriceAdjustmentClick()}
          >
            {strings.createButton}
          </Button>
        </Col>
      </Row>

      {(processingPriceAdjustments.length > 0 || loadingProcessingPriceAdjustments) && (
        <Row>
          <Col xs={12}>
            <h2>{strings.processingTable.title}</h2>
          </Col>

          <Col xs={12}>
            <ProcessingPriceAdjustmentsTable
              headingStrings={{
                id: strings.processingTable.headings.id,
                title: strings.processingTable.headings.title,
                type: strings.processingTable.headings.type,
                progress: strings.processingTable.headings.progress,
                startTime: strings.processingTable.headings.startTime,
                elapseTime: strings.processingTable.headings.elapseTime,
              }}
              priceAdjustments={processingPriceAdjustments.map((priceAdjustment) => ({
                key: priceAdjustment.id,
                id: priceAdjustment.id,
                title: priceAdjustment.title,
                type: getPriceAdjustmentType(priceAdjustment),
                progress: priceAdjustment.percentageComplete,
                startTime: Date.parse(priceAdjustment.dateExecuted),
                elapseTime: new Date() - new Date(priceAdjustment.dateExecuted),
              }))}
              loading={loadingProcessingPriceAdjustments}
              skeletonLoadingRows={5}
            />
          </Col>

          <Space height="4em" />
        </Row>
      )}

      {(scheduledPriceAdjustments.length > 0 || loadingScheduledPriceAdjustments) && (
        <Row>
          <Col xs={12}>
            <h2>{strings.scheduledTable.title}</h2>
          </Col>
          <Col xs={10}>
            <ScheduledPriceAdjustmentsTable
              headingStrings={{
                id: strings.scheduledTable.headings.id,
                title: strings.scheduledTable.headings.title,
                type: strings.scheduledTable.headings.type,
                scheduledExecution: strings.scheduledTable.headings.scheduledExecution,
              }}
              priceAdjustments={scheduledPriceAdjustments.map((priceAdjustment) => ({
                key: priceAdjustment.id,
                id: priceAdjustment.id,
                title: priceAdjustment.title,
                type: getPriceAdjustmentType(priceAdjustment),
                scheduledExecution: (priceAdjustment.executionType === priceAdjustmentExecutionType.immediate)
                  ? 'Immediately'
                  : toLocalDateTime(priceAdjustment.scheduledDate),
              }))}
              loading={loadingScheduledPriceAdjustments}
              skeletonLoadingRows={5}
              editButtonText={strings.editButton}
              deleteButtonText={strings.deleteButton}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          </Col>

          <Space height="4em" />
        </Row>
      )}

      {(draftPriceAdjustments.length > 0 || loadingDraftPriceAdjustments) && (
        <Row>
          <Col xs={12}>
            <h2>{strings.draftTable.title}</h2>
          </Col>
          <Col xs={10}>
            <DraftPriceAdjustmentsTable
              headingStrings={{
                id: strings.draftTable.headings.id,
                title: strings.draftTable.headings.title,
                type: strings.draftTable.headings.type,
                scheduledExecution: strings.draftTable.headings.scheduledExecution,
              }}
              priceAdjustments={draftPriceAdjustments.map((priceAdjustment) => ({
                key: priceAdjustment.id,
                id: priceAdjustment.id,
                title: priceAdjustment.title,
                type: getPriceAdjustmentType(priceAdjustment),
                executionType: priceAdjustment.executionType,
                scheduledExecution: (priceAdjustment.executionType === priceAdjustmentExecutionType.immediate)
                  ? strings.executionTypes.immediate
                  : priceAdjustment.scheduledDate,
              }))}
              loading={loadingDraftPriceAdjustments}
              skeletonLoadingRows={5}
              editButtonText={strings.editButton}
              deleteButtonText={strings.deleteButton}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          </Col>

          <Space height="4em" />
        </Row>
      )}

      {!loadingHistoricalPriceAdjustments && (
        <Row>
          <Col xs={12}>
            <Row>
              <Col>
                <h2>{strings.historticalTable.title}</h2>
              </Col>
            </Row>

            {historicalPriceAdjustments.length === 0 && (
              <Row>
                <Col>
                  <Text
                    size="small"
                  >
                    {strings.noHistoricalPriceAdjustments}
                  </Text>
                </Col>
              </Row>
            )}

            {historicalPriceAdjustments.length > 0 && (
              <>
                <Row>
                  <Col>
                    <HistoricalPriceAdjustmentsTable
                      headingStrings={{
                        id: strings.historticalTable.headings.id,
                        title: strings.historticalTable.headings.title,
                        type: strings.historticalTable.headings.type,
                        status: strings.historticalTable.headings.status,
                        progress: strings.historticalTable.headings.progress,
                        issues: strings.historticalTable.headings.issues,
                        startTime: strings.historticalTable.headings.startTime,
                        completionTime: strings.historticalTable.headings.completionTime,
                        elapseTime: strings.historticalTable.headings.elapseTime,
                      }}
                      priceAdjustments={historicalPriceAdjustments.map((priceAdjustment) => ({
                        id: priceAdjustment.id,
                        title: priceAdjustment.title,
                        type: getPriceAdjustmentType(priceAdjustment),
                        status: priceAdjustment.state,
                        issues: 0,
                        startTime: Date.parse(priceAdjustment.dateExecuted),
                        completionTime: Date.parse(priceAdjustment.dateCompleted),
                        elapseTime: Date.parse(priceAdjustment.dateCompleted) - new Date(priceAdjustment.dateExecuted),
                      }))}
                      loading={loadingHistoricalPriceAdjustments}
                      skeletonLoadingRows={5}
                    />
                  </Col>
                </Row>

                {totalHistoricalPriceAdjustmentPages > 1 && (
                  <Row
                    className="align-content-right mt-4"
                  >
                    <Col>
                      <Button
                        className="mr-2"
                        disabled={loadingHistoricalPriceAdjustments || currentHistoricalPriceAdjustmentPage === 1}
                        onClick={handlePreviousHistoricalPageClick}
                      >
                        <Image
                          source={leftArrowIcon}
                          width="0.7em"
                        />
                      </Button>
                      <Button
                        disabled={loadingHistoricalPriceAdjustments || currentHistoricalPriceAdjustmentPage === totalHistoricalPriceAdjustmentPages}
                        onClick={handleNextHistoricalPageClick}
                      >
                        <Image
                          source={rightArrowIcon}
                          width="0.7em"
                        />
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PriceAdjustmentsPage;
