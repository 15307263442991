import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './styles.module.css';

const Tag = ({
  className,
  tagId,
  attributeId,
  text,
  onDismissClick,
}) => {
  const handleDismissClick = () => {
    onDismissClick(tagId, attributeId);
  };

  return (
    <div
      className={classnames(
        styles.container,
        className,
      )}
    >
      <span
        className={styles.text}
      >
        {text}
      </span>
      <span
        className={classnames(
          'glyphicons glyphicons-remove',
          styles.dismissIcon,
        )}
        onClick={handleDismissClick}
      />
    </div>
  );
};

Tag.propTypes = {
  text: PropTypes.string,
  onDismissClick: PropTypes.func,
};

Tag.defaultProps = {
  text: '',
  onDismissClick: () => {},
};

export default Tag;
