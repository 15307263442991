import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useAuth from 'Common/hooks/useAuth';
import useInput from 'Common/hooks/useInput';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useDebounce from 'Common/hooks/useDebounce';

import Space from 'Common/components/Space';
import Text from 'Common/components/Text';
import Input from 'Common/components/Input';
import PageSelector from 'Common/components/PageSelector';
import ItemsTable from 'Admin/components/PriceAdjustments/ItemsTable';
import GlobalItemAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalItemAdjustmentSettingsBox';
import ItemAdjustmentsTable from 'Admin/components/PriceAdjustments/ItemAdjustmentsTable';
import ItemExclusionsTable from 'Admin/components/PriceAdjustments/ItemExclusionsTable';
import AdjustmentSaveChangesBox from 'Admin/components/PriceAdjustments/AdjustmentSaveChangesBox';
import InvalidChangesErrorBox from 'Admin/components/PriceAdjustments/InvalidChangesErrorBox';

import { nestedCopy, isUndefined, delay } from 'Common/utils/utils';

const PriceAdjustmentItemsTab = ({
  priceAdjustment,
  strings: { items: strings },
}) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    tokens: { accessToken },
  } = useAuth();
  const { addToast } = useToasts();

  const [itemPage, setItemPage] = useState(1);
  const { value: search, onChange: onSearchChange } = useInput('');
  const [searchValue, setSearchedValue] = useState('');

  const { results: items, loading: loadingItems, currentPage: currentItemPage, totalPages: totalItemPages } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/items?pageSize=50&page=${itemPage}&status=available&search=${searchValue}`);
  const { results: existingItemAdjustments, loading: loadingItemAdjustments, refetch: refetchItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items?pageSize=1000`);

  const [itemAdjustments, setItemAdjustments] = useState([]);
  const [itemExclusions, setItemExclusions] = useState([]);

  const [savingChanges, setSavingChanges] = useState(false);

  const getInitialGlobalItemAdjustmentState = () => (
    priceAdjustment.adjustAllItems
      ? priceAdjustment.globalItemAdjustment
      : {
        adjustBasePrice: false,
        adjustmentPercentage: '',
      });

  const [savedAdjustAllItems, setSavedAdjustAllItems] = useState(priceAdjustment.adjustAllItems);
  const [savedGlobalItemAdjustment, setSavedGlobalItemAdjustment] = useState(getInitialGlobalItemAdjustmentState());
  const [adjustAllItems, setAdjustAllItems] = useState(priceAdjustment.adjustAllItems);
  const [globalItemAdjustment, setGlobalItemAdjustment] = useState(getInitialGlobalItemAdjustmentState());

  const { results: existingItemExclusions, loading: loadingItemExclusions, refetch: refetchItemExclusions } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/exclusions/items?pageSize=1000`);

  const isItemExcluded = (itemId) => itemExclusions.some((x) => x.item.id === itemId);
  const hasAdjustmentForItem = (itemId) => itemAdjustments.some((x) => x.item.id === itemId);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setItemAdjustments(existingItemAdjustments);
  }, [existingItemAdjustments, setItemAdjustments]);

  useEffect(() => {
    setItemExclusions(existingItemExclusions);
  }, [existingItemExclusions, setItemExclusions]);

  const hasGlobalAdjustmentbeenChanged = () => {
    if (savedAdjustAllItems !== adjustAllItems) {
      return true;
    }

    if (adjustAllItems) {
      if (savedGlobalItemAdjustment.adjustBasePrice !== globalItemAdjustment.adjustBasePrice) {
        return true;
      }

      if (savedGlobalItemAdjustment.adjustmentPercentage !== globalItemAdjustment.adjustmentPercentage) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (debouncedSearch) {
      setSearchedValue(search);
    }
  }, [debouncedSearch, setSearchedValue, search]);

  const haveChangesBeenMade = () => {
    if (hasGlobalAdjustmentbeenChanged()) {
      return true;
    }

    if (itemAdjustments.length !== existingItemAdjustments.length) {
      return true;
    }

    if (itemExclusions.length !== existingItemExclusions.length) {
      return true;
    }

    const hasAnyAdjustmentChanged = itemAdjustments.some((adjustment) => {
      const existingAdjustment = existingItemAdjustments.find((x) => x.item.id === adjustment.item.id);

      if (isUndefined(existingAdjustment)) {
        return true;
      }

      if (existingAdjustment.adjustBasePrice !== adjustment.adjustBasePrice) {
        return true;
      }

      if (existingAdjustment.adjustmentPercentage !== adjustment.adjustmentPercentage) {
        return true;
      }

      return false;
    });

    if (hasAnyAdjustmentChanged) {
      return true;
    }

    const hasAnyExclusionChanged = itemExclusions.some((exclusion) => {
      return existingItemAdjustments.some((x) => x.item.id === exclusion.item.id);
    });

    if (hasAnyExclusionChanged) {
      return true;
    }

    return false;
  };

  const haveInvalidChangesBeenMade = () => {
    if (adjustAllItems && globalItemAdjustment.adjustBasePrice && Number(globalItemAdjustment.adjustmentPercentage) === 0) {
      return true;
    }

    if (itemAdjustments.some((adjustment) => adjustment.adjustBasePrice && Number(adjustment.adjustmentPercentage) === 0)) {
      return true;
    }

    return false;
  };

  const saveGlobalAdjustmentChanges = async () => {
    try {
      if (savedAdjustAllItems !== adjustAllItems) {
        if (adjustAllItems) {
          await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/global`, {
            adjustBasePrice: globalItemAdjustment.adjustBasePrice,
            adjustmentPercentage: globalItemAdjustment.adjustmentPercentage,
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } else {
          await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/global`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      } else {
        await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/global`, {
          adjustBasePrice: globalItemAdjustment.adjustBasePrice,
          adjustmentPercentage: globalItemAdjustment.adjustmentPercentage,
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    } catch (e) {
      addToast(strings.toastMessages.failedUpdateGlobalAdjustment, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const createItemAdjustment = async (itemAdjustment) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/${itemAdjustment.item.id}`, {
        adjustBasePrice: itemAdjustment.adjustBasePrice,
        adjustmentPercentage: itemAdjustment.adjustmentPercentage,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedAddItemAdjustment}: ${itemAdjustment.item.title}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const updateItemAdjustment = async (itemAdjustment) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/${itemAdjustment.item.id}`, {
        adjustBasePrice: itemAdjustment.adjustBasePrice,
        adjustmentPercentage: itemAdjustment.adjustmentPercentage,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedUpdateItemAdjustment} for: ${itemAdjustment.item.title}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const deleteItemAdjustment = async (itemAdjustment) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/items/${itemAdjustment.item.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedRemoveItemAdjustment}: ${itemAdjustment.item.title}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const saveItemAdjustmentChanges = async () => {
    const deletedAdjustments = existingItemAdjustments.filter(
      (existingAdjustment) => !itemAdjustments.some((adjustment) => adjustment.item.id === existingAdjustment.item.id));

    deletedAdjustments.forEach(async (adjustment) => {
      await deleteItemAdjustment(adjustment);
    });

    itemAdjustments.forEach(async (adjustment) => {
      const existingAdjustment = existingItemAdjustments.find((x) => x.item.id === adjustment.item.id);

      if (isUndefined(existingAdjustment)) {
        await createItemAdjustment(adjustment);
      } else if (existingItemAdjustments.adjustBasePrice !== adjustment.adjustBasePrice || existingItemAdjustments.adjustmentPercentage !== adjustment.adjustmentPercentage) {
        await updateItemAdjustment(adjustment);
      }
    });
  };

  const createItemExclusion = async (itemExclusion) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/exclusions/items/${itemExclusion.item.id}`, {
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedAddItemExclusion}: ${itemExclusion.item.title}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const deleteItemExclusion = async (itemExclusion) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/exclusions/items/${itemExclusion.item.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedRemoveItemExclusion}: ${itemExclusion.item.title}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const saveItemExclusionChanges = async () => {
    const deletedExclusions = existingItemExclusions.filter(
      (existingExclusion) => !itemExclusions.some((exclusion) => exclusion.item.id === existingExclusion.item.id));

    deletedExclusions.forEach(async (exclusion) => {
      await deleteItemExclusion(exclusion);
    });

    itemExclusions.forEach(async (exclusion) => {
      if (!existingItemExclusions.some((x) => x.item.id === exclusion.item.id)) {
        await createItemExclusion(exclusion);
      }
    });
  };

  // Handlers
  const handleAdjustAllItemsChange = (value) => {
    setAdjustAllItems(value);
  };

  const handleAdjustBasePriceChange = (value) => {
    setGlobalItemAdjustment({
      ...globalItemAdjustment,
      adjustBasePrice: value,
      adjustmentPercentage: value ? '3.00' : null,
    });
  };

  const handleAdjustmentPercentageChange = (value) => {
    setGlobalItemAdjustment({
      ...globalItemAdjustment,
      adjustmentPercentage: value,
    });
  };

  const handleItemPageChange = (page) => {
    setItemPage(page);
  };

  const handleIncludeItemClick = (item) => {
    if (isItemExcluded(item.id)) {
      return;
    }

    if (hasAdjustmentForItem(item.id)) {
      return;
    }

    setItemAdjustments([
      ...itemAdjustments, {
        item: {
          id: item.id,
          title: item.title,
          productCode: item.productCode,
          listPrice: item.listPrice,
        },
        adjustBasePrice: false,
        adjustmentPercentage: null,
      },
    ]);
  };

  const handleExcludeItemClick = (item) => {
    if (isItemExcluded(item.id)) {
      return;
    }

    if (hasAdjustmentForItem(item.id)) {
      return;
    }

    setItemExclusions([
      ...itemExclusions, {
        item: {
          id: item.id,
          title: item.title,
          productCode: item.productCode,
          listPrice: item.listPrice,
        },
      },
    ]);
  };

  const handleDeleteItemExclusion = (itemId) => {
    if (!itemExclusions.some((x) => x.item.id === itemId)) {
      return;
    }

    const filteredItemExclusions = itemExclusions.filter((x) => x.item.id !== itemId);

    setItemExclusions(filteredItemExclusions);
  };

  const handleDeleteItemAdjustment = (itemId) => {
    if (!hasAdjustmentForItem(itemId)) {
      return;
    }

    const filteredItemAdjustments = itemAdjustments.filter((x) => x.item.id !== itemId);

    setItemAdjustments(filteredItemAdjustments);
  };

  const handleItemAdjustmentBasePriceChange = (itemId, value) => {
    const editedItemAdjustments = nestedCopy(itemAdjustments);

    const index = editedItemAdjustments.findIndex((x) => x.item.id === itemId);
    editedItemAdjustments[index].adjustBasePrice = value;
    editedItemAdjustments[index].adjustmentPercentage = (value) ? '3.00' : null;

    setItemAdjustments(editedItemAdjustments);
  };

  const handleItemAdjustmentPercentageChange = (itemId, value) => {
    const editedItemAdjustments = nestedCopy(itemAdjustments);

    const index = editedItemAdjustments.findIndex((x) => x.item.id === itemId);
    editedItemAdjustments[index].adjustmentPercentage = value;

    setItemAdjustments(editedItemAdjustments);
  };

  const handleSaveChangesClick = async () => {
    setSavingChanges(true);

    if (hasGlobalAdjustmentbeenChanged()) {
      await saveGlobalAdjustmentChanges();
      setSavedAdjustAllItems(adjustAllItems);
      setSavedGlobalItemAdjustment(globalItemAdjustment);
    }

    await saveItemAdjustmentChanges();

    if (adjustAllItems) {
      await saveItemExclusionChanges();
    }

    addToast(strings.toastMessages.successSaveChanges, {
      appearance: 'success',
      autoDismiss: true,
      pauseOnHover: false,
    });

    await delay(3000);
    refetchItemAdjustments();
    refetchItemExclusions();

    setSavingChanges(false);
  };

  const _haveChangesBeenMade = haveChangesBeenMade();
  const _haveInvalidChangesBeenMade = haveInvalidChangesBeenMade();

  return (
    <div>
      <Row>
        <Col xs={12}>
          {(_haveChangesBeenMade && _haveInvalidChangesBeenMade) && (
            <InvalidChangesErrorBox
              title={strings.invalidChangesAlert.title}
              message={strings.invalidChangesAlert.message}
            />
          )}

          {_haveChangesBeenMade && (
            <AdjustmentSaveChangesBox
              title={strings.saveChanges.title}
              message={strings.saveChanges.message}
              buttonText={strings.saveChanges.button}
              onSaveChangesClick={handleSaveChangesClick}
              disabled={savingChanges || _haveInvalidChangesBeenMade}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="pr-4">
          <div className="mb-2">
            <Input
              placeholder={strings.placeholders.search}
              value={search}
              onChange={onSearchChange}
            />
          </div>
          <ItemsTable
            headingStrings={{
              productCode: strings.itemTable.headings.productCode,
              title: strings.itemTable.headings.title,
              listPrice: strings.itemTable.headings.listPrice,
            }}
            items={items.map((item) => ({
              id: item.id,
              title: item.title,
              productCode: item.productCode,
              listPrice: item.listPrice,
              excluded: isItemExcluded(item.id),
              hasAdjustment: hasAdjustmentForItem(item.id),
            }))}
            canExclude={adjustAllItems}
            onIncludeClick={handleIncludeItemClick}
            onExcludeClick={handleExcludeItemClick}
          />

          <PageSelector
            currentPage={currentItemPage}
            maxPages={totalItemPages}
            onChange={handleItemPageChange}
          />
        </Col>
        <Col xs={12} md={6}>
          <GlobalItemAdjustmentSettingsBox
            applyToAll={adjustAllItems}
            adjustBasePrice={globalItemAdjustment.adjustBasePrice}
            adjustmentPercentage={globalItemAdjustment.adjustmentPercentage}
            onApplyToAllChange={handleAdjustAllItemsChange}
            onAdjustBasePriceChange={handleAdjustBasePriceChange}
            onAdjustmentPercentageChange={handleAdjustmentPercentageChange}
            strings={{
              title: strings.globalItemAdjustment.title,
              applyToAll: strings.globalItemAdjustment.applyToAll,
              adjustBasePrice: strings.globalItemAdjustment.adjustBasePrice,
              adjustmentPercentage: strings.globalItemAdjustment.adjustmentPercentage,
            }}
            editable
          />

          <h3>{strings.subHeadings.itemAdjustments}</h3>
          {loadingItemAdjustments && (
            <Text size="small">{strings.loadingMessages.loadingAdustments}</Text>
          )}

          {!loadingItemAdjustments && (
            <>
              {itemAdjustments.length > 0 && (
                <ItemAdjustmentsTable
                  headingStrings={{
                    productCode: strings.itemAdjustmentTable.headings.productCode,
                    title: strings.itemAdjustmentTable.headings.title,
                    listPrice: strings.itemAdjustmentTable.headings.listPrice,
                    adjustBasePrice: strings.itemAdjustmentTable.headings.adjustBasePrice,
                    adjustmentPercentage: strings.itemAdjustmentTable.headings.adjustmentPercentage,
                  }}
                  itemAdjustments={itemAdjustments}
                  onDeleteClick={handleDeleteItemAdjustment}
                  onAdjustBasePriceChange={handleItemAdjustmentBasePriceChange}
                  onAdjustmentPercentageChange={handleItemAdjustmentPercentageChange}
                  editable
                />
              )}

              {itemAdjustments.length === 0 && (
                <Text size="small">{strings.noRecords.noItemAdjustments}</Text>
              )}
            </>
          )}

          {adjustAllItems && (
            <>
              <Space height="3em" />

              <h3>{strings.subHeadings.itemExclusions}</h3>
              {loadingItemExclusions && (
                <Text size="small">{strings.loadingMessages.loadingExclusions}</Text>
              )}

              {!loadingItemExclusions && (
                <>
                  {itemExclusions.length > 0 && (
                    <ItemExclusionsTable
                      headingStrings={{
                        productCode: strings.itemExclusionTable.headings.productCode,
                        title: strings.itemExclusionTable.headings.title,
                        listPrice: strings.itemExclusionTable.headings.listPrice,
                      }}
                      itemExclusions={itemExclusions}
                      onDeleteClick={handleDeleteItemExclusion}
                      editable
                    />
                  )}

                  {itemExclusions.length === 0 && (
                    <Text size="small">{strings.noRecords.noItemExclusions}</Text>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PriceAdjustmentItemsTab;
