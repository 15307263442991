import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Row, Col } from 'react-bootstrap';

import { useToasts } from 'react-toast-notifications';
import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAuth from 'Common/hooks/useAuth';

import Button from 'Common/components/Button';
import FiltersBox from 'Common/components/FiltersBox';
import Dropdown from 'Common/components/Dropdown';
import PermissionToggler from 'Admin/components/PermissionToggler';
import PageTitle from 'Common/components/PageTitle';
import Paragraph from 'Common/components/Paragraph';
import Space from 'Common/components/Space';

import { isUndefined, isNull, convertToInt } from 'Common/utils/utils';

const RolesPage = () => {
  const { addToast } = useToasts();

  const { roles: { strings, loading: loadingStrings }, fetchRolesStrings } = useStrings();
  const { user } = useUser();
  const { tokens: { accessToken } } = useAuth();

  const [selectedRole, setSelectedRole] = useState(undefined);

  const { results: permissions, loading: loadingPermissions } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/permissions`);
  const { results: roles, loading: loadingRoles, refetch: refetchRoles } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/roles`);

  const [deletingRole, setDeletingRole] = useState(false);

  const updateRole = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/roles/${selectedRole.id}`, {
        name: selectedRole.name,
        description: selectedRole.description,
        permissions: selectedRole.permissions,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      refetchRoles();
      addToast('Role Successfully Updated', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      addToast('Something went wrong when trying to update the role', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const deleteRole = async () => {
    setDeletingRole(true);

    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/roles/${selectedRole.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      refetchRoles();
      addToast('Role Successfully Deleted', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      addToast('Something went wrong when trying to delete the role', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setDeletingRole(false);
  };

  const doesSelectedRoleHavePermission = (targetPermission) => {
    return selectedRole.permissions.includes(targetPermission);
  };

  // Handlers
  const handleRoleChange = (e) => {
    const { value } = e.target;

    const newRole = roles.find((role) => role.id === convertToInt(value));
    setSelectedRole(newRole);
  };

  const handlePermissionClick = (permissionName) => {
    const role = selectedRole;

    if (doesSelectedRoleHavePermission(permissionName)) {
      const index = role.permissions.indexOf(permissionName);

      if (index > -1) {
        role.permissions.splice(index, 1);
      }
    } else {
      role.permissions.push(permissionName);
    }

    setSelectedRole({ ...role });
  };

  // Effects
  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchRolesStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchRolesStrings]);

  useEffect(() => {
    setSelectedRole((roles.length > 0) ? roles[0] : undefined);
  }, [roles]);

  // Rendering
  if (isNull(strings)) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <PageTitle>{strings.title}</PageTitle>
        </Col>
      </Row>

      <Row>
        <Col md={10} xs={12}>
          <Space height="20px" />
          <Paragraph>
            {strings.description}
          </Paragraph>
          <Space height="30px" />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <FiltersBox
            isDisabled={false}
          >
            <Row>
              <Col>
                <Dropdown
                  className="mr-4"
                  options={roles.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  value={selectedRole && selectedRole.id}
                  onChange={handleRoleChange}
                />

                <Button
                  type="button"
                  theme="red"
                  onClick={deleteRole}
                  disabled={isUndefined(selectedRole)}
                >
                  {strings.deleteButton}
                </Button>
              </Col>

            </Row>
          </FiltersBox>
        </Col>
      </Row>

      {isUndefined(selectedRole) && (
        <Row>
          <Col xs={12}>
            <Space height="500px" />
          </Col>
        </Row>
      )}
      <Row>
        {selectedRole && permissions.map((permission) => (
          <Col xs={12} md={4} lg={3}>
            <PermissionToggler
              key={permission.id}
              name={permission.name}
              description={permission.description}
              active={doesSelectedRoleHavePermission(permission.name)}
              onClick={handlePermissionClick}
            />
          </Col>
        ))}
      </Row>

      <Row>
        {selectedRole && (
          <Col sm={{ span: 1, offset: 11 }} style={{ textAlign: 'right' }}>
            <Button
              type="button"
              kind="action"
              onClick={updateRole}
            >
              {strings.saveButton}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RolesPage;
