import React from 'react';
import PropTypes from 'prop-types';

import Input from 'Common/components/Input';

import { getDateNextYear } from 'Common/utils/dateUtils';

import styles from './styles.module.css';

const DateTimeInput = ({
  name = '',
  value,
  onChange = () => {},
}) => {
  const handleOnChange = (event) => {
    onChange(new Date(event));
  };

  const convertToDatetimeInputString = (dateTime) => {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    return (new Date(dateTime - timezoneOffset)).toISOString().slice(0, -1);
  };

  return (
    <Input
      type="datetime-local"
      value={convertToDatetimeInputString(value)}
      name={name}
      onChange={handleOnChange}
    />
  );
};

DateTimeInput.propTypes = {
};

export default DateTimeInput;
