import jwtDecode from 'jwt-decode';
import claimTypes from 'Common/helpers/jwtClaimTypes';

const getClaims = (accessToken) => {
  const decoded = jwtDecode(accessToken);

  const claims = {
    userId: parseInt(decoded[claimTypes.userId], 10),
    name: decoded[claimTypes.name],
    email: decoded[claimTypes.email],
    role: decoded[claimTypes.role],
  };

  return claims;
};

const authReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    // login
    case 'login_requested':
      return {
        ...state,
        loggingIn: true,
      };
    case 'login_successful':
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        claims: getClaims(payload.tokens.accessToken),
        tokens: {
          accessToken: payload.tokens.accessToken,
          refreshToken: payload.tokens.refreshToken,
        },
      };
    case 'login_failed':
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
      };

    // Logout
    case 'logout':
      return {
        ...state,
        loggedIn: false,
        loggingIn: false,
        claims: undefined,
        tokens: {
          accessToken: undefined,
          refreshToken: undefined,
        },
      };

    // Refreshing Tokens
    // login
    case 'refreshing_tokens_requested':
      return {
        ...state,
        refreshingRokens: true,
      };
    case 'refreshing_tokens_successful':
      return {
        ...state,
        refreshingRokens: false,
        loggedIn: true,
        claims: getClaims(payload.tokens.accessToken),
        tokens: {
          accessToken: payload.tokens.accessToken,
          refreshToken: payload.tokens.refreshToken,
        },
      };
    case 'refreshing_tokens_failed':
      return {
        ...state,
        refreshingRokens: false,
        loggedIn: false,
      };

    default:
      return state;
  }
};

export default authReducer;
