import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Table from 'Common/components/Table';

import { camelize } from 'Common/utils/stringUtils';

import styles from './styles.module.css';

const OrdersTable = ({
  headingStrings,
  statusStrings,
  actionLabelText,
  orders,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.orderNumber,
      identifier: 'customerordernumber',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.customer,
      identifier: 'customer',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.status,
      identifier: 'status',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.orderDate,
      identifier: 'orderDate',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.totalCost,
      identifier: 'totalCost',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.orderedBy,
      identifier: 'orderedBy',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={orders.map((order) => ({
        identifier: order.id,
        data: [
          order.customerOrderNumber,
          order.client.name,
          <div className={classnames(styles.orderStatus, styles[camelize(order.status)])}>
            {statusStrings[order.status]}
          </div>,
          new Date(order.dateOrdered).toLocaleString(),
          order.totalCost,
          order.orderedBy.username,
          <p>{actionLabelText}</p>,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
    />
  );
};

OrdersTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    orderNumber: PropTypes.string,
    customer: PropTypes.string,
    status: PropTypes.string,
    orderDate: PropTypes.string,
    totalCost: PropTypes.string,
    orderedBy: PropTypes.string,
  }),
  actionLabelText: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

OrdersTable.defaultProps = {
  headingStrings: undefined,
  statusStrings: [],
  actionLabelText: '',
  orders: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default OrdersTable;
