import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './Common/App';
import Provider from './Common/contexts/Providers';

import * as serviceWorker from './serviceWorker';

import './index.css';
import 'Common/assets/css/bootstrap-grid.css';

ReactDOM.render((
  <Router>
    <Provider>
      <App />
    </Provider>
  </Router>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
