import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import Profile from 'Admin/tabs/MyAccount/Profile';
import Settings from 'Admin/tabs/MyAccount/Settings/Settings';

import AccountDetailsPanel from 'Admin/components/AccountDetailsPanel';
import Tabs from 'Common/components/Tabs';
import Space from 'Common/components/Space';

import useUser from 'Common/hooks/useUser';
import useBranch from 'Common/hooks/useBranch';
import useClient from 'Common/hooks/useClient';
import useStrings from 'Admin/hooks/useStrings';

const MyAccountPage = () => {
  const { user } = useUser();
  const { branch } = useBranch();
  const { client } = useClient();

  //const { myAccount: { strings, loading: loadingStrings }, fetchMyAccountStrings } = useStrings();
  const [activeTab, setActiveTab] = useState('profile');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Row>
        <AccountDetailsPanel
          user={user}
          branch={branch}
          client={client}
        />
        <div
          style={{
            flex: 1,
          }}
        >
          <Col>
            <Row>
              <Tabs
                tabs={[
                  {
                    key: 'profile',
                    title: 'Profile',
                  },
                  {
                    key: 'settings',
                    title: 'Settings',
                  },
                ]}
                onTabClick={handleTabClick}
                selectedTab={activeTab}
              />
            </Row>

            <Space height="3vh" />

            <Row>
              {(activeTab === 'profile') && (
                <Profile />
              )}
              {(activeTab === 'settings') && (
                <Settings />
              )}
            </Row>
          </Col>
        </div>
      </Row>
    </div>
  );
};

export default MyAccountPage;
