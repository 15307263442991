import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Input from 'Common/components/Input';
import Text from 'Common/components/Text';
import Button from 'Common/components/Button';
import Space from 'Common/components/Space';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const CategoryCreationForm = ({
  open,
  parentCategory,
  onRequestClose,
  onCreateClick,
  inputErrors,
  errorMessage,
  title,
  shortCode,
  onTitleChange,
  onShortCodeChange
}) => (
  <Modal
    isOpen={open}
    contentLabel="Category Creation"
    onRequestClose={onRequestClose}
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
    <Text
      bold
      size="xxlarge"
    >
      Create Category
    </Text>

    <Space height="1em" />

    <div className={styles.inputContainer}>
      <Text className={styles.label} bold>Parent: </Text>
      <Text>{!isUndefined(parentCategory) ? parentCategory.title : 'None'}</Text>
    </div>

    <div className={styles.inputContainer}>
      <Text className={styles.label} bold>Level: </Text>
      <Text>{!isUndefined(parentCategory) ? parentCategory.level + 1 : 1}</Text>
    </div>

    <Space height="2em" />

    <div>
      <span>{inputErrors.shortCode}</span>
    </div>

    <div className={styles.inputContainer}>
      <Text className={styles.inputLabel} bold>Short Code: </Text>
      <Input
        className={styles.input}
        placeholder="Short Code"
        value={shortCode}
        onChange={onShortCodeChange}
      />
    </div>

    <div>
      <span>{inputErrors.title}</span>
    </div>

    <div className={styles.inputContainer}>
      <Text className={styles.inputLabel} bold>Title: </Text>
      <Input
        className={styles.input}
        placeholder="Title"
        value={title}
        onChange={onTitleChange}
      />
    </div>

    <Space height="1em" />

    {!isUndefined(errorMessage) && (
      <Text>{errorMessage}</Text>
    )}

    <div>
      <Button
        theme="action"
        onClick={onCreateClick}
      >
        Create
      </Button>
    </div>
  </Modal>
);

CategoryCreationForm.defaultProp = {
  onRequestClose: () => {},
};

export default CategoryCreationForm;
