import { useState } from 'react';

const usePageSizeSelector = (
  sizes = [],
  defaultSize,
) => {
  const [pageSizes, setPageSizes] = useState(sizes);
  const [currentSize, setCurrentSize] = useState(defaultSize);

  return {
    pageSizes,
    pageSize: currentSize,
    setPageSizes,
    setPageSize: setCurrentSize,
  };
};

export default usePageSizeSelector;
