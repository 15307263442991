const accountManagersReducer = (state, action) => {
  const { payload } = action;

  console.log(action.type);
  switch (action.type) {
    // Fetch Account Managers
    case 'fetch_account_managers_requested':
      return {
        ...state,
        loading: true,
      };
    case 'fetch_account_managers_successful':
      return {
        ...state,
        loading: false,
        accountManagers: payload.accountManagers,
      };
    case 'fetch_account_managers_failed':
      return {
        ...state,
        loading: false,
        accountManagers: undefined,
      };

    // Delete Account Manager
    case 'delete_account_manager_requested':
      return {
        ...state,
        deleting: true,
      };
    case 'delete_account_manager_successful':
      return {
        ...state,
        deleting: false,
      };
    case 'delete_account_manager_failed':
      return {
        ...state,
        deleting: false,
      };

    // Delete Account Manager
    case 'replacing_account_manager_requested':
      return {
        ...state,
        replacing: true,
      };
    case 'replacing_account_manager_successful':
      return {
        ...state,
        replacing: false,
      };
    case 'replacing_account_manager_failed':
      return {
        ...state,
        replacing: false,
      };

    default:
      return state;
  }
};

export default accountManagersReducer;
