import React, { useEffect } from 'react';

import useAuth from 'Common/hooks/useAuth';

import { logout as strings } from 'Common/config/strings';

const LogoutPage = () => {
  const { logout } = useAuth();

  // Effects
  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div>
      <p>{strings.text}</p>
    </div>
  );
};

export default LogoutPage;
