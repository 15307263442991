import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';
import Input from 'Common/components/Input';

import styles from './styles.module.css';

const ClientAdjustmentsTable = ({
  headingStrings,
  clientAdjustments,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  onDeleteClick,
  skeletonLoadingRows,
  onAdjustmentPercentageChange = () => {},
  editable,
  height,
}) => {
  const columns = useMemo(() => ([
    {
      heading: '',
      identifier: '',
      width: '10%',
      sortable: false,
    },
    {
      heading: headingStrings.accountNumber,
      identifier: 'accountNumber',
      width: '20%',
      sortable: false,
    },
    {
      heading: headingStrings.name,
      identifier: 'name',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.adjustmentPercentage,
      identifier: 'adjustmentPercentage',
      width: '15%',
      sortable: false,
    },
  ]), [headingStrings]);

  const handleAdjustmentPercentageChange = (clientId, value) => {
    let adjustmentPercentage = value.replace(/\D/g, '');
    adjustmentPercentage = (adjustmentPercentage / 100).toFixed(2);

    onAdjustmentPercentageChange(clientId, adjustmentPercentage);
  };

  return (
    <Table
      columns={columns}
      rows={clientAdjustments.map((clientAdjustment) => ({
        identifier: clientAdjustment.client.id,
        data: [
          editable ? (
            <Button
              className={styles.deleteButton}
              onClick={() => onDeleteClick(clientAdjustment.client.id)}
            >
              x
            </Button>
          ) : '',
          clientAdjustment.client.accountNumber,
          clientAdjustment.client.name,
          <Input
            value={clientAdjustment.adjustmentPercentage}
            disabled={!editable}
            onChange={(value) => handleAdjustmentPercentageChange(clientAdjustment.client.id, value)}
            state={(Number(clientAdjustment.adjustmentPercentage) === 0) ? "error" : "normal"}
          />,
        ],
        error: Number(clientAdjustment.adjustmentPercentage) === 0,
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
      height={height}
    />
  );
};

ClientAdjustmentsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    productCode: PropTypes.string,
    title: PropTypes.string,
    listPrice: PropTypes.string,
  }),
  clientAdjustments: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ClientAdjustmentsTable.defaultProps = {
  headingStrings: undefined,
  clientAdjustments: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ClientAdjustmentsTable;
