import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';
import IncludeButton from 'Admin/components/PriceAdjustments/IncludeButton';
import ExcludeButton from 'Admin/components/PriceAdjustments/ExcludeButton';

import getSymbolFromCurrency from 'currency-symbol-map';

import styles from './styles.module.css';

const ItemsTable = ({
  headingStrings,
  items,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick = () => {},
  onRowClick = () => {},
  onDeleteClick = () => {},
  onIncludeClick = () => {},
  onExcludeClick = () => {},
  canExclude = false,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.productCode,
      identifier: 'productCode',
      width: '15%',
      sortable: false,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.listPrice,
      identifier: 'listPrice',
      width: '15%',
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: canExclude ? '12%' : '6%',
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={items.map((item) => ({
        identifier: item.id,
        data: [
          item.productCode,
          item.title,
          `${getSymbolFromCurrency(item.listPrice.currencyCode)}${item.listPrice.value}`,
          <div>
            <IncludeButton
              onClick={() => onIncludeClick(item)}
              disabled={item.excluded || item.hasAdjustment}
            />
            {canExclude && (
              <ExcludeButton
                className="ml-2"
                onClick={() => onExcludeClick(item)}
                disabled={item.excluded || item.hasAdjustment}
              />
            )}
          </div>,
        ],
        disabled: item.excluded || item.hasAdjustment,
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
    />
  );
};

ItemsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    productCode: PropTypes.string,
    title: PropTypes.string,
    listPrice: PropTypes.string,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ItemsTable.defaultProps = {
  headingStrings: undefined,
  items: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ItemsTable;
