import React from 'react';
import { Redirect } from 'react-router-dom';
import priceAdjustmentState from 'Admin/constants/priceAdjustmentState';

export default (priceAdjustment) => {
  return () => {
    if (priceAdjustment.state === priceAdjustmentState.processing) {
      return <Redirect to={`/admin/price-adjustment/${priceAdjustment.id}/processing`} />;
    }

    if (priceAdjustment.state === priceAdjustmentState.completed) {
      return <Redirect to={`/admin/price-adjustment/${priceAdjustment.id}/summary`} />;
    }

    return <Redirect to={`/admin/price-adjustment/${priceAdjustment.id}/info`} />;
  };
};
