import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';

import styles from './styles.module.css';

const ClientExclusionsTable = ({
  headingStrings,
  clientExclusions,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  onDeleteClick,
  skeletonLoadingRows,
  editable,
}) => {
  const columns = useMemo(() => ([
    {
      heading: '',
      identifier: '',
      width: '10%',
      sortable: false,
    },
    {
      heading: headingStrings.accountNumber,
      identifier: '25%',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.name,
      identifier: 'name',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={clientExclusions.map((clientExclusion) => ({
        identifier: clientExclusion.clientId,
        data: [
          editable ? (
            <Button
              className={styles.deleteButton}
              onClick={() => onDeleteClick(clientExclusion.client.id)}
            >
              x
            </Button>
          ) : '',
          clientExclusion.client.accountNumber,
          clientExclusion.client.name,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
    />
  );
};

ClientExclusionsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    productCode: PropTypes.string,
    title: PropTypes.string,
    listPrice: PropTypes.string,
  }),
  clientExclusions: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ClientExclusionsTable.defaultProps = {
  headingStrings: undefined,
  clientExclusions: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ClientExclusionsTable;
