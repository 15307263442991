import React, { useState, useEffect, useMemo } from 'react';
import uuidv4 from 'uuid/v4';

import { Row, Col } from 'react-bootstrap';

import CircularProgressBar from '../components/CircularProgressBar';
import DashboardOptionsSection from 'Common/components/DashboardOptionsSection';
import DashboardOptionsList from 'Common/components/DashboardOptionsList';
import Panel from 'Admin/components/Panel';

import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';

import { isUndefined, isNull } from 'Common/utils/utils';
import { getTodayDateRange } from 'Common/utils/dateUtils';

import routeMappings from 'Admin/routeMappings.js';

const DashboardPage = () => {
  const { dashboard: { strings, loading: loadingStrings }, fetchDashboardStrings } = useStrings();
  const { user } = useUser();

  const [ordersPlacedTodayCountLink, setOrderPlacedTodayCountLink] = useState(null);
  const { totalRecords: orderPlacedTodayCount, loading: loadingOrderPlacedTodayCount } = usePagedFetch(ordersPlacedTodayCountLink);

  const todaysOrderTarget = 100;

  useEffect(() => {
    if (!loadingOrderPlacedTodayCount) {
      const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/orders`);

      const todaysDateRange = getTodayDateRange();
      url.search = new URLSearchParams({
        pageSize: 1,
        orderDate1: todaysDateRange.startDate,
        orderDate2: todaysDateRange.endDate,
      });

      setOrderPlacedTodayCountLink(url.href);
    }
  }, [loadingOrderPlacedTodayCount]);

  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchDashboardStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchDashboardStrings]);

  const optionGroups = useMemo(() => {
    if (isNull(strings)) {
      return [];
    }

    // eslint-disable-next-line arrow-body-style
    return strings.pageOptions.optionGroups.map((group) => ({
      ...group,
      options: group.options.map((option) => ({
        ...option,
        redirect: routeMappings[option.page],
      })),
    }));
  }, [strings]);

  return (
    <div>
      <Row className="mt-4 mb-5 justify-content-center">
        {!isUndefined(orderPlacedTodayCount) && (
          <Col xs={4}>
            <CircularProgressBar
              title="Orders Placed Today"
              percentage={Math.min((orderPlacedTodayCount / todaysOrderTarget) * 100, 100)}
              value={orderPlacedTodayCount.toString()}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Panel
          className="p-5"
        >
          <Col xs={12}>
            <Row>
              {optionGroups.map((group) => (
                <Col
                  lg={3}
                  md={6}
                  xs={12}
                  key={uuidv4()}
                >
                  <DashboardOptionsSection title={group.heading}>
                    <DashboardOptionsList options={group.options} />
                  </DashboardOptionsSection>
                </Col>
              ))}
            </Row>
          </Col>
        </Panel>
      </Row>
    </div>
  );
};

export default DashboardPage;
