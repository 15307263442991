import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { NavLink } from 'react-router-dom';

import breadcrumbIcon from 'Common/assets/icons/right_arrow.png';

import styles from './styles.module.css';

const Breadcrumbs = ({
  className,
  paths = []
}) => (
  <div
    className={classnames(styles.container, className)}
  >
    {paths.map((path, i) => (
      <>
        <NavLink
          className={styles.link}
          activeClassName={styles.active}
          to={path.pathname}
        >
          {path.title}
        </NavLink>

        {((i + 1) < paths.length) && (
          <img className={classnames(styles.breadcrumbIcon, "mx-4")} src={breadcrumbIcon} alt="breadcrumb-icon" />
        )}
      </>
    ))}
  </div>
);

Breadcrumbs.propTypes = {
};

export default Breadcrumbs;
