import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';

import styles from './styles.module.css';

const ItemExclusionsTable = ({
  headingStrings,
  itemExclusions,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  onDeleteClick = () => {},
  skeletonLoadingRows,
  editable,
}) => {
  const columns = useMemo(() => ([
    {
      heading: '',
      identifier: '',
      width: '10%',
      sortable: false,
    },
    {
      heading: headingStrings.productCode,
      identifier: 'productCode',
      width: '15%',
      sortable: false,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.listPrice,
      identifier: 'listPrice',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={itemExclusions.map((itemExclusion) => ({
        identifier: itemExclusion.item.id,
        data: [
          editable ? (
            <Button
              className={styles.deleteButton}
              onClick={() => onDeleteClick(itemExclusion.item.id)}
            >
              x
            </Button>
          ) : '',
          itemExclusion.item.productCode,
          itemExclusion.item.title,
          itemExclusion.item.listPrice.value,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
    />
  );
};

ItemExclusionsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    productCode: PropTypes.string,
    title: PropTypes.string,
    listPrice: PropTypes.string,
  }),
  itemExclusions: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ItemExclusionsTable.defaultProps = {
  headingStrings: undefined,
  priceAdjustments: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ItemExclusionsTable;
