import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Text from 'Common/components/Text';
import Input from 'Common/components/Input';
import Checkbox from 'Common/components/Checkbox';

import styles from './styles.module.css';

const GlobalItemAdjustmentSettingsBox = ({
  className,
  applyToAll,
  adjustBasePrice,
  adjustmentPercentage,
  onApplyToAllChange = () => {},
  onAdjustBasePriceChange = () => {},
  onAdjustmentPercentageChange = () => {},
  onSaveClick = () => {},
  strings,
  editable,
}) => {
  const handleAdjustmentPercentageChange = (value) => {
    let newValue = value.replace(/\D/g, '');
    newValue = (newValue / 100).toFixed(2);

    onAdjustmentPercentageChange(newValue);
  };

  return (
    <div className={classnames(styles.container, className)}>
      <h2>{strings.title}</h2>

      <div className={styles.settingsContainer}>
        <div className={styles.toggleContainer}>
          <Checkbox
            checked={applyToAll}
            checkable={editable}
            onChange={onApplyToAllChange}
            label={strings.applyToAll}
          />
        </div>

        <div className={styles.optionsContainer}>
          <Text
            className={styles.optionLabel}
            size="small"
          >
            {strings.adjustBasePrice}
          </Text>
          <Checkbox
            className={styles.checkbox}
            checked={adjustBasePrice}
            onChange={onAdjustBasePriceChange}
            checkable={editable && applyToAll}
          />

          <Text
            className={styles.optionLabel}
            size="small"
          >
            {strings.adjustmentPercentage}
          </Text>
          <Input
            className={styles.input}
            value={adjustBasePrice ? adjustmentPercentage : ''}
            onChange={handleAdjustmentPercentageChange}
            disabled={!editable || !applyToAll || !adjustBasePrice}
            state={adjustBasePrice && (Number(adjustmentPercentage) === 0) ? "error" : "normal"}
          />
        </div>
      </div>
    </div>
  );
};

GlobalItemAdjustmentSettingsBox.propTypes = {
};

GlobalItemAdjustmentSettingsBox.defaultProps = {
  editable: true,
};


export default GlobalItemAdjustmentSettingsBox;
