import { isUndefined, isNullOrUndefined } from 'Common/utils/utils';

export const loadTokens = () => {
  try {
    const serializedTokens = localStorage.getItem('tokens');

    if (isNullOrUndefined(serializedTokens)) {
      return undefined;
    }

    return { ...JSON.parse(serializedTokens) };
  } catch (error) {
    return undefined;
  }
};

export const saveTokens = (tokens) => {
  try {
    if (tokens !== undefined) {
      const serializedTokens = JSON.stringify(tokens);
      localStorage.setItem('tokens', serializedTokens);
    } else {
      localStorage.removeItem('tokens');
    }
  } catch (error) {
    console.log(error);
  }
};

export const loadAccessToken = () => {
  try {
    const serializedTokens = localStorage.getItem('tokens');

    if (serializedTokens == null) {
      return undefined;
    }

    return JSON.parse(serializedTokens).accessToken;
  } catch (error) {
    return undefined;
  }
};

export const clearTokens = () => {
  localStorage.removeItem('tokens');
};

export const hasTokens = () =>
  !isUndefined(loadTokens());

export const isAdmin = (args) =>
  args === 'Administrator' || args === 'Developer';
