import { useContext, useCallback } from 'react';
import axios from 'axios';

import { AccountManagersContext } from 'Admin/contexts/AccountManagersContext';

import useAuth from 'Common/hooks/useAuth';

const useAccountManagers = () => {
  const { state, dispatch } = useContext(AccountManagersContext);
  const {
    tokens: { accessToken },
  } = useAuth();

  const deleteAccountManager = async (href) => {
    dispatch({ type: 'delete_account_managers_requested' });

    try {
      await axios.delete(href, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({
        type: 'delete_account_managers_successful',
      });

      return null;
    } catch (e) {
      dispatch({ type: 'delete_account_managers_failed' });
      throw e;
    }
  };

  const replace = async (href, destinationAccountManagerId) => {
    dispatch({ type: 'replacing_account_manager_requested' });

    try {
      await axios.post(href, {
        ReplacementId: destinationAccountManagerId,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({
        type: 'replacing_account_manager_successful',
      });

      return null;
    } catch (e) {
      dispatch({ type: 'replacing_account_manager_failed' });
      throw e;
    }
  };

  return {
    ...state,
    delete: useCallback(deleteAccountManager, [dispatch, state]),
    replace: useCallback(replace, [dispatch]),
  };
};

export default useAccountManagers;
