import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Paragraph = ({
  children,
  kind = 'default',
}) => (
  <div>
    <p className={styles[kind]}>{children}</p>
  </div>
);

Paragraph.propTypes = {
  children: PropTypes.node,
  kind: PropTypes.oneOf(['default']),
};

export default Paragraph;
