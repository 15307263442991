import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

class CircularProgressBar extends React.Component {
  state = {
    sqSize: 200,
    strokeWidth: 20,
  };

  render() {
    // Size of the enclosing square
    const { sqSize, strokeWidth } = this.state;
    const { percentage, title, value, target, theme } = this.props;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * percentage / 100;

    return (
      <div className={styles.circleDiv}>
        <svg
          className={styles.svg}
          viewBox={viewBox}
        >
          <circle
            className={styles.circleBackground}
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
          />
          <circle
            className={classnames(styles.circleProgress, styles[theme])}
            cx={sqSize / 2}
            cy={sqSize / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />
          <text
            className={classnames(styles.circleText, styles[theme], (!isUndefined(target) ? styles.smallCircleText : ''))}
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle"
          >
            {`${value}`}
          </text>
          {
            !isUndefined(target) && (
              <text
                className={styles.circleTextSubtitle}
                x="50%"
                y="50%"
                dy="1.7em"
                textAnchor="middle"
              >
                {target}
              </text>
            )
          }
        </svg>
        <h2 className={styles.title}>{title.toUpperCase()}</h2>
      </div>
    );
  }
}

CircularProgressBar.propTypes = {
  percentage: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.string,
  target: PropTypes.string,
  theme: PropTypes.oneOf(['error', 'warning', 'success']),
};

export default CircularProgressBar;
