import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'Common/hooks/useAuth';

import { isUndefined } from 'Common/utils/utils'
import { isAdmin } from 'Common/utils/authUtils';

export default () => {
 return () => {
    const { loggedIn, logout, claims } = useAuth();
    console.log("default route");

    if (!loggedIn) {
      return <Redirect to="/login" />;
    }

    if (isUndefined(claims)) {
      logout();
      return <Redirect to="/login" />;
    }

    const { role } = claims;

    if (isAdmin(role)) {
      console.log("redirecting to /account/dashboard");
      return <Redirect to="/admin/dashboard" />;
    }

    console.log("redirecting to /account/dashboard");
    return <Redirect to="/account/dashboard" />;
  };
};
