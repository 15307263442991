import React from 'react';
import PropTypes from 'prop-types';

import Text from 'Common/components/Text';
import HorizontalRule from 'Common/components/HorizontalRule';

import styles from './styles.module.css';

const Heading = ({
  title,
}) => (
  <div
    className={styles.wrapper}
  >
    <h2>
      <Text
        bold
        size="x-large"
        className={styles.title}
      >
        {title}
      </Text>
    </h2>
    <HorizontalRule
      className={styles.horizontalRule}
    />
  </div>
);

export default Heading;
