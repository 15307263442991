import { useContext, useCallback } from 'react';

import { AppContext } from 'Common/contexts/AppContext';

const useAuth = () => {
  const { state, dispatch } = useContext(AppContext);

  const toggleSidebar = () => {
    dispatch({ type: 'toggle_sidebar' });
  };

  return {
    ...state,
    toggleSidebar: useCallback(toggleSidebar, [dispatch]),
  };
};


export default useAuth;
