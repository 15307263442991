import React from 'react';

import { Row, Col } from 'react-bootstrap';

const PrivacyPolicyPage = () => (
  <Row>
    <Col>
      <h1>Privacy policy</h1>
      <p>This statement was last updated on 30/01/2009.</p>
      <p>We are committed to protecting the privacy of all personal information obtained from you during your visits to this website.</p>
      <p>This privacy policy explains what personal information we may collect from you and the purposes for which it will be used. By using this website you give your agreement to the data practices described in this policy.</p>

      <h1>What information do we collect and how do we use it?</h1>
      <p>The types of information and uses are explained below.</p>
      <p>When you register with our site, we may ask whether you would like to be added to our mailing list or to receive future updates from us, so that we can tell you about our services. If you are a registered user, we may use information we collect to offer personalised information when you use the website, notify you of changes to our service, ask your opinions and to help us improve our service to you.</p>

      <h2>1. Information that you provide to us</h2>
      <p>We collect and process information that you provide for specific purposes. This may include demographic information such as your name, email addresses, mailing address and telephone number. We use the information to provide you with the specific information, product or services that you request from us and to communicate with you. If you contact us we may keep a record of that correspondence.</p>
      <p>You will be asked to provide information about yourself when you use certain site facilities, including the following:</p>
      <ul>
        <li>&quot;Request a Catalogue&quot; - the name and address supplied is used by us to ensure that you receive a harcopy catalogue that you have requested.</li>
        <li>&quot;Register Your Account&quot; - when you register your account details, we will create a User Name and associated password that you supply to us. Your name, User Name, password and email address will be stored on our servers. You can change your stored information at any time by logging in or contacting us by telephone, email, or mail.</li>
        <li>&quot;Contact us&quot; - you will be asked for contact information if you request a call or more information from us.</li>
      </ul>

      <h2>2. Website usage information</h2>
      <p>We receive and store certain information whenever you interact with us. We collect website usage information from a number of sources:</p>

      <ul>
        <li>Our web server.</li>
        <li>URLs passed to us when you enter our site.</li>
        <li>Some information entered by you on forms on the website.</li>
        <li>Internet service providers.</li>
        <li>Search engine companies.</li>
        <li>IP addresses.</li>
        <li>Cookies.</li>
      </ul>

      <p>
        We collect web usage information during your visits to our site including information on the date, time, page viewed or searched for, catalogues ordered, services used, referals made and other information relating to your browsing activity on our website. Where you are a registered user of our website and have logged in, we may collect web usage information to enable us to build a demographic profile. We may also use web usage information to create statistical data regarding the use of our website. We may then use or disclose that statistical data to others for marketing and strategic development purposes, but no individuals will be identified in such statistical data.
      </p>

      <p>We collect web usage information to help us:</p>

      <ul>
        <li>assess the effectiveness of marketing campaigns</li>
        <li>develop and deliver services and information that better meet your needs</li>
        <li>inform you of events, services and products which we believe to be relevant to you, where you have consented to be contacted for such purposes</li>
      </ul>

      <h2>3. IP Addresses</h2>

      <p>
        We use IP addresses to analyse trends, track users&apos; movement, and gather broad demographic information for aggregate use.
        IP addresses are not linked to personally identifiable information.
      </p>

      <h2>4. Cookies</h2>

      <p>
        Cookies are small files that are often created when you visit a website, and which are stored in the cookie directory of your computer.
        A cookie often includes an anonymous unique identifier. Cookies do not damage your computer. Each website can send its own cookie to your browser if your browser&apos;s preferences allow it, but to protect your privacy your browser only permits a website to access the cookies t has already sent to you, not the cookies sent to you by other sites. You do not have to accept cookies. You can decide if you want to
        accept cookies by changing the settings on your browser to either accept all cookies, reject all cookies, or notify you when a cookie is set.
        Switching off cookies will still allow you to view the some of the content on our website, although it may restrict your use of our interactive services.
      </p>

      <p>There are two types of cookie. Persistent cookies remain on your computer for a specified time. We use persistent cookies to:</p>
      <ul>
        <li>identify that a registered user has returned</li>
        <li>collect information for use as described above.</li>
      </ul>

      <p>Session-specific cookies are deleted when you leave the website. We use session-specific cookies to:</p>

      <ul>
        <li>hold your details when you are logged into the site</li>
        <li>collect information for use as described above</li>
      </ul>

      <h2>5. Javascript</h2>

      <p>
        We use javascript, a simple web scripting language, for interaction with users and to provide a streamlined user experience.
        We also employ it to collect web usage information for the purposes described above.
      </p>

      <h2>Disclosure of information</h2>

      <p>
        We may disclose information to government bodies and law enforcement agencies for their enforcement purposes. We may also disclose information provided by you to other third parties where this is necessary for the purposes set out in this privacy policy, and to suppliers that we engage to process your information on our behalf. We will only disclose information about you to others where the law allows. We will not sell, rent or disclose your information to any third parties other than those set out in this privacy policy without your consent. We do not transfer your personal information outside of the European Economic Area.
      </p>

      <h2>Linking to other websites</h2>
      <p>
      This website may contain links to other third party websites. Anonymous visit or session information may be passed to other
      websites for the web usage information purposes described above. None of this information can be related to an individual user
      of the website. The operators of other sites may collect information from you which will be used by them in accordance with their own data protection policy.
      </p>
      <p>This privacy policy applies only to our website. We are not responsible for the privacy practices within any other websites.
      You should always be aware of this when you leave this website and we encourage you to read the privacy statement on any other website that you visit.
      </p>
      <h2>Security</h2>

      <p>
        All information you provide to us is stored on secure servers. We use leading technologies and security measures to safeguard your information and keep strict security standards to prevent any unauthorised access to it. Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential.
        We ask you not to share a password with anyone.
      </p>

      <p>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transaction is at your own risk. Once we have recieved your information, we will use strict procedures and security features to try to prevent unauthorised access.
      </p>

      <h2>Changes to this policy</h2>

      <p>This privacy policy will change from time to time as we develop the range of services on the website. When this privacy policy changes,
      we will place an updated version on this page and where appropriate we will give you the opportunity to review the revised privacy
      policy and ask you to agree to it. Regularly reviewing this page ensures that you are always aware of what information we collect,
      how we use it and under what circumstances, if any, we will share it with other parties. You should continue to check the privacy
      policy before using this site, even if you have visited it in the past. This ensures that you will be aware of any changes.
      </p>
      <h2>Your rights</h2>

      <p>If your use of this site falls under the Data Protection Act as a personal user, you have a right to request a copy of any information
      held about you. If you wish to do this, please contact us, explaining that your interest is to review your personal information.
      Except where the law obliges us to keep your data, we will ensure that your data is gathered and/or purged from the servers
      managed by BACA or third party service providers.  We will need to receive appropriate identification that proves you are the correct
      person to request the information.
      </p>
    </Col>
  </Row>
);

export default PrivacyPolicyPage;
