import React from 'react';
import { Redirect } from 'react-router-dom';
import priceAdjustmentState from 'Admin/constants/priceAdjustmentState';

export default (WrappedComponent, priceAdjustment) => {
  return () => {
    if (priceAdjustment.state !== priceAdjustmentState.completed) {
      return <Redirect to={`/admin/price-adjustment/${priceAdjustment.id}`} />;
    }
    return <WrappedComponent />;
  };
};
