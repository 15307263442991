import React, { useContext, useEffect } from 'react';

import Sidebar from 'Admin/components/Sidebar';

import useApp from 'Common/hooks/useApp';
import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';

import { isUndefined, isNull } from 'Common/utils/utils';

const SidebarContainer = () => {
  const { sidebar: { strings, loading: loadingStrings }, fetchSidebarStrings } = useStrings();
  const { user } = useUser();

  const { sidebarCollapsed } = useApp();

  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchSidebarStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchSidebarStrings]);

  return (
    <Sidebar
      collapsed={sidebarCollapsed}
      optionGroups={!isNull(strings) ? strings.optionGroups : []}
    />
  );
};

export default SidebarContainer;
