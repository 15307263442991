import React from 'react';

import { Row, Col } from 'react-bootstrap';

import Text from 'Common/components/Text';
import PageTitle from 'Common/components/PageTitle';
import Breadcrumbs from 'Common/components/Breadcrumbs';
import PriceAdjustmentInfoBox from 'Admin/components/PriceAdjustments/PriceAdjustmentInfoBox';

import pageActions from 'Common/constants/pageActions';
import priceAdjustmentState from 'Admin/constants/priceAdjustmentState';
import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { isNull } from 'Common/utils/utils';
import { toLocalDateTime } from 'Common/utils/dateUtils';

const PriceAdjustmentInfoBar = ({
  priceAdjustment,
  strings,
  pageAction,
  pageTitle,
}) => {
  if (isNull(strings)) {
    return null;
  }

  const breadcrumbPaths = [
    {
      pathname: '/admin/price-adjustment/:id/info',
      title: strings.breadcrumbs.info,
    },
    {
      pathname: '/admin/price-adjustment/:id/items',
      title: strings.breadcrumbs.items,
    },
    {
      pathname: '/admin/price-adjustment/:id/clients',
      title: strings.breadcrumbs.clients,
    },
    {
      pathname: '/admin/price-adjustment/:id/schedule',
      title: strings.breadcrumbs.schedule,
    },
    {
      pathname: '/admin/price-adjustment/:id/review',
      title: strings.breadcrumbs.review,
    },
  ];

  return (
    <div>
      <Row>
        <Col xs={12} md={9}>
          <Text italic>{pageTitle}</Text>
          <PageTitle>{strings.title}</PageTitle>
          {(pageAction !== pageActions.creating) && (
            <Text
              bold
              theme="black"
              size="small"
            >
              {priceAdjustment.title}
            </Text>
          )}
        </Col>
        <Col xs={12} md={3}>
          {(pageAction !== pageActions.creating) && (
            <PriceAdjustmentInfoBox
              id={priceAdjustment.id}
              state={priceAdjustment.state}
              stateText={priceAdjustment.state}
              scheduledText={strings.executionTypes.scheduled}
              executionText={(priceAdjustment.executionType === priceAdjustmentExecutionType.immediate)
                ? strings.executionTypes.immediate
                : (toLocalDateTime(priceAdjustment.scheduledDate))}
            />
          )}
        </Col>
      </Row>


      {(pageAction === pageActions.editing && (priceAdjustment.state === priceAdjustmentState.draft || priceAdjustment.state === priceAdjustmentState.confirmed)) && (
        <Row>
          <Col xs={12} className="mt-4">
            <Breadcrumbs
              paths={breadcrumbPaths.map((path) => ({
                ...path,
                pathname: path.pathname.replace(':id', priceAdjustment.id),
              }))}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

PriceAdjustmentInfoBar.propTypes = {
};

PriceAdjustmentInfoBar.defaultProps = {
};

export default PriceAdjustmentInfoBar;
