import { useContext, useCallback } from 'react';
import axios from 'axios';

import { LocalesContext } from 'Common/contexts/LocalesContext';

const useLocales = () => {
  const { state, dispatch } = useContext(LocalesContext);

  const fetch = async () => {
    dispatch({ type: 'fetch_locales_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/locales`);

      dispatch({
        type: 'fetch_locales_successful',
        payload: {
          locales: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_locales_failed' });
      throw e;
    }
  };

  return {
    ...state,
    fetch: useCallback(fetch, [dispatch]),
  };
};

export default useLocales;
