import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Text from 'Common/components/Text';

import styles from './styles.module.css';

const AdjustmentSaveChangesBox = ({
  title,
  message,
  buttonText,
  className,
  onSaveChangesClick = () => {},
  disabled,
}) => (
  <div className={classnames(styles.container, className)}>
    <Text
      bold
      size="small"
    >
      {title}
    </Text>
    <Text
      size="small"
    >
      {message}
    </Text>
    <Button
      className="ml-3"
      theme="blue"
      onClick={onSaveChangesClick}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  </div>
);

AdjustmentSaveChangesBox.propTypes = {
};

AdjustmentSaveChangesBox.defaultProps = {
};


export default AdjustmentSaveChangesBox;
