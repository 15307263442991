import React from 'react';
import PropTypes from 'prop-types';

import { StyledLink as Link } from './styles';

import { isEmpty } from '../../utils/utils';

const LinkComponent = ({
  className,
  children,
  to = "",
}) => (
  <Link
    className={className}
    to={to}
    isActive={!isEmpty(to)}
  >
    {children}
  </Link>
);

LinkComponent.propTypes = {
  to: PropTypes.string
};

export default LinkComponent;
