import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Container, Row, Col } from 'react-bootstrap';

import LoginForm from 'Common/components/LoginForm';
import Space from 'Common/components/Space';
import BackgroundImage from 'Common/components/BackgroundImage';

import useAuth from 'Common/hooks/useAuth';
import useInput from 'Common/hooks/useInput';

import { isUndefined } from 'Common/utils/utils';

import { login as strings } from 'Common/config/strings';

const LoginPage = () => {
  // State
  const { value: username, onChange: onUsernameChange, validation: usernameValidation } = useInput('sys.admin');
  const { value: accountNumber, onChange: onAccountNumberChange, validation: accountNumberValidation } = useInput('');
  const { value: password, onChange: onPasswordChange, validation: passwordValidation } = useInput('eprocelo');

  const [inputErrors, setInputErrors] = useState({
    username: undefined,
    accountNumber: undefined,
    password: undefined,
  });
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [numberOfAccounts, setNumberOfAccounts] = useState(0);

  const auth = useAuth();

  const verifyUsername = async () => {
    if (usernameValidation.isEmpty) {
      setErrorMessage('Please supply a username');
      return;
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/auth/username`, {
        username,
        accountNumber: (!accountNumberValidation.isEmpty) ? accountNumber : null,
        password,
      });

      setNumberOfAccounts(data);

      if (data === 0) {
        setErrorMessage('There is no account with the username/email supplied');
      }
    } catch (e) {
      setErrorMessage('Unable resolve username. Please try again later.');
    }
  };

  const validateLoginInputs = () => {
    const newInputErrors = {
      username: (() => (usernameValidation.isEmpty
        ? strings.validationMessages.emptyUsername
        : undefined))(),

      password: (() => (passwordValidation.isEmpty
        ? strings.validationMessages.emptyPassword
        : undefined))(),
    };

    setInputErrors(newInputErrors);

    return (
      isUndefined(newInputErrors.username)
      && isUndefined(newInputErrors.password)
    );
  };

  const login = async () => {
    try {
      if (!validateLoginInputs()) {
        // setIsLoggingIn(false);
        return;
      }

      await auth.login(
        username,
        password,
        (!accountNumberValidation.isEmpty) ? accountNumber : null,
      );
    } catch (e) {
      if (!isUndefined(e.response) && e.response.status === 400) {
        setErrorMessage(strings.responseMessages.failed);
      } else {
        setErrorMessage(strings.responseMessages.failed);
      }
    }
  };

  const onSubmit = () => {
    if (numberOfAccounts === 0) {
      verifyUsername();
    } else {
      login();
    }
  };

  const onBack = () => {
    setNumberOfAccounts(0);
  };

  // Effects
  useEffect(() => {
    setInputErrors({
      username: undefined,
      accountNumber: undefined,
      password: undefined,
    });
    setErrorMessage(undefined);
  }, [username, accountNumber, password]);

  return (
    <Container>
      <BackgroundImage src={`${process.env.REACT_APP_ASSET_URL}/website/login-bg.${process.env.REACT_APP_LOGIN_BACKGROUND_FILETYPE}`} />
      <Space height="4vh" />
      <Row className="justify-content-center">
        <img src={`${process.env.REACT_APP_ASSET_URL}/website/logo.png`} alt="vbop logo" />
      </Row>
      <Row>
        <Col sm={{ span: 8, offset: 2 }}>
          <div className="description">
            <p
              style={{
                color: 'white',
                padding: '30px 0px',
                textAlign: 'center',
                fontSize: '1.1em',
              }}
            >
              Welcome to the new website.
              <br />
              This site is still in development, but we are giving you access to it and some of the features it has to offer.
              <br />
              Enjoy!
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: 6, offset: 3 }}>
          <LoginForm
            username={username}
            accountNumber={accountNumber}
            password={password}
            buttonText={(numberOfAccounts === 0) ? 'Next' : 'Login'}
            onSubmit={onSubmit}
            onUsernameChange={onUsernameChange}
            onAccountNumberChange={onAccountNumberChange}
            onPasswordChange={onPasswordChange}
            onBack={onBack}
            noOfAccounts={numberOfAccounts}
            errorMessage={errorMessage}
            inputErrors={inputErrors}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
