const localesReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    // Fetch Locales
    case 'fetch_locales_requested':
      return {
        ...state,
        loading: true,
      };
    case 'fetch_locales_successful':
      return {
        ...state,
        loading: false,
        locales: payload.locales,
      };
    case 'login_locales_failed':
      return {
        ...state,
        loading: false,
        locales: [],
      };
    default:
      return state;
  }
};

export default localesReducer;
