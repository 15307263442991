import React from 'react';
import { Link } from 'react-router-dom';

import styles from './styles.module.css';

const FooterComponent = () => (
  <div className={styles.wrapper}>
    <hr className={styles.line} />
    <div className={styles.footer}>
      <ul>
        <li className={styles.link}>&copy; 2019 Tecto Ltd</li>
        <li><Link to="/terms-and-conditions" className={styles.link}>Terms &amp; Conditions</Link></li>
        <li><Link to="/privacy-policy" className={styles.link}>Privacy Policy</Link></li>
      </ul>
    </div>
  </div>
);

export default FooterComponent;
