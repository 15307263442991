import React from 'react';
import { ToastProvider } from 'react-toast-notifications';

import App from 'Admin/App';
import Providers from 'Admin/contexts/Providers';

export default () => (
  <Providers>
    <ToastProvider autoDismissTimeout={4000}>
      <App />
    </ToastProvider>
  </Providers>
);
