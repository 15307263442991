import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const Panel = ({
  className,
  theme,
  scrollable,
  height,
  children,
}) => (
  <div
    className={classnames(
      styles.panel,
      styles[theme],
      scrollable && styles.scrollable,
      className,
    )}
    style={
      height && {
        height,
      }
    }
  >
    {children}
  </div>
);

Panel.propTypes = {
  theme: PropTypes.oneOf(['default', 'grey']),
  scrollable: PropTypes.bool,
  children: PropTypes.node,
};

Panel.defaultProps = {
  theme: 'default',
  scrollable: false,
  children: PropTypes.node,
};


export default Panel;
