import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  color: #0281CD;
  font-weight: bold;

  ${props =>
    !props.isActive && `color: #606060; pointer-events: none;`}

  font-size: small;
`;