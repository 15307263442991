import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import usePagedFetch from 'Common/hooks/usePagedFetch';

import PageSelector from 'Common/components/PageSelector';
import Text from 'Common/components/Text';
import Space from 'Common/components/Space';
import ProgressBar from 'Common/components/ProgressBar';
import ItemAdjustmentsSummaryTable from 'Admin/components/PriceAdjustments/ItemAdjustmentsSummaryTable';
import ClientItemAdjustmentsSummaryTable from 'Admin/components/PriceAdjustments/ClientItemAdjustmentsSummaryTable';

import { toLocalDateTime, formatElapseTime } from 'Common/utils/dateUtils';

const PriceAdjustmentProcessingTab = ({
  priceAdjustment,
  strings: { processing: strings },
}) => {
  const { id } = useParams();

  const { loading: loadingInitialItemAdjustmentsCheck, totalRecords: totalItemAdjustments, refetch: recheckItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/items?pageSize=1`);
  const { loading: loadingInitialClientItemAdjustmentsCheck, totalRecords: totalClientItemAdjustments, refetch: recheckClientItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/client-items?pageSize=1`);

  const [itemAdjustmentsPage, setItemPriceAdjustmentsPage] = useState(1);
  const [clientItemPriceAdjustmentsPage, setClientItemPriceAdjustmentsPage] = useState(1);

  const { results: itemAdjustments, loading: loadingItemAdjustments, currentPage: currentItemAdjustmentPage, totalPages: totalCurrentItemAdjustmentPages, refetch: refetchItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/items?pageSize=100&page=${itemAdjustmentsPage}&sortBy=completed&sortDesc=true`);
  const { results: clientItemAdjustments, loading: loadingClientItemAdjustments, currentPage: currentClientItemAdjustmentPage, totalPages: totalCurrentClientItemAdjustmentPages, refetch: refetchClientItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/client-items?pageSize=100&page=${clientItemPriceAdjustmentsPage}&sortBy=completed&sortDesc=true`);

  const handleItemAdjustmentPageChange = (pageNumber) => {
    setItemPriceAdjustmentsPage(pageNumber);
  };

  const handleClientItemAdjustmentPageChange = (pageNumber) => {
    setClientItemPriceAdjustmentsPage(pageNumber);
  };

  return (
    <div>
      <Row className="justify-content-md-center">
        <Col xs={8}>
          <Row className="justify-content-md-center">
            <Col xs={9} className="text-align-center">
              <Text size="x-large" theme="black">{priceAdjustment.title}</Text>
              <div className="my-4">
                <Text size="x-small">{id}</Text>
              </div>
            </Col>
          </Row>

          <Space height="2em" />

          <Row className="justify-content-md-center">
            <Col xs={8}>
              <Row>
                <Col xs={6} className="text-align-center">
                  <Text size="small" theme="black" bold className="mr-3">{strings.labels.dateExecuted}</Text>
                  <Text size="small">{toLocalDateTime(Date.parse(priceAdjustment.dateExecuted))}</Text>
                </Col>
                <Col xs={6} className="text-align-center">
                  <Text size="small" theme="black" bold className="mr-3">{strings.labels.elapseTime}</Text>
                  <Text size="small">{formatElapseTime(new Date() - new Date(priceAdjustment.dateExecuted))}</Text>
                </Col>
              </Row>
            </Col>
          </Row>

          <Space height="3em" />

          <Row className="justify-content-md-center">
            <Col xs={8} className="text-align-center">
              <Text
                size="small"
              >
                {`${Math.floor(priceAdjustment.percentageComplete)}%`}
              </Text>
              <ProgressBar
                percentage={priceAdjustment.percentageComplete}
                theme="green"
              />
              <Space height="0.5em" />
              <Text
                size="x-small"
                italic
              >
                {(!loadingClientItemAdjustments && clientItemAdjustments.length > 0)
                  ? `${strings.statues.processing}...` : `${strings.statues.initializing}...`}
              </Text>
            </Col>
          </Row>

          <Space height="3em" />

          {(totalItemAdjustments > 0 || priceAdjustment.adjustAllItems) && (
            <>
              <Row>
                <Col xs={12}>
                  <h2>{strings.subHeadings.itemAdjustments}</h2>
                </Col>
              </Row>

              {totalItemAdjustments > 0 && (
                <>
                  <Row>
                    <Col xs={12}>
                      <ItemAdjustmentsSummaryTable
                        headingStrings={{
                          productCode: strings.itemAdjustmentTable.headings.productCode,
                          title: strings.itemAdjustmentTable.headings.title,
                          listPrice: strings.itemAdjustmentTable.headings.listPrice,
                          adjustBasePrice: strings.itemAdjustmentTable.headings.adjustBasePrice,
                          adjustmentPercentage: strings.itemAdjustmentTable.headings.adjustmentPercentage,
                          completed: strings.itemAdjustmentTable.headings.completed,
                        }}
                        itemAdjustments={itemAdjustments}
                        loading={loadingItemAdjustments}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <PageSelector
                        currentPage={currentItemAdjustmentPage}
                        maxPages={totalCurrentItemAdjustmentPages}
                        onChange={handleItemAdjustmentPageChange}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {(totalItemAdjustments === 0 && priceAdjustment.adjustAllItems) && (
                <Row>
                  <Col xs={12}>
                    <Text size="small">{`${strings.generatingText.itemAdjustments}...`}</Text>
                  </Col>
                </Row>
              )}

              <Space height="3em" />
            </>
          )}


          {(totalClientItemAdjustments > 0 || priceAdjustment.adjustAllClients) && (
            <>
              <Row>
                <Col xs={12}>
                  <h2>{strings.subHeadings.clientItemAdjustments}</h2>
                </Col>
              </Row>

              {totalClientItemAdjustments > 0 && (
                <>
                  <Row>
                    <Col xs={12}>
                      <ClientItemAdjustmentsSummaryTable
                        headingStrings={{
                          accountNumber: strings.clientItemAdjustmentTable.headings.accountNumber,
                          name: strings.clientItemAdjustmentTable.headings.clientName,
                          productCode: strings.clientItemAdjustmentTable.headings.productCode,
                          title: strings.clientItemAdjustmentTable.headings.itemTitle,
                          listPrice: strings.clientItemAdjustmentTable.headings.listPrice,
                          adjustBasePrice: strings.clientItemAdjustmentTable.headings.adjustBasePrice,
                          adjustmentPercentage: strings.clientItemAdjustmentTable.headings.adjustmentPercentage,
                          completed: strings.clientItemAdjustmentTable.headings.completed,
                        }}
                        clientItemAdjustments={clientItemAdjustments}
                        loading={loadingClientItemAdjustments}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <PageSelector
                        currentPage={currentClientItemAdjustmentPage}
                        maxPages={totalCurrentClientItemAdjustmentPages}
                        onChange={handleClientItemAdjustmentPageChange}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {(totalClientItemAdjustments === 0 && priceAdjustment.adjustAllClients) && (
                <Row>
                  <Col xs={12}>
                    <Text size="small">{`${strings.generatingText.clientItemAdjustments}...`}</Text>
                  </Col>
                </Row>
              )}

              <Space height="3em" />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PriceAdjustmentProcessingTab;
