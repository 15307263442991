import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import defaultRoute from 'Common/hocs/routing/defaultRoute';
import authRoute from 'Common/hocs/routing/authRoute';
import unAuthRoute from 'Common/hocs/routing/unAuthRoute';

import Main from 'Common/pages/Main';
import Login from 'Common/pages/Login';
import Logout from 'Common/pages/Logout';

import PrivacyPolicy from 'Common/pages/PrivacyPolicy';
import TermsAndConditions from 'Common/pages/TermsAndConditions';

const Routes = () => {
  const location = useLocation();

  useEffect(() => {
    //console.log(`location: ${location.pathname}`);
  }, [location]);

  return (
    <Switch>
      <Route exact path="/" component={defaultRoute(Main)} />

      <Route path="/login" component={unAuthRoute(Login)} />
      <Route path="/logout" component={authRoute(Logout)} />

      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />

      <Route path="/*" component={authRoute(Main)} />
    </Switch>
  );
};

export default Routes;
