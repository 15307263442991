const clientReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    // Fetch Client
    case 'fetch_client_requested':
      return {
        ...state,
        loading: true,
      };
    case 'fetch_client_successful':
      return {
        ...state,
        loading: false,
        client: payload.client,
      };
    case 'login_client_failed':
      return {
        ...state,
        loading: false,
        client: undefined,
      };
    default:
      return state;
  }
};

export default clientReducer;
