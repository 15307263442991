import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

import DashboardOption from '../DashboardOption';

const DashboardOptionsList = ({
  options,
}) => (
  <div className={styles.wrapper}>
    {
      options.map((option) => (
        <DashboardOption
          key={option.page}
          title={option.title}
          description={option.description}
          redirect={option.redirect}
        />
      ))
    }
  </div>
);

DashboardOptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    page: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    redirect: PropTypes.string,
  })).isRequired,
};

export default DashboardOptionsList;
