import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const Checkbox = ({
  className,
  checked,
  label,
  name = "",
  onChange = () => {},
  onChangeRaw = () => {},
  disabled = false,
  checkable = false,
}) => {
  const handleOnChange = (e) => {
    if (!checkable) {
      return;
    }

    onChange(e.target.checked);
    onChangeRaw(e);
  };

  return (
    <div className={classnames(styles.container, className)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={handleOnChange}
        name={name}
        disabled={disabled}
      />
      {!isUndefined(label) && (
        <label
          for={name}
          className={classnames("ml-2", styles.label)}
        >
          {label}
        </label>
      )}
    </div>
  );
};

Checkbox.propTypes = {
};

Checkbox.defaultProps = {
  checked: false,
};

export default Checkbox;
