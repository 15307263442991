import React from 'react';

import Header from 'Admin/components/Header';

import useApp from 'Common/hooks/useApp';
import useUser from 'Common/hooks/useUser';
import useBranch from 'Common/hooks/useBranch';
import useClient from 'Common/hooks/useClient';

import { isUndefined } from 'Common/utils/utils';

const HeaderContainer = () => {
  const { toggleSidebar } = useApp();

  const { user } = useUser();
  const { branch } = useBranch();
  const { client } = useClient();

  const handleMenuClick = () => {
    toggleSidebar();
  };

  const userData = !isUndefined(user) ? user : {
    firstName: undefined,
    emailAddress: undefined,
    profilePictureUrl: undefined,
  };
  const branchData = !isUndefined(branch) ? branch : {
    accountNumber: undefined,
  };

  return (
    <Header
      fullName={`${userData.firstName} ${userData.lastName}`}
      username={userData.username}
      emailAddress={userData.emailAddress}
      profilePicture={userData.profilePictureUrl}
      accountNumber={branchData.accountNumber}
      clientName={client && client.name}
      logo={client && client.logoUrl}
      versionNumber={`v${process.env.REACT_APP_VERSION}`}
      onMenuClick={handleMenuClick}
    />
  );
};

export default HeaderContainer;
