import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import FileDownload from 'js-file-download';
// import { MenuItem } from '@trendmicro/react-dropdown';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Row, Col } from 'react-bootstrap';

import { useToasts } from 'react-toast-notifications';
import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAuth from 'Common/hooks/useAuth';
import usePageSizeSelector from 'Common/hooks/usePageSizeSelector';

import PageTitle from 'Common/components/PageTitle';
import Paragraph from 'Common/components/Paragraph';
import FiltersBox from 'Common/components/FiltersBox';
import Button from 'Common/components/Button';
import Dropdown from 'Common/components/Dropdown';
import Input from 'Common/components/Input';
import Space from 'Common/components/Space';
import ClientsTable from 'Admin/components/ClientsTable';
import CountriesSelector from 'Common/components/CountriesSelector';
// import ButtonDropdown from 'Common/components/ButtonDropdown';
import DateRangeFilter from 'Common/components/DateRangeFilter';
import PageSelector from 'Common/components/PageSelector';
import PageSizeSelector from 'Common/components/PageSizeSelector';
import HorizontalOptionSelector from 'Common/components/HorizontalOptionSelector';
import LoadingIcon from 'Common/components/LoadingIcon';

import { isUndefined, isNull } from 'Common/utils/utils';

import timeframeType from 'Common/constants/timeframeType';
import timeRangeFiltersType from 'Common/constants/timeRangeFiltersType';


import calanderIcon from 'Common/assets/icons/calendar_icon.png';

const defaultClientFilters = {
  page: 1,
  orderProcessType: undefined,
  status: undefined,
  search: '',
  sortBy: 'accountNumber',
  sortDescending: false,
  country: undefined,
  createdDateRange: {
    startDate: undefined,
    endDate: undefined,
  },
  startsWith: undefined,
};

const ClientsPage = () => {
  const { addToast } = useToasts();

  const { clients: { strings, loading: loadingStrings }, fetchClientsStrings } = useStrings();
  const { user } = useUser();
  const { tokens: { accessToken }} = useAuth();

  const [clientFilters, setClientFilters] = useState(defaultClientFilters);
  const [clientsLink, setClientsLink] = useState(null);
  const { results: clients, loading: loadingClients, currentPage, totalPages, totalResults, data: clientsData } = usePagedFetch(clientsLink);

  const [selectedValues, setSelectedValues] = useState(defaultClientFilters);
  const { pageSizes, pageSize, setPageSize } = usePageSizeSelector([10, 25, 50, 75, 100], 25);

  const [loadingReport, setLoadingReport] = useState(false);

  const [timeframe, setTimeframe] = useState(timeframeType.allTime);
  const [rangeFilter, setRangeFilter] = useState(timeRangeFiltersType.before);

  const [dateRangeFilterVisible, setDateRangeFilterVisible] = useState(false);

  // Functions
  const getQueryParameters = useCallback(() => {
    const params = {
      page: clientFilters.page,
      pageSize,
      search: clientFilters.search,
    };

    if (!isUndefined(clientFilters.sortBy)) {
      params.sortBy = clientFilters.sortBy;
      params.sortDesc = clientFilters.sortDescending;
    }

    if (!isUndefined(clientFilters.status)) params.status = clientFilters.status;
    if (!isUndefined(clientFilters.orderProcessType)) params.orderProcessType = clientFilters.orderProcessType;
    if (!isUndefined(clientFilters.startsWith)) params.startsWith = clientFilters.startsWith;
    if (!isUndefined(clientFilters.country)) params.country = clientFilters.country;

    const { createdDateRange } = clientFilters;
    if (!isUndefined(createdDateRange.startDate)) params.createdDate1 = createdDateRange.startDate;
    if (!isUndefined(createdDateRange.endDate)) params.createdDatee = createdDateRange.endDate;

    return params;
  }, [clientFilters, pageSize]);

  const updateClientsLink = useCallback(() => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/clients`);
    const params = getQueryParameters();
    url.search = new URLSearchParams(params);
    setClientsLink(url.href);
  }, [getQueryParameters]);

  // const getReportFilename = (fileType) => {
  //   const baseFilename = `Clients_${new Date().toISOString()}`;

  //   if (fileType === 'csv') {
  //     return `${baseFilename}.csv`;
  //   }

  //   if (fileType === 'xlsx') {
  //     return `${baseFilename}.xlsx`;
  //   }

  //   return baseFilename;
  // };

  // const fetchReport = async (fileType) => {
  //   const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/clients`);
  //   const params = getQueryParameters();
  //   url.search = new URLSearchParams(params);

  //   try {
  //     setLoadingReport(true);

  //     const response = await axios.get(url.href, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     const filename = getReportFilename(fileType);
  //     FileDownload(response, filename);
  //   } catch (e) {
  //     addToast('Something went wrong while trying to generate report', {
  //       appearance: 'error',
  //       autoDismiss: true,
  //       pauseOnHover: false,
  //     });
  //   }

  //   setLoadingReport(false);
  // };

  // Handlers
  const handleSearchChange = (search) => {
    setSelectedValues({ ...selectedValues, search });
  };

  const handleOrderProcessingTypeChange = (orderProcessType) => {
    setSelectedValues({ ...selectedValues, orderProcessType });
    setClientFilters({ ...clientFilters, orderProcessType });
  };

  const handleStatusChange = (status) => {
    setSelectedValues({ ...selectedValues, status });
    setClientFilters({ ...clientFilters, status });
  };

  const handleApplySearchClick = () => {
    setClientFilters({ ...clientFilters, search: selectedValues.search });
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedValues({ ...selectedValues, createdDateRange: dateRange });
  };

  const handleApplyDateRangeClick = () => {
    setClientFilters({ ...clientFilters, createdDateRange: selectedValues.createdDateRange });
  };

  const handleInitialClick = (initial) => {
    setSelectedValues({ ...selectedValues, startsWith: initial });
    setClientFilters({ ...clientFilters, startsWith: initial });
  };

  const handleCountryClick = (country) => {
    setSelectedValues({ ...selectedValues, country });
    setClientFilters({ ...clientFilters, country });
  };

  const handlePageChange = (pageNumber) => {
    setSelectedValues({ ...selectedValues, page: pageNumber });
    setClientFilters({ ...clientFilters, page: pageNumber });
  };

  const handleResetFiltersClick = () => {
    setClientFilters(defaultClientFilters);
  };

  const handleTableHeadingClick = (column) => {
    // TODO: Check this is correct. If new column does it reset?
    const sortDescending = (selectedValues.sortBy === column) ? !selectedValues.sortDescending : selectedValues.sortDescending;
    setSelectedValues({
      ...selectedValues,
      sortBy: column,
      sortDescending,
    });
    setClientFilters({
      ...clientFilters,
      sortBy: column,
      sortDescending,
    });
  };

  const toggleDateFilteVisibilty = () => {
    setDateRangeFilterVisible(!dateRangeFilterVisible);
  };

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
  };

  const handleRangeFilterChange = (value) => {
    setRangeFilter(value);
  };

  // const handleExportReportClick = (fileType) => {
  //   if (totalResults > 50) {
  //     confirmAlert({
  //       title: 'Confirm Operation',
  //       message: 'This operation may take a long time with the number of clients. Are you sure you want to generate the report?',
  //       confirmLabel: 'Confirm',
  //       cancelLabel: 'Cancel',
  //       onConfirm: fetchReport(fileType),
  //     });
  //   } else {
  //     fetchReport();
  //   }
  // };

  // Effects
  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchClientsStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchClientsStrings]);

  useEffect(() => {
    updateClientsLink();
  }, [clientFilters, updateClientsLink, pageSize]);

  useEffect(() => {
    const resetSelectedCountryFilter = () => {
      setSelectedValues({ ...selectedValues, country: undefined });
      setSelectedValues({ ...clientFilters, country: undefined });
    };

    if (!isUndefined(selectedValues.country)) {
      if (!isUndefined(clientsData)) {
        if (!clientsData.countries.includes(selectedValues.country)) {
          setSelectedValues({ ...selectedValues, country: undefined });
          setSelectedValues({ ...clientFilters, country: undefined });
        }
      } else {
        resetSelectedCountryFilter();
      }
    }
  }, [clientFilters, clientsData, selectedValues]);

  // Rendering
  if (isNull(strings)) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs="12"><PageTitle>{strings.title}</PageTitle></Col>
      </Row>

      <Row>
        <Col md={10} xs={12}>
          <Space height="20px" />
          <Paragraph>
            {strings.subtitle}
          </Paragraph>
          <Space height="30px" />
        </Col>

        <Col md="2" xs="12">
          <Button kind="blue">
            {strings.addCustomerButton}
          </Button>
        </Col>
      </Row>

      <Row>
        <FiltersBox>
          <Col xs={12}>
            <Row>
              <Col>
                <Row>
                  <Input
                    width={400}
                    className="mr-5 mb-2"
                    onChange={handleSearchChange}
                    onEnterKeyPress={handleApplySearchClick}
                    name="search"
                    value={selectedValues.search}
                    placeholder={strings.searchPlaceholder}
                  />
                  <Button
                    className="mr-5 mb-2"
                    theme="blue"
                    onClick={handleApplySearchClick}
                  >
                    {strings.searchButton}
                  </Button>

                  <Dropdown
                    className="mr-5 mb-2"
                    options={[
                      {
                        value: 'active',
                        label: 'Active',
                      },
                      {
                        value: 'inactive',
                        label: 'Inactive',
                      },
                      {
                        value: 'onStop',
                        label: 'OnStop',
                      },
                    ]}
                    value={selectedValues.status}
                    onChange={handleStatusChange}
                    placeholder="Status"
                  />

                  <Dropdown
                    className="mr-5 mb-2"
                    options={strings.customerType.map((type) => ({
                      value: type.value.toString(),
                      label: type.name,
                    }))}
                    value={selectedValues.orderProcessType}
                    onChange={handleOrderProcessingTypeChange}
                    placeholder="Type"
                  />

                  <Button
                    className="mr-5 mb-2"
                    theme="filter"
                    onClick={toggleDateFilteVisibilty}
                  >
                    <img
                      style={{
                        width: '30px',
                        height: '28px',
                        margin: '-4px',
                      }}
                      src={calanderIcon}
                      alt="profile"
                    />
                  </Button>
                </Row>
              </Col>

              <Col md={{ span: 1, offset: 2 }}>
                <Button
                  onClick={handleResetFiltersClick}
                >
                  {strings.resetButton}
                </Button>
              </Col>
            </Row>

            {dateRangeFilterVisible && (
              <Row
                className="mt-4"
              >
                <DateRangeFilter
                  onChange={handleDateRangeChange}
                  value={selectedValues.createdDateRange}
                  timeframe={timeframe}
                  rangeFilter={rangeFilter}
                  onTimeframeChange={handleTimeframeChange}
                  onRangeFilterChange={handleRangeFilterChange}
                  onApplyClick={handleApplyDateRangeClick}
                />
              </Row>
            )}
          </Col>
        </FiltersBox>
      </Row>

      {!isUndefined(clientsData) && (
        <Row
          className="mt-4"
        >
          <Col md={9} xs={12}>
            <HorizontalOptionSelector
              options={clientsData.initials.map((initial) => ({
                value: initial,
                label: initial,
              }))}
              selectedOption={selectedValues.initial}
              onClick={handleInitialClick}
            />
          </Col>
          <Col md={3} xs={12}>
            <CountriesSelector
              countries={clientsData.countries}
              selectedCountry={selectedValues.countries}
              onClick={handleCountryClick}
            />
          </Col>
        </Row>
      )}

      <Row
        className="mt-4"
      >
        <Col>
          {/* <ButtonDropdown
            btnSize="xs"
            title="Export"
            disable={loadingReport || totalResults === 0}
            onSelect={handleExportReportClick}
          >
            <MenuItem eventKey="xlsx">Excel</MenuItem>
            <MenuItem eventKey="csv">CSV</MenuItem>
          </ButtonDropdown> */}

          <PageSizeSelector
            sizes={pageSizes}
            selectedValue={pageSize}
            onChange={setPageSize}
          />
        </Col>
      </Row>

      <Row
        className="justify-content-center py-3"
        style={{ textAlign: 'center' }}
      >

        <LoadingIcon
          visible={loadingClients}
        />
      </Row>

      {(clients.length === 0 && !loadingClients) && (
        <Row>
          <Col xs={12}>
            <Paragraph>{strings.noClientsText}</Paragraph>
          </Col>
        </Row>
      )}

      {(clients.length > 0 && !loadingClients) && (
        <Row>
          <Col xs="12">
            <Paragraph>
              {strings.showingText
                .replace('{0}', `${(currentPage - 1) * pageSize} - ${currentPage * pageSize > totalResults ? totalResults : currentPage * pageSize}`)
                .replace('{1}', totalResults)}
            </Paragraph>

            <ClientsTable
              headingStrings={strings.tableHeadings}
              actionStrings={{
                branches: strings.tableHeadings.branches,
                users: strings.tableHeadings.users,
                products: strings.tableHeadings.products,
                worksheets: strings.tableHeadings.worksheets,
              }}
              clients={clients}
              loading={loadingClients}
              sortBy={selectedValues.sortBy}
              sortDescending={selectedValues.sortDescending}
              onHeadingClick={handleTableHeadingClick}
              skeletonLoadingRows={pageSize}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>
          <PageSelector
            currentPage={currentPage}
            maxPages={totalPages}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};


export default ClientsPage;
