import React from 'react';
import PropTypes from 'prop-types';

import CountryFlag from 'Common/components/CountryFlag';
import classnames from 'classnames';

import styles from './styles.module.css';

const CountriesSelector = ({
  countries,
  selectedCountry,
  onClick,
}) => (
  <div>
    {countries.map((country) => (
      <button
        type="button"
        className={classnames(
          styles.option,
          (country === selectedCountry) && styles.selected,
        )}
        onClick={() => onClick(country)}
      >
        <CountryFlag country={country} />
      </button>
    ))}
  </div>
);

CountriesSelector.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
  selectedCountry: PropTypes.string,
  onClick: PropTypes.func,
};

CountriesSelector.defaultProps = {
  countries: [],
  selectedCountry: null,
  onClick: () => {},
};

export default CountriesSelector;
