import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import Skeleton from 'react-loading-skeleton';

import classnames from 'classnames';
import TableHeading from './TableHeading';

import styles from './styles.module.css';

const Table = ({
  columns,
  rows,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  skeletonLoadingRows,
  className,
  theme,
}) => (
  <table
    className={classnames(styles.table, styles[theme], className)}
  >
    <thead>
      <tr>
        {columns.map((column) => (
          <TableHeading
            key={uuidv4()}
            heading={column.heading}
            sortable={column.sortable}
            onClick={(column.sortable) ? () => onHeadingClick(column.identifier) : undefined}
            sorted={sortBy === column.identifier}
            sortDescending={sortDescending}
            width={column.width}
          />
        ))}
      </tr>
    </thead>
    {loading
      ? (
        <tbody>
          {[...Array(skeletonLoadingRows)].map(() => (
            <tr>
              <td colSpan={columns.length}>
                <Skeleton count={1} />
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          {rows.map((row) => (
            <tr
              onClick={() => onRowClick(row.identifier)}
              className={classnames(
                row.disabled && styles.disabledRow,
                row.error && styles.errorRow,
              )}
            >
              {row.data.map((field) => (
                <td>
                  <>{field}</>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      )}
  </table>
);

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    identifier: PropTypes.string,
    width: PropTypes.string,
    sortable: PropTypes.bool,
  })),
  rows: PropTypes.arrayOf(PropTypes.shape({
    identifier: PropTypes.any,
    data: PropTypes.arrayOf(PropTypes.any),
  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
  skeletonLoadingRows: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.oneOf('default', 'light'),
};

Table.defaultProps = {
  columns: [],
  rows: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
  skeletonLoadingRows: 25,
  className: '',
  theme: 'default',
};

export default Table;
