import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// import { NavLink } from 'react-router-dom';

import Table from 'Common/components/Table';
import CountryFlag from 'Common/components/CountryFlag';

import styles from './styles.module.css';

const ClientsTable = ({
  headingStrings,
  actionStrings,
  clients,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.accountNumber,
      identifier: 'accountNumber',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.name,
      identifier: 'name',
      width: undefined,
      sortable: true,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.orders,
      identifier: 'totalorders',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.parked,
      identifier: 'parkedorders',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.approval,
      identifier: 'ordersawaitingapproval',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.processing,
      identifier: 'ordersawaitingprocessing',
      width: undefined,
      sortable: true,
    },
    {
      heading: headingStrings.dateCreated,
      identifier: 'datecreated',
      width: undefined,
      sortable: true,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={clients.map((client) => ({
        identifier: client.id,
        data: [
          client.accountNumber,
          <h5>{client.name}</h5>,
          <CountryFlag className={styles.flag} country={client.countryCode} />,
          client.orders.total.count,
          client.orders.parked.count,
          client.orders.awaitingApproval.count,
          client.orders.awaitingProcessing.count,
          new Date(client.dateCreated).toLocaleString(),
          <h5>{actionStrings.branches}</h5>,
          <h5>{actionStrings.users}</h5>,
          <h5>{actionStrings.products}</h5>,
          <h5>{actionStrings.worksheets}</h5>,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
    />
  );
};

ClientsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    accountNumber: PropTypes.string,
    name: PropTypes.string,
    orders: PropTypes.string,
    parked: PropTypes.string,
    approval: PropTypes.string,
    processing: PropTypes.string,
    dateCreated: PropTypes.string,
  }),
  actionStrings: PropTypes.objectOf({
    branches: PropTypes.string,
    users: PropTypes.string,
    products: PropTypes.string,
    worksheets: PropTypes.string,
  }),
  clients: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ClientsTable.defaultProps = {
  headingStrings: undefined,
  actionStrings: undefined,
  clients: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ClientsTable;
