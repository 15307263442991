import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CountryFlag from 'Common/components/CountryFlag';
import Text from 'Common/components/Text';
import Input from 'Common/components/Input';

import styles from './styles.module.css';

const TranslationInput = ({
  title,
  value,
  countryCode,
  onChange,
  className,
}) => (
  <div
    className={classnames(
      styles.container,
      className,
    )}
  >
    <div
      className={styles.infoContainer}
    >
      <CountryFlag
        className={styles.flag}
        country={countryCode}
      />
      <Text>{title}</Text>
    </div>
    <div
      className={styles.inputContainer}
    >
      <Input
        value={value}
        width="100%"
        onChange={onChange}
      />
    </div>
  </div>
);

TranslationInput.propTypes = {
};

export default TranslationInput;
