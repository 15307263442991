import React from 'react';
import PropTypes from 'prop-types';

import icon from 'Common/assets/images/loading.gif';

import styles from './styles.module.css';

const LoadingIcon = ({
  visible,
}) => (
  <>
    {visible && (
      <img
        className={styles.default}
        src={icon}
        alt="Loading Icon"
      />
    )}
  </>
);

LoadingIcon.propTypes = {
  visible: PropTypes.bool,
};

LoadingIcon.defaultProps = {
  visible: false,
};

export default LoadingIcon;
