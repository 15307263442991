import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';
import Checkbox from 'Common/components/Checkbox';
import Input from 'Common/components/Input';

import { isNull } from 'Common/utils/utils';
import getSymbolFromCurrency from 'currency-symbol-map';

import styles from './styles.module.css';

const ItemAdjustmentsSummaryTable = ({
  headingStrings,
  itemAdjustments,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  skeletonLoadingRows
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.productCode,
      identifier: 'productCode',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.listPrice,
      identifier: 'listPrice',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.adjustBasePrice,
      identifier: 'adjustBasePrice',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.adjustmentPercentage,
      identifier: 'adjustmentPercentage',
      width: undefined,
      sortable: false,
    },
    {
      heading: headingStrings.completed,
      identifier: 'completed',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={itemAdjustments.map((itemAdjustment) => ({
        identifier: itemAdjustment.item.d,
        data: [
          itemAdjustment.item.productCode,
          itemAdjustment.item.title,
          `${getSymbolFromCurrency(itemAdjustment.item.listPrice.currencyCode)}${itemAdjustment.item.listPrice.value}`,
          <Checkbox
            checked={itemAdjustment.adjustBasePrice}
          />,
          `${itemAdjustment.adjustmentPercentage}%`,
          <Checkbox
            checked={itemAdjustment.completed}
          />,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
    />
  );
};

ItemAdjustmentsSummaryTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    productCode: PropTypes.string,
    title: PropTypes.string,
    listPrice: PropTypes.string,
  }),
  itemAdjustments: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ItemAdjustmentsSummaryTable.defaultProps = {
  headingStrings: undefined,
  itemAdjustments: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default ItemAdjustmentsSummaryTable;
