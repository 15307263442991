import { useContext, useCallback } from 'react';
import axios from 'axios';

import { AuthContext } from 'Common/contexts/AuthContext';

const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const login = async (username, password, accountNumber = null) => {
    dispatch({ type: 'login_requested' });

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/auth/login`, {
        username,
        accountNumber,
        password,
      });

      dispatch({
        type: 'login_successful',
        payload: {
          tokens: data,
        },
      });
      return data;
    } catch (e) {
      dispatch({ type: 'login_failed' });
      throw e;
    }
  };

  const logout = () => {
    dispatch({ type: 'logout' });
  };

  const refreshTokens = async () => {
    dispatch({ type: 'refreshing_tokens_requested' });

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tokens/refresh`, {
        accessToken: state.tokens.accessToken,
        refreshToken: state.tokens.refreshToken,
      });

      dispatch({
        type: 'refreshing_tokens_successful',
        payload: {
          tokens: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'refreshing_tokens_failed' });
      throw e;
    }
  };

  return {
    ...state,
    login: useCallback(login, [dispatch]),
    logout: useCallback(logout, [dispatch]),
    refreshTokens: useCallback(refreshTokens, [dispatch, state.tokens]),
  };
};


export default useAuth;
