import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import FileDownload from 'js-file-download';
// import { MenuItem } from '@trendmicro/react-dropdown';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Row, Col } from 'react-bootstrap';

import { useToasts } from 'react-toast-notifications';
import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAuth from 'Common/hooks/useAuth';
import usePageSizeSelector from 'Common/hooks/usePageSizeSelector';

import PageTitle from 'Common/components/PageTitle';
import Paragraph from 'Common/components/Paragraph';
import FiltersBox from 'Common/components/FiltersBox';
import Button from 'Common/components/Button';
import Dropdown from 'Common/components/Dropdown';
import Input from 'Common/components/Input';
import Space from 'Common/components/Space';
import OrdersTable from 'Admin/components/OrdersTable';
import DateRangeFilter from 'Common/components/DateRangeFilter';
// import ButtonDropdown from 'Common/components/ButtonDropdown';
import PageSelector from 'Common/components/PageSelector';
import PageSizeSelector from 'Common/components/PageSizeSelector';
import SearchableDropdown from 'Common/components/SearchableDropdown';
import LoadingIcon from 'Common/components/LoadingIcon';

import { isUndefined, isNull } from 'Common/utils/utils';
import { camelize } from 'Common/utils/stringUtils';

import timeframeType from 'Common/constants/timeframeType';
import timeRangeFiltersType from 'Common/constants/timeRangeFiltersType';

import calendarIcon from 'Common/assets/icons/calendar_icon.png';

const defaultOrderFilters = {
  page: 1,
  status: undefined,
  client: undefined,
  search: '',
  sortBy: 'orderdate',
  sortDescending: true,
  dateOrderedDateRange: {
    startDate: undefined,
    endDate: undefined,
  },
};

const OrdersPage = () => {
  const { addToast } = useToasts();

  const { orders: { strings, loading: loadingStrings }, fetchOrdersStrings } = useStrings();
  const { user } = useUser();
  const { tokens: { accessToken } } = useAuth();

  const [orderFilters, setOrderFilters] = useState(defaultOrderFilters);
  const [ordersLink, setOrdersLink] = useState(null);
  const { results: orders, loading: loadingOrders, currentPage, totalPages, totalResults } = usePagedFetch(ordersLink);

  const [clientSearch, setClientSearch] = useState('');
  const [selectedValues, setSelectedValues] = useState(defaultOrderFilters);
  const { pageSizes, pageSize, setPageSize } = usePageSizeSelector([10, 25, 50, 75, 100], 10);

  const [clientSearchLink, setClientSearchLink] = useState(null);
  const { results: clients, loading: loadingClients } = usePagedFetch(clientSearchLink);

  const [loadingReport, setLoadingReport] = useState(false);

  const [timeframe, setTimeframe] = useState(timeframeType.allTime);
  const [rangeFilter, setRangeFilter] = useState(timeRangeFiltersType.before);

  const [dateRangeFilterVisible, setDateRangeFilterVisible] = useState(false);

  const [clientSearchElapseTimeout, setClientSearchElapseTimeout] = useState(0);

  // Functions
  const getQueryParameters = useCallback(() => {
    const params = {
      page: orderFilters.page,
      pageSize,
      search: orderFilters.search,
    };

    if (!isUndefined(orderFilters.sortBy)) {
      params.sortBy = orderFilters.sortBy;
      params.sortDesc = orderFilters.sortDescending;
    }

    if (!isUndefined(orderFilters.status)) params.status = orderFilters.status;
    if (!isUndefined(orderFilters.client)) params.client = orderFilters.client;

    const { dateOrderedDateRange } = orderFilters;
    if (!isUndefined(dateOrderedDateRange.startDate)) params.orderDate1 = dateOrderedDateRange.startDate;
    if (!isUndefined(dateOrderedDateRange.endDate)) params.orderDate2 = dateOrderedDateRange.endDate;

    return params;
  }, [orderFilters, pageSize]);

  const updateOrdersLink = useCallback(() => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/orders`);
    const params = getQueryParameters();
    url.search = new URLSearchParams(params);
    setOrdersLink(url.href);
  }, [getQueryParameters]);

  // const getReportFilename = (fileType) => {
  //   const baseFilename = `Orders_${new Date().toISOString()}`;

  //   if (fileType === 'csv') {
  //     return `${baseFilename}.csv`;
  //   }

  //   if (fileType === 'xlsx') {
  //     return `${baseFilename}.xlsx`;
  //   }

  //   return baseFilename;
  // };

  // const fetchReport = async (fileType) => {
  //   const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/orders`);
  //   const params = getQueryParameters();
  //   url.search = new URLSearchParams(params);

  //   try {
  //     setLoadingReport(true);

  //     const response = await axios.get(url.href, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     const filename = getReportFilename(fileType);
  //     FileDownload(response, filename);
  //   } catch (e) {
  //     addToast('Something went wrong while trying to generate report', {
  //       appearance: 'error',
  //       autoDismiss: true,
  //       pauseOnHover: false,
  //     });
  //   }

  //   setLoadingReport(false);
  // };

  // Handlers
  const handleSearchChange = (search) => {
    setSelectedValues({ ...selectedValues, search });
  };

  const handleClientSearchChange = (e) => {
    const { value } = e.target;
    setClientSearch(value);

    if (value.length > 2) {
      clearTimeout(clientSearchElapseTimeout);

      setClientSearchElapseTimeout(setTimeout(
        () => {
          const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/clients`);
          const params = {
            search: value,
            sortBy: 'name',
            sortDescending: true,
          };
          url.search = new URLSearchParams(params);
          setClientSearchLink(url.href);
        },
        500,
      ));
    }
  };

  const handleClientSearchSelected = (event, data) => {
    const { value } = data;
    setClientSearch(value);
    setSelectedValues({ ...selectedValues, client: value });
    setOrderFilters({ ...orderFilters, client: value });
  };

  const handleClientSearchClick = () => {
    setClientSearchLink(null);
    setClientSearch('');
  };

  const handleStatusChange = (status) => {
    setSelectedValues({ ...selectedValues, status });
    setOrderFilters({ ...orderFilters, status });
  };

  const handleApplySearchClick = () => {
    setOrderFilters({ ...orderFilters, search: selectedValues.search });
  };

  const handleDateRangeChange = (dateRange) => {
    setSelectedValues({ ...selectedValues, dateOrderedDateRange: dateRange });
  };

  const handleApplyDateRangeClick = () => {
    setOrderFilters({ ...orderFilters, dateOrderedDateRange: selectedValues.dateOrderedDateRange });
  };

  const handlePageChange = (pageNumber) => {
    setSelectedValues({ ...selectedValues, page: pageNumber });
    setOrderFilters({ ...orderFilters, page: pageNumber });
  };

  const handleResetFiltersClick = () => {
    setOrderFilters(defaultOrderFilters);
  };

  const handleTableHeadingClick = (column) => {
    // TODO: Check this is correct. If new column does it reset?
    const sortDescending = (selectedValues.sortBy === column) ? !selectedValues.sortDescending : selectedValues.sortDescending;

    setSelectedValues({
      ...selectedValues,
      sortBy: column,
      sortDescending,
    });
    setOrderFilters({
      ...orderFilters,
      sortBy: column,
      sortDescending,
    });
  };

  const toggleDateFilteVisibilty = () => {
    setDateRangeFilterVisible(!dateRangeFilterVisible);
  };

  const handleTimeframeChange = (value) => {
    setTimeframe(value);
  };

  const handleRangeFilterChange = (value) => {
    setRangeFilter(value);
  };

  // const handleExportReportClick = (fileType) => {
  //   if (totalResults > 50) {
  //     confirmAlert({
  //       title: 'Confirm Operation',
  //       message: 'This operation may take a long time with the number of orders. Are you sure you want to generate the report?',
  //       confirmLabel: 'Confirm',
  //       cancelLabel: 'Cancel',
  //       onConfirm: fetchReport(fileType),
  //     });
  //   } else {
  //     fetchReport();
  //   }
  // };

  // Effects
  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchOrdersStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchOrdersStrings]);

  useEffect(() => {
    updateOrdersLink();
  }, [orderFilters, updateOrdersLink, pageSize]);

  // Rendering
  if (!strings) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <PageTitle>{strings.title}</PageTitle>
        </Col>
      </Row>

      <Row>
        <Col md={10} xs={12}>
          <Space height="20px" />
          <Paragraph>
            {strings.description}
          </Paragraph>
          <Space height="30px" />
        </Col>
      </Row>

      <Row>
        <FiltersBox>
          <Col xs={12}>
            <Row>
              <Col>
                <Row>
                  <Input
                    width={300}
                    className="mr-5 mb-2"
                    onChange={handleSearchChange}
                    name="search"
                    value={selectedValues.search}
                    placeholder={strings.filters.searchPlaceholder}
                  />
                  <Button
                    className="mr-5 mb-2"
                    theme="blue"
                    onClick={handleApplySearchClick}
                  >
                    {strings.searchButton}
                  </Button>

                  <SearchableDropdown
                    className="mr-5 mb-2"
                    width={300}
                    onSearchChange={handleClientSearchChange}
                    onChange={handleClientSearchSelected}
                    onClick={handleClientSearchClick}
                    placeholder="Search for a customer..."
                    options={clients.map((client) => ({
                      key: client.id,
                      text: `${client.name} (${client.accountNumber})`,
                      value: client.id,
                      flag: client.countryCode.toLowerCase(),
                    }))}
                  />

                  <Dropdown
                    className="mr-5 mb-2"
                    options={
                      Object.entries(strings.orderStatuses).map(([key, value]) => ({
                        value: camelize(key.toString()),
                        label: value.toString(),
                      }))
                    }
                    value={selectedValues.status}
                    onChange={handleStatusChange}
                    placeholder="Status"
                  />

                  <Button
                    className="mr-5 mb-2"
                    theme="filter"
                    onClick={toggleDateFilteVisibilty}
                  >
                    <img
                      style={{
                        width: '30px',
                        height: '28px',
                        margin: '-4px',
                      }}
                      src={calendarIcon}
                      alt="profile"
                    />
                  </Button>
                </Row>
              </Col>

              <Col md={{ span: 1, offset: 2 }}>
                <Row>
                  <Button
                    className="mr-4 mb-2"
                    onClick={handleResetFiltersClick}
                  >
                    {strings.resetButton}
                  </Button>
                </Row>
              </Col>
            </Row>

            {dateRangeFilterVisible && (
              <Row
                className="mt-4"
              >
                <DateRangeFilter
                  onChange={handleDateRangeChange}
                  value={selectedValues.createdDateRange}
                  timeframe={timeframe}
                  rangeFilter={rangeFilter}
                  onTimeframeChange={handleTimeframeChange}
                  onRangeFilterChange={handleRangeFilterChange}
                  onApplyClick={handleApplyDateRangeClick}
                />
              </Row>
            )}
          </Col>
        </FiltersBox>
      </Row>

      <Row
        className="mt-4 mx-4"
      >
        <Col md={{ span: 1 }}>
          {/* <ButtonDropdown
            btnSize="xs"
            title="Export"
            disable={loadingReport || orders.length === 0}
            onSelect={handleExportReportClick}
          >
            <MenuItem eventKey="xlsx">Excel</MenuItem>
            <MenuItem eventKey="csv">CSV</MenuItem>
          </ButtonDropdown> */}
        </Col>
        <Col md={{ span: 1, offset: 11 }}>
          <PageSizeSelector
            sizes={pageSizes}
            selectedValue={pageSize}
            onChange={setPageSize}
          />
        </Col>
      </Row>

      <Row
        className="justify-content-center py-3"
        style={{ textAlign: 'center' }}
      >

        <LoadingIcon
          visible={loadingOrders}
        />
      </Row>


      {(orders.length === 0 && !loadingOrders) && (
        <Row>
          <Col xs={12}>
            <Paragraph>{strings.noOrdersText}</Paragraph>
          </Col>
        </Row>
      )}

      {(orders.length > 0 && !loadingOrders) && (
        <Row>
          <Col xs="12">
            <Paragraph>
              {strings.resultsText
                .replace('{0}', `${(currentPage - 1) * pageSize} - ${currentPage * pageSize > totalResults ? totalResults : currentPage * pageSize}`)
                .replace('{1}', totalResults)}
            </Paragraph>

            <OrdersTable
              headingStrings={strings.tableHeadings}
              statusStrings={strings.orderStatuses}
              actionLabelText={strings.actionsLabel}
              orders={orders}
              loading={loadingOrders}
              sortBy={selectedValues.sortBy}
              sortDescending={selectedValues.sortDescending}
              onHeadingClick={handleTableHeadingClick}
              skeletonLoadingRows={pageSize}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>
          <PageSelector
            currentPage={currentPage}
            maxPages={totalPages}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OrdersPage;
