import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Button from 'Common/components/Button';
import Link from 'Common/components/Link';

import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { toLocalDateTime } from 'Common/utils/dateUtils';

import styles from './styles.module.css';

const ScheduledPriceAdjustmentsTable = ({
  headingStrings,
  priceAdjustments,
  skeletonLoadingRows,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  editButtonText = "",
  deleteButtonText = "",
  onEditClick,
  onDeleteClick,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.id,
      identifier: 'id',
      width: '24%',
      sortable: false,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: '24%',
      sortable: false,
    },
    {
      heading: headingStrings.type,
      identifier: 'type',
      width: '24%',
      sortable: false,
    },
    {
      heading: headingStrings.scheduledExecution,
      identifier: 'scheduledExecution',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={priceAdjustments.map((priceAdjustment) => ({
        identifier: priceAdjustment.id,
        data: [
          <Link
            to={`/admin/price-adjustment/${priceAdjustment.id}/info`}
            className={styles.link}
          >
            {priceAdjustment.id}
          </Link>,
          priceAdjustment.title,
          priceAdjustment.type,
          priceAdjustment.scheduledExecution,
          <div>
            <Button
              size="small"
              theme="action"
              className="mr-2"
              onClick={() => onEditClick(priceAdjustment.id)}
            >
              {editButtonText}
            </Button>
            <Button
              onClick={() => onDeleteClick(priceAdjustment.id)}
            >
              {deleteButtonText}
            </Button>
          </div>,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
    />
  );
};

ScheduledPriceAdjustmentsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    scheduledExecution: PropTypes.string,
  }),
  priceAdjustments: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

ScheduledPriceAdjustmentsTable.defaultProps = {
  headingStrings: undefined,
  priceAdjustments: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
  onEditClick: () => {},
  onDeleteClick: () => {},
};

export default ScheduledPriceAdjustmentsTable;
