import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Image from 'Common/components/Image';

import icon from 'Admin/assets/icons/price-adjustment-exclude-icon.svg';

import styles from './styles.module.css';

const ExcludeButton = ({
  className,
  disabled,
  onClick = () => {},
  width="1.4em",
}) => (
  <Button
    className={classnames(styles.button, className)}
    disabled={disabled}
    onClick={onClick}
  >
    <Image
      className={styles.image}
      source={icon}
      width={width}
    />
  </Button>
);

ExcludeButton.propTypes = {
};

ExcludeButton.defaultProps = {
};


export default ExcludeButton;
