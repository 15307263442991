export const isUndefined = (arg) => arg === undefined;

export const isEmpty = (arg) => arg === '';

export const isNull = (arg) => arg === null;

export const isNullOrEmpty = (arg) => isNull(arg) || isEmpty(arg);

export const isNullOrUndefined = (arg) => isUndefined(arg) || isNull(arg);

export const convertToInt = (arg) => parseInt(arg, 10);

export const nestedCopy = (array) => JSON.parse(JSON.stringify(array));

export const delay = (ms) => new Promise((response) => setTimeout(response, ms));
