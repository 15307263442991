import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { camelize } from 'Common/utils/stringUtils';

import styles from './styles.module.css';

const PriceAdjustmentStatusTag = ({
  status,
  statusText,
}) => {
  return (
    <div
      className={classnames(styles.default, styles[camelize(status)])}
    >
      {statusText}
    </div>
  );
};

PriceAdjustmentStatusTag.propTypes = {

};

export default PriceAdjustmentStatusTag;
