import React, {
  createElement, createContext, useReducer,
} from 'react';
import PropTypes from 'prop-types';

import branchReducer from 'Common/reducers/branchReducer';

export const BranchContext = createContext();

const initialState = {
  loading: false,
  branch: undefined,
};

export const BranchProvider = ({ children }) => {
  const [state, dispatch] = useReducer(branchReducer, initialState);

  return (
    <BranchContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

BranchProvider.propTypes = {
  children: PropTypes.node,
};

BranchProvider.defaultProps = {
  children: createElement('div'),
};

export const BranchConsumer = BranchContext.Consumer;
