import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Dropdown from 'Common/components/Dropdown';
import styles from './styles.module.css';

const PageSizeSelector = ({
  className,
  sizes,
  selectedValue,
  onChange,
}) => (
  <Dropdown
    className={classnames(
      className,
    )}
    options={sizes.map((size) => ({
      value: size,
      label: size,
    }))}
    value={selectedValue}
    onChange={onChange}
  />
);

PageSizeSelector.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.number),
  selectedValue: PropTypes.number,
  onChange: PropTypes.func,
};

PageSizeSelector.defaultProps = {
  className: '',
  sizes: [],
  selectedValue: undefined,
  onChange: () => {},
};

export default PageSizeSelector;
