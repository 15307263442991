import { useState, useEffect } from 'react';

import useFetch from './useFetch';
import { isNull, isUndefined, nestedCopy } from '../utils/utils';

const usePagedFetch = (url) => {
  const [results, setResults] = useState([]);
  const [currentPageLink, setCurrentPageLink] = useState(url);
  const [previousPageLink, setPreviousPageLink] = useState(null);
  const [nextPageLink, setNextPageLink] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(undefined);
  const [totalRecords, setTotalRecords] = useState(0);

  const {
    data, error, loading, refetch,
  } = useFetch(currentPageLink);

  const resetState = () => {
    setResults([]);
    setPreviousPageLink(null);
    setNextPageLink(null);

    setCurrentPage(1);
    setTotalPages(1);
    setPageSize(undefined);
    setTotalRecords(0);
  };

  const getPreviousPage = () => {
    setCurrentPageLink(previousPageLink);
  };

  const getNextPage = () => {
    setCurrentPageLink(nextPageLink);
  };

  const getFirstPage = () => {
    const link = url.replace(`page=${currentPage}`, 'page=1');
    setCurrentPageLink(link);
  };

  const getLastPage = () => {
    const link = url.replace(`page=${currentPage}`, `page=${totalPages}`);
    setCurrentPageLink(link);
  };

  const getPage = (page) => {
    const link = url.replace(`page=${currentPage}`, `page=${page}`);
    setCurrentPageLink(link);
  };

  useEffect(() => {
    resetState();
    setCurrentPageLink(url);
  }, [url]);

  useEffect(() => {
    if (!isUndefined(data)) {
      setCurrentPage(data.pageNumber);
      setTotalPages(data.totalPages);
      setPageSize(data.pageSize);
      setTotalRecords(data.totalRecords);

      const hasLinks = !isUndefined(data.links);
      const hasPreviousLink = hasLinks && data.links.some((x) => x.rel === 'prev');
      const hasNextLink = hasLinks && data.links.some((x) => x.rel === 'next');

      setNextPageLink(hasNextLink ? data.links.find((x) => x.rel === 'next').href : null);

      setPreviousPageLink((hasPreviousLink) ? data.links.find((x) => x.rel === 'prev').href : null);
      setResults(nestedCopy(data.results));
    }
  }, [data]);

  return {
    results,
    error,
    loading,
    getPreviousPage,
    getNextPage,
    getFirstPage,
    getLastPage,
    getPage,
    hasPreviousLink: !isNull(previousPageLink),
    hasNextLink: !isNull(nextPageLink),
    currentPage,
    totalPages,
    pageSize,
    totalRecords,
    refetch,
    data,
  };
};

export default usePagedFetch;
