import { useContext, useCallback } from 'react';
import axios from 'axios';

import { ClientContext } from 'Common/contexts/ClientContext';

import useAuth from 'Common/hooks/useAuth';

const useClient = () => {
  const { state, dispatch } = useContext(ClientContext);
  const { tokens } = useAuth();

  const fetch = async (href) => {
    dispatch({ type: 'fetch_client_requested' });

    try {
      const { data } = await axios.get(href, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_client_successful',
        payload: {
          client: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_client_failed' });
      throw e;
    }
  };

  return {
    ...state,
    fetch: useCallback(fetch, [dispatch, tokens.accessToken]),
  };
};


export default useClient;
