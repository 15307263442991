import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './styles.module.css';

// TODO: Split out to CategoryTreeNode?

const TreeNode = ({
  data,
  onClick,
  onAddClick,
  onDeleteClick,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [hoveredOver, setHoveredOver] = useState(false);

  const handleCaretClick = () => {
    setCollapsed(!collapsed);
  };

  const handleNodeMouseEnter = () => {
    setHoveredOver(true);
  };

  const handleNodeMouseLeave = () => {
    setHoveredOver(false);
  };

  const handleNodeClick = () => {
    onClick(data.id);
  };

  const handleAddClick = () => {
    onAddClick(data.id);
  };

  const handleDeleteClick = () => {
    onDeleteClick(data.id);
  };

  return (
    <div
      className={styles.container}
    >
      <div
        className={classnames(
          styles.node,
          data.selected && styles.nodeSelected,
        )}
        onClick={handleNodeClick}
        onMouseEnter={handleNodeMouseEnter}
        onMouseLeave={handleNodeMouseLeave}
      >
        <div
          className={styles.caretContainer}
          onClick={handleCaretClick}
        >
          {data.children.length > 0 && (
            <span
              className={classnames(
                styles.caret,
                collapsed ? 'glyphicon glyphicon-triangle-right' : 'glyphicon glyphicon-triangle-bottom'
              )}
            />
          )}
        </div>
        <span
          className={styles.title}
        >
          {data.title}
        </span>
        <div
          className={styles.removeIconContainer}
        >
          {hoveredOver && (
            <span
              className={classnames(
                'glyphicon glyphicon-remove-circle',
                styles.removeIcon,
              )}
              onClick={handleDeleteClick}
            />
          )}
        </div>
      </div>

      <div
        className={styles.nestedList}
      >
        {!collapsed && data.children.map((child) => (
          <TreeNode
            data={child}
            onClick={onClick}
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
        {(data.selected && (!collapsed || data.children.length === 0)) && (
          <div
            className={styles.addNode}
          >
            <div
              className={styles.addNodeTextContainer}
              onClick={handleAddClick}
            >
              +
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

TreeNode.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    children: PropTypes.array,
  }),
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onAddClick: PropTypes.func,
};

TreeNode.defaultProps = {
  onClick: () => {},
  onDeleteClick: () => {},
  onAddClick: () => {},
};

export default TreeNode;
