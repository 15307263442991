import React, { createElement, createContext, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';

import appReducer from 'Common/reducers/appReducer';

export const AppContext = createContext();

const initialState = {
  sidebarCollapsed: false,
};

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch: useCallback((...args) => {
          dispatch(...args);
        }, [dispatch]),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

AppProvider.defaultProps = {
  children: createElement('div'),
};

export const AppConsumer = AppContext.Consumer;
