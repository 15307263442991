import timeframeType from '../constants/timeframeType';

export const formatDateForInput = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const formatLocalDateForInput = (datetime) => {
  const d = new Date(datetime);

  const minutes = `${d.getMinutes()}`;
  const hours = `${d.getHours()}`;
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[year, month, day].join('-')}T${[hours, minutes].join(':')}`;
};

export const formatDateTimeForInput = (datetime) => {
  const d = new Date(datetime);

  const seconds = `${d.getSeconds()}`;
  const minutes = `${d.getMinutes()}`;
  const hours = `${d.getHours()}`;
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[year, month, day].join('-')}T${[hours, minutes, seconds].join(':')}`;
};


export const getTodayDateRange = () => {
  const dateToday = new Date();

  dateToday.setHours(0, 0, 0, 0);
  const startDate = dateToday.toISOString();

  dateToday.setHours(23, 59, 59, 999);
  const endDate = dateToday.toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getYesterdayDateRange = () => {
  const dateToday = new Date();
  const dateYesterday = new Date();

  dateYesterday.setDate(dateToday.getDate() - 1);
  dateYesterday.setHours(0, 0, 0, 0);
  const startDate = dateYesterday.toISOString();

  dateYesterday.setHours(23, 59, 59, 999);
  const endDate = dateYesterday.toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getThisWeekDateRange = () => {
  const dateToday = new Date();
  const day = dateToday.getDay();
  const diff = dateToday.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  const startOfWeekDate = new Date(dateToday.setDate(diff));
  startOfWeekDate.setHours(0, 0, 0, 0);

  const endOfWeekDate = new Date(startOfWeekDate);
  endOfWeekDate.setDate(startOfWeekDate.getDate() + 6);
  endOfWeekDate.setHours(23, 59, 59, 999);

  const startDate = (startOfWeekDate).toISOString();
  const endDate = (endOfWeekDate).toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getThisMonthDateRange = () => {
  const dateToday = new Date();
  const beginningOfMonthDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
  beginningOfMonthDate.setHours(0, 0, 0, 0);

  const endOfMonthDate = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0 - 1);
  endOfMonthDate.setHours(23, 59, 59, 999);

  const startDate = beginningOfMonthDate.toISOString();
  const endDate = endOfMonthDate.toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getLast3MonthsDateRange = () => {
  const dateToday = new Date();
  const dateRangeBeginning = new Date(dateToday.getFullYear(), dateToday.getMonth() - 3, dateToday.getDay());
  dateRangeBeginning.setHours(0, 0, 0, 0);

  const dateRangeEnd = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0 - 1);
  dateRangeEnd.setHours(23, 59, 59, 999);

  const startDate = dateRangeBeginning.toISOString();
  const endDate = dateRangeEnd.toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getLast6MonthsDateRange = () => {
  const dateToday = new Date();

  const dateRangeBeginning = new Date(dateToday.getFullYear(), dateToday.getMonth() - 6, dateToday.getDay());
  dateRangeBeginning.setHours(0, 0, 0, 0);

  const dateRangeEnd = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0 - 1);
  dateRangeEnd.setHours(23, 59, 59, 999);

  const startDate = dateRangeBeginning.toISOString();
  const endDate = dateRangeEnd.toISOString();
  return {
    startDate,
    endDate,
  };
};

export const getPastYearDateRange = () => {
  const dateToday = new Date();
  const dateRangeBeginning = new Date(dateToday.getFullYear() - 1, dateToday.getMonth(), dateToday.getDay());
  dateRangeBeginning.setHours(0, 0, 0, 0);

  const dateRangeEnd = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0 - 1);
  dateRangeEnd.setHours(23, 59, 59, 999);

  const startDate = dateRangeBeginning.toISOString();
  const endDate = dateRangeEnd.toISOString();

  return {
    startDate,
    endDate,
  };
};

export const getDateRange = (timeframe) => {
  if (timeframe === timeframeType.today) {
    return getTodayDateRange();
  }

  if (timeframe === timeframeType.yesterday) {
    return getYesterdayDateRange();
  }

  if (timeframe === timeframeType.thisWeek) {
    return getThisWeekDateRange();
  }

  if (timeframe === timeframeType.thisMonth) {
    return getThisMonthDateRange();
  }

  if (timeframe === timeframeType.past3Months) {
    return getLast3MonthsDateRange();
  }

  if (timeframe === timeframeType.past6Months) {
    return getLast6MonthsDateRange();
  }

  if (timeframe === timeframeType.pastYear) {
    return getPastYearDateRange();
  }

  throw new Error('Timeframe type is not handled.');
};

export const getPrettyDate = (date) => {
  return new Date(date).toString();
};

export const getDateTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
};

export const getDateNextYear = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  return date;
};

export const getDate15MinsFromNow = () => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + 15);
  return date;
};

export const toLocalDateTime = (date) => {
  return new Date(date).toLocaleTimeString() + " " + new Date(date).toLocaleDateString();
}

const padify = (seconds, minutes, hours, days) => {
  const s = seconds < 10 ? `0${seconds}s` : `${seconds}s`;
  const m = (minutes > 0) ? (minutes < 10 ? `0${minutes}m` : `${minutes}m`) : '';
  const h = (hours > 0) ? (`${hours}h`) : '';
  const d = (days > 0) ? (`${days}d`) : '';

  return [d, h, m, s].filter(Boolean).join(' ');
}

export const formatElapseTime = (time) => {
  if (time === undefined || time === 0 || time === null) {
    return '';
  };

  const seconds = Math.floor(time / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return padify(seconds % 60, minutes % 60, hours % 60, days);
};
