import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useToasts } from 'react-toast-notifications';

import CategoryCreationForm from 'Admin/components/CategoryCreationForm';

import useInput from 'Common/hooks/useInput';
import useCapitalizedInput from 'Common/hooks/useCapitalizedInput';
import useAuth from 'Common/hooks/useAuth';

import { isUndefined } from 'Common/utils/utils';
import { isAlphanumericalOnly, hasWhiteSpace } from 'Common/utils/stringUtils';

const CategoryCreationFormContainer = ({
  open,
  onRequestClose,
  parentCategory,
  onCreationSuccess,
}) => {
  const { addToast } = useToasts();

  const {
    tokens: { accessToken },
  } = useAuth();

  const { value: shortCode, onChange: onShortCodeChange } = useCapitalizedInput('');
  const { value: title, onChange: onTitleChange } = useInput('');

  const strings = {
    responseMessages: {
      failed: 'Unable to create category. Please check your inputs.',
    },
    validationMessages: {
      shortCodeTooLong: 'Short code is too long',
      shortCodeTooSmall: 'Short code must be at least 3 characters long',
      shortCodeContainsWhiteSpace: 'Short code cannot contain spaces',
      shortCodeInvalid: 'Invalid short code',
      titleTooLong: 'Title is too long',
      titleTooSmall: 'Title must be at least 3 characters long',
    },
  };

  const [inputErrors, setInputErrors] = useState({
    shortCode: undefined,
    title: undefined,
  });
  const [errorMessage, setErrorMessage] = useState(undefined);

  const resetForm = () => {
    onShortCodeChange('');
    onTitleChange('');
    setInputErrors({
      shortCode: undefined,
      title: undefined,
    });
    setErrorMessage(undefined);
  };

  const validateInputs = () => {
    const newInputErrors = {
      shortCode: (() => {
        if (shortCode.length > 50) {
          return strings.validationMessages.shortCodeTooLong;
        }
        if (shortCode.length < 3) {
          return strings.validationMessages.shortCodeTooSmall;
        }

        if (hasWhiteSpace(shortCode)) {
          return strings.validationMessages.shortCodeContainsWhiteSpace;
        }

        if (!isAlphanumericalOnly(shortCode)) {
          return strings.validationMessages.shortCodeInvalid;
        }

        return undefined;
      })(),

      title: (() => {
        if (title.length > 50) {
          return strings.validationMessages.titleTooLong;
        }
        if (title.length < 3) {
          return strings.validationMessages.titleTooSmall;
        }

        return undefined;
      })(),
    };

    setInputErrors(newInputErrors);

    return (
      isUndefined(newInputErrors.shortCode)
      && isUndefined(newInputErrors.title)
    );
  };

  const handleRequestClose = () => {
    resetForm();
    onRequestClose();
  };

  const handleCreateClick = async () => {
    try {
      if (!validateInputs()) {
        return;
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tags/`, {
        title,
        level: !isUndefined(parentCategory) ? parentCategory.level + 1 : 1,
        parentTagId: !isUndefined(parentCategory) ? parentCategory.id : null,
        shortCode,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      addToast('Category was successfully created', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      resetForm();
      onCreationSuccess();
    } catch (e) {
      if (!isUndefined(e.response) && e.response.status === 400) {
        setErrorMessage(strings.responseMessages.failed);
      } else {
        addToast('Something went wrong when trying to create category', {
          appearance: 'error',
          autoDismiss: true,
          pauseOnHover: false,
        });
      }
    }
  };

  // Effects
  useEffect(() => {
    setInputErrors({
      shortCode: undefined,
      title: undefined,
    });
    setErrorMessage(undefined);
  }, [shortCode, title]);

  return (
    <CategoryCreationForm
      open={open}
      parentCategory={parentCategory}
      onCreateClick={handleCreateClick}
      onRequestClose={handleRequestClose}
      inputErrors={inputErrors}
      errorMessage={errorMessage}
      shortCode={shortCode}
      onShortCodeChange={onShortCodeChange}
      title={title}
      onTitleChange={onTitleChange}
    />
  );
};

CategoryCreationFormContainer.propTypes = {

};

CategoryCreationFormContainer.defaultProps = {

};

export default CategoryCreationFormContainer;
