import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const FiltersBox = ({
  children,
}) => (
  <div
    className={classnames(
      styles.default,
      'p-4',
    )}
  >
    {children}
  </div>
);

FiltersBox.propTypes = {
  children: PropTypes.node,
};

export default FiltersBox;
