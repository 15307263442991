import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Text from 'Common/components/Text';

import styles from './styles.module.css';

const AdjustmentSaveChangesBox = ({
  title,
  message,
}) => (
  <div className={classnames(styles.container)}>
    <div>
      <Text
        bold
        size="small"
        theme="black"
      >
        {`${title}: `}
      </Text>
    </div>
    <Text
      size="small"
      theme="black"
    >
      {message}
    </Text>
  </div>
);

AdjustmentSaveChangesBox.propTypes = {
};

AdjustmentSaveChangesBox.defaultProps = {
};


export default AdjustmentSaveChangesBox;
