import { useContext, useCallback, useMemo } from 'react';
import axios from 'axios';

import { StringsContext } from 'Admin/contexts/StringsContext';

import useAuth from 'Common/hooks/useAuth';

const useStrings = () => {
  const { state, dispatch } = useContext(StringsContext);
  const { tokens } = useAuth();

  const fetchSidebarStrings = async (locale) => {
    dispatch({ type: 'fetch_sidebar_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/sidebar/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_sidebar_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_sidebar_strings_failed' });
      throw e;
    }
  };

  const fetchDashboardStrings = async (locale) => {
    dispatch({ type: 'fetch_dashboard_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/dashboard/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_dashboard_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_dashboard_strings_failed' });
      throw e;
    }
  };

  const fetchClientsStrings = async (locale) => {
    dispatch({ type: 'fetch_clients_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/clients/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_clients_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_clients_strings_failed' });
      throw e;
    }
  };

  const fetchOrdersStrings = async (locale) => {
    dispatch({ type: 'fetch_orders_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/orders/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_orders_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_orders_strings_failed' });
      throw e;
    }
  };

  const fetchRolesStrings = async (locale) => {
    dispatch({ type: 'fetch_roles_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/roles/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_roles_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_roles_strings_failed' });
      throw e;
    }
  };

  const fetchCategoriesStrings = async (locale) => {
    dispatch({ type: 'fetch_categories_strings_requested' });

    try {
      // const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/categories/${locale}`, {
      //   headers: {
      //     Authorization: `Bearer ${tokens.accessToken}`,
      //   },
      // });

      const data = {
        title: 'Categories',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
      };

      dispatch({
        type: 'fetch_categories_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_categories_strings_failed' });
      throw e;
    }
  };

  const fetchAccountManagersStrings = async (locale) => {
    dispatch({ type: 'fetch_account_managers_strings_requested' });

    try {
      // const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/categories/${locale}`, {
      //   headers: {
      //     Authorization: `Bearer ${tokens.accessToken}`,
      //   },
      // });

      const data = {
        title: 'Account Managers',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur',
        showingText: '{0} out of {1}',
        tableHeadings: {
          name: 'Name',
          title: 'Title',
          emailAddress: 'Email Address',
          telephone: 'Telephone',
          mobile: 'Mobile',
          clients: 'Clients',
          branches: 'Branches',
        },
        addAccountManagerButton: 'Add Account Manager',
        searchButton: 'Search',
        searchPlaceholder: 'Search',
        resetButton: 'Reset',
        noClientsText: 'There are no account manager matching the search criteria',
      };

      dispatch({
        type: 'fetch_account_managers_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_account_managers_strings_failed' });
      throw e;
    }
  };

  const fetchPriceAdjustmentsStrings = async (locale) => {
    dispatch({ type: 'fetch_priceadjustments_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/priceadjustments/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_priceadjustments_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_priceadjustments_strings_failed' });
      throw e;
    }
  };

  const fetchPriceAdjustmentStrings = async (locale) => {
    dispatch({ type: 'fetch_priceadjustment_strings_requested' });

    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/string-resources/admin/priceadjustment/${locale}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_priceadjustment_strings_successful',
        payload: {
          strings: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_priceadjustment_strings_failed' });
      throw e;
    }
  };

  return {
    languageCode: useMemo(() => state.languageCode, [state.languageCode]),
    sidebar: useMemo(() => state.sidebar, [state.sidebar]),
    dashboard: useMemo(() => state.dashboard, [state.dashboard]),
    orders: useMemo(() => state.orders, [state.orders]),
    clients: useMemo(() => state.clients, [state.clients]),
    roles: useMemo(() => state.roles, [state.roles]),
    categories: useMemo(() => state.categories, [state.categories]),
    accountManagers: useMemo(() => state.accountManagers, [state.accountManagers]),
    priceAdjustment: useMemo(() => state.priceAdjustment, [state.priceAdjustment]),
    priceAdjustments: useMemo(() => state.priceAdjustments, [state.priceAdjustments]),
    fetchSidebarStrings: useCallback(fetchSidebarStrings, [dispatch, tokens.accessToken]),
    fetchDashboardStrings: useCallback(fetchDashboardStrings, [dispatch, tokens.accessToken]),
    fetchClientsStrings: useCallback(fetchClientsStrings, [dispatch, tokens.accessToken]),
    fetchOrdersStrings: useCallback(fetchOrdersStrings, [dispatch, tokens.accessToken]),
    fetchRolesStrings: useCallback(fetchRolesStrings, [dispatch, tokens.accessToken]),
    fetchCategoriesStrings: useCallback(fetchCategoriesStrings, [dispatch, tokens.accessToken]),
    fetchAccountManagersStrings: useCallback(fetchAccountManagersStrings, [dispatch, tokens.accessToken]),
    fetchPriceAdjustmentStrings: useCallback(fetchPriceAdjustmentStrings, [dispatch, tokens.accessToken]),
    fetchPriceAdjustmentsStrings: useCallback(fetchPriceAdjustmentsStrings, [dispatch, tokens.accessToken]),
  };
};


export default useStrings;
