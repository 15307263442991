import { useContext, useCallback } from 'react';
import axios from 'axios';

import { UserContext } from 'Common/contexts/UserContext';

import useAuth from 'Common/hooks/useAuth';

const useUser = () => {
  const { state, dispatch } = useContext(UserContext);
  const { tokens: { accessToken } } = useAuth();

  const fetch = useCallback(async (href) => {
    dispatch({ type: 'fetch_user_requested' });

    try {
      const { data } = await axios.get(href, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_user_successful',
        payload: {
          user: data,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_user_failed' });
      throw e;
    }
  }, [dispatch, accessToken]);

  const changeEmail = useCallback(async (newEmailAddress, password) => {
    dispatch({ type: 'change_email_requested' });

    try {
      await axios.put(
        state.user.links.find((x) => x.rel === 'change-email').href,
        {
          emailAddress: newEmailAddress,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      dispatch({
        type: 'change_email_successful',
        payload: {
          newEmailAddress,
        },
      });
      return null;
    } catch (e) {
      dispatch({ type: 'change_email_failed' });
      throw e;
    }
  }, [dispatch, accessToken, state.user]);

  const changePassword = useCallback(async (currentPassword, newPassword) => {
    dispatch({ type: 'change_password_requested' });

    try {
      await axios.put(
        state.user.links.find((x) => x.rel === 'change-password').href,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      dispatch({ type: 'change_password_successful' });
      return null;
    } catch (e) {
      dispatch({ type: 'change_password_failed' });
      throw e;
    }
  }, [dispatch, accessToken, state.user]);


  return {
    ...state,
    fetch,
    changeEmail,
    changePassword,
  };
};

export default useUser;
