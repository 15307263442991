import React, { useContext, useEffect, useMemo } from 'react';

import HeaderContainer from 'Admin/containers/HeaderContainer';
import SidebarContainer from 'Admin/containers/SidebarContainer';

import Footer from 'Common/components/Footer';

import { AppContext } from 'Common/contexts/AppContext';

import Routes from 'Admin/routes';

import './global.css';
import styles from './styles.module.css';

const App = () => {
  const { state } = useContext(AppContext);

  useEffect(() => {
    if (window.innerWidth <= 760) {
      document.body.style.overflow = (state.sidebarCollapsed ? 'scroll' : 'hidden');
    }
  }, [state.sidebarCollapsed]);

  return useMemo(() => (
    // onTouchStart={this.ontouchstart}
    <div className={styles.wrapper}>
      <HeaderContainer />

      <div className={styles.content}>
        <Routes />
        <Footer />
      </div>

      <SidebarContainer />
    </div>
  ), []);
};

export default App;
