import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import { AppProvider } from './AppContext';
import { AuthProvider } from './AuthContext';
import { UserProvider } from './UserContext';
import { BranchProvider } from './BranchContext';
import { ClientProvider } from './ClientContext';
import { LocalesProvider } from './LocalesContext';

const Providers = ({ children }) => (
  <AppProvider>
    <AuthProvider>
      <UserProvider>
        <BranchProvider>
          <ClientProvider>
            <LocalesProvider>
              {children}
            </LocalesProvider>
          </ClientProvider>
        </BranchProvider>
      </UserProvider>
    </AuthProvider>
  </AppProvider>
);

Providers.propTypes = {
  children: PropTypes.node,
};

Providers.defaultProps = {
  children: createElement('div'),
};

export default Providers;
