import React from 'react';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

import styles from './styles.module.css';

const ProgressBar = ({
  percentage,
  theme = "blue",
  className,
}) => (
  <div
    className={classnames(styles.containerBar, className)}
  >
    <div
      className={classnames(styles.innerBar, styles[theme])}
      role="progressbar"
      aria-valuenow={percentage}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{
        width: `${percentage}%`,
      }}
    />
  </div>
);

export default ProgressBar;
