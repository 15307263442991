import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Dropdown from '../Dropdown';
import Input from '../Input';
import Button from '../Button';

import { getDateRange, formatDateForInput } from '../../utils/dateUtils';

import timeframeType from 'Common/constants/timeframeType';
import timeRangeFiltersType from 'Common/constants/timeRangeFiltersType';

import styles from './styles.module.css';

const DateRangeFilter = ({
  className,
  timeframe = timeframeType.allTime,
  rangeFilter = timeRangeFiltersType.between,
  onChange = () => {},
  onTimeframeChange = () => {},
  onRangeFilterChange = () => {},
  onApplyClick = () => {},
  value,
}) => {
  const handleChange = (timeframeValue) => {
    let newDateRange;

    if (timeframeValue === timeframeType.custom) {
      newDateRange = {
        startDate: undefined,
        endDate: undefined,
      };
    } else if (timeframeValue === timeframeType.allTime) {
      newDateRange = {
        startDate: undefined,
        endDate: undefined,
      };
    } else {
      newDateRange = getDateRange(timeframeValue);
    }

    if (timeframeValue === timeframeType.custom) {
      if (rangeFilter === timeRangeFiltersType.before) {
        newDateRange.startDate = undefined;
      } else if (rangeFilter === timeRangeFiltersType.after) {
        newDateRange.endDate = undefined;
      }
    }

    onChange(newDateRange);
  };

  const handleTimeframeChange = (e) => {
    handleChange(e.target.value);
    onTimeframeChange(e.target.value);
  };

  const handleRangeFilterChange = (e) => {
    handleChange(timeframe);
    onRangeFilterChange(e.target.value);
  };

  const handleDateChange = (e) => {
    const { name, valueAsDate } = e.target;
    const stringValue = valueAsDate.toISOString();

    const newDateRange = value;
    newDateRange[name] = stringValue;

    onChange(newDateRange);
  };

  return (
    <div
      className={classnames(styles.container, 'p-3', className)}
    >
      <div>
        <Dropdown
          className="mr-3"
          value={timeframe}
          options={[
            { value: timeframeType.today, label: 'Today' },
            { value: timeframeType.yesterday, label: 'Yesterday' },
            { value: timeframeType.thisWeek, label: 'This Week' },
            { value: timeframeType.thisMonth, label: 'This Month' },
            { value: timeframeType.past3Months, label: 'Past 3 Months' },
            { value: timeframeType.past6Months, label: 'Past 6 Months' },
            { value: timeframeType.pastYear, label: 'Past Year' },
            { value: timeframeType.allTime, label: 'All Time' },
            { value: timeframeType.custom, label: 'Custom' },
          ]}
          onChangeRaw={handleTimeframeChange}
        />
        {timeframe === timeframeType.custom && (
          <Dropdown
            className="mr-3"
            value={rangeFilter}
            options={[
              { value: timeRangeFiltersType.before, label: 'Before' },
              { value: timeRangeFiltersType.after, label: 'After' },
              { value: timeRangeFiltersType.between, label: 'Between' },
            ]}
            disabled={timeframe !== timeframeType.custom}
            onChangeRaw={handleRangeFilterChange}
          />
        )}
        <Button
          onClick={onApplyClick}
          theme="action"
        >
          Apply
        </Button>
      </div>

      {timeframe === timeframeType.custom && (
        <div
          className="mt-2"
        >
          <Input
            className="mr-3"
            name="startDate"
            type="date"
            value={formatDateForInput(new Date(value.startDate))}
            disabled={(timeframe !== timeframeType.custom || rangeFilter === timeRangeFiltersType.before)}
            onChange={handleDateChange}
          />
          <Input
            name="endDate"
            type="date"
            value={formatDateForInput(new Date(value.endDate))}
            disabled={(timeframe !== timeframeType.custom || rangeFilter === timeRangeFiltersType.after)}
            onChange={handleDateChange}
          />
        </div>
      )}
    </div>
  );
};

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  timeframe: PropTypes.string,
  rangeFilter: PropTypes.string,
  onChange: PropTypes.func,
  onTimeframeChange: PropTypes.func,
  onRangeFilterChange: PropTypes.func,
  onApplyClick: PropTypes.func,
  value: {
    startDate: PropTypes.Date,
    endDate: PropTypes.Date,
  },
};

DateRangeFilter.defaultProps = {
  className: '',
  timeframe: timeframeType.allTime,
  rangeFilter: timeRangeFiltersType.between,
  onChange: () => {},
  onTimeframeChange: () => {},
  onRangeFilterChange: () => {},
  onApplyClick: () => {},
  value: undefined,
};

export default DateRangeFilter;
