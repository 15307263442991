import React from 'react';
import PropTypes from 'prop-types';

import { StringsProvider } from './StringsContext';
import { CategoriesProvider } from './CategoriesContext';
import { AccountManagersProvider } from './AccountManagersContext';

const Providers = ({ children }) => (
  <StringsProvider>
    <CategoriesProvider>
      <AccountManagersProvider>
        {children}
      </AccountManagersProvider>
    </CategoriesProvider>
  </StringsProvider>
);

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
