import { useContext, useCallback } from 'react';
import axios from 'axios';

import { CategoriesContext } from 'Admin/contexts/CategoriesContext';

import useAuth from 'Common/hooks/useAuth';

const useCategories = () => {
  const { state, dispatch } = useContext(CategoriesContext);
  const {
    tokens: {accessToken},
  } = useAuth();

  const fetch = async (href) => {
    dispatch({ type: 'fetch_categories_requested' });

    try {
      const { data } = await axios.get(href, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({
        type: 'fetch_categories_successful',
        payload: {
          categories: data.results,
        },
      });

      return data;
    } catch (e) {
      dispatch({ type: 'fetch_categories_failed' });
      throw e;
    }
  };

  const deleteCategory = async (href) => {
    dispatch({ type: 'delete_category_requested' });

    try {
      await axios.delete(href, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch({
        type: 'delete_category_successful',
      });

      return null;
    } catch (e) {
      dispatch({ type: 'delete_category_failed' });
      throw e;
    }
  };

  return {
    ...state,
    fetch: useCallback(fetch, [dispatch]),
    delete: useCallback(deleteCategory, [dispatch, state]),
  };
};

export default useCategories;
