import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const TextArea = ({
  className,
  name,
  value,
  width,
  placeholder,
  disabled,
  rows,
  resizable,
  onChange = () => {},
  onChangeRaw = () => {},
  onEnterKeyPress = () => {},
}, ref) => {
  const inputRef = useRef(null);
  useImperativeHandle(ref, () => inputRef.current);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onEnterKeyPress(e);
    }
  };

  const handleOnChange = (e) => {
    onChange(e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    onChangeRaw(e);
  };

  return (
    <textarea
      className={classnames(
        styles.input,
        className,
        resizable && styles.resizable,
      )}
      style={
        width && {
          width,
        }
      }
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleOnChange}
      onKeyPress={handleKeyPress}
      rows={rows}
      ref={inputRef}
      value={value}
    />
  );
};

const ForwardedInput = forwardRef(TextArea);

TextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  resizable: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onChangeRaw: PropTypes.func,
  onEnterKeyPress: PropTypes.func,
};

TextArea.defaultProps = {
  className: '',
  name: undefined,
  value: '',
  placeholder: '',
  disabled: false,
  resizable: true,
  rows: 4,
  onChange: () => {},
  onChangeRaw: () => {},
  onEnterKeyPress: () => {},
};

export default ForwardedInput;
