import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

const TableHeading = ({
  className,
  heading,
  sortable,
  onClick,
  sorted,
  sortDescending,
  width,
}) => {
  if (!sortable) {
    return (
      <th
        width={width}
      >
        {heading}
      </th>
    );
  }

  return (
    <th
      className={classnames(
        styles.sortable,
        className,
      )}
      width={width}
      onClick={onClick}
    >
      {heading}
      {sorted && (
        <span
          className={classnames(
            'glyphicon',
            (sortDescending) ? 'glyphicon-chevron-down' : 'glyphicon-chevron-up',
            'ml-2',
          )}
        />
      )}
    </th>
  );
};

TableHeading.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  sortable: PropTypes.bool,
  onClick: PropTypes.func,
  sorted: PropTypes.bool,
  sortDescending: PropTypes.bool,
  width: PropTypes.string,
};

TableHeading.defaultProps = {
  className: '',
  heading: '',
  sortable: false,
  onClick: () => {},
  sorted: false,
  sortDescending: false,
  width: undefined,
};

export default TableHeading;
