import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useParams, useHistory, useLocation } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

import completedRoute from 'Admin/hocs/PriceAdjustments/completedRoute';
import editableRoute from 'Admin/hocs/PriceAdjustments/editableRoute';
import mainRoute from 'Admin/hocs/PriceAdjustments/mainRoute';
import processingRoute from 'Admin/hocs/PriceAdjustments/processingRoute';

import useUser from 'Common/hooks/useUser';
import useStrings from 'Admin/hooks/useStrings';
import useFetch from 'Common/hooks/useFetch';

import InfoTab from 'Admin/tabs/PriceAdjustments/Info';
import ItemsTab from 'Admin/tabs/PriceAdjustments/Items';
import ClientsTab from 'Admin/tabs/PriceAdjustments/Clients';
import ScheduleTab from 'Admin/tabs/PriceAdjustments/Schedule';
import ReviewTab from 'Admin/tabs/PriceAdjustments/Review';

import ProcessingTab from 'Admin/tabs/PriceAdjustments/Processing';
import SummaryTab from 'Admin/tabs/PriceAdjustments/Summary';

import Space from 'Common/components/Space';
import PriceAdjustmentInfoBar from 'Admin/components/PriceAdjustments/PriceAdjustmentInfoBar';

import pageActions from 'Common/constants/pageActions';

import { isUndefined, isNull } from 'Common/utils/utils';

const TabComponentHOC = (
  Component,
  priceAdjustment,
  strings,
  updatePriceAdjustment = () => {},
  callback = () => {},
) => (
  () => {
    callback();
    return <Component priceAdjustment={priceAdjustment} strings={strings} updatePriceAdjustment={updatePriceAdjustment} />;
  });

const PriceAdjustmentPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const { user } = useUser();
  const { priceAdjustment: { strings, loading: loadingStrings }, fetchPriceAdjustmentStrings } = useStrings();

  const [pageAction] = useState(isUndefined(id) ? pageActions.creating : pageActions.editing);
  const [pageTitle, setPageTitle] = useState('Price Adjustment');

  const [priceAdjustmentUrl, setPriceAdjustmentUrl] = useState(null);
  const { data: fetchedPriceAdjustment, error: priceAdjustmentFetchError } = useFetch(priceAdjustmentUrl);

  const [priceAdjustment, setPriceAdjustment] = useState(undefined);

  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchPriceAdjustmentStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchPriceAdjustmentStrings]);

  useEffect(() => {
    setPriceAdjustment(fetchedPriceAdjustment);
  }, [fetchedPriceAdjustment]);

  useEffect(() => {
    if (pageAction === pageActions.editing) {
      setPriceAdjustmentUrl(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}`);
    }
  }, [pageAction, id]);

  useEffect(() => {
    if (!isUndefined(priceAdjustmentFetchError)) {
      history.push('/admin/price-adjustments');
    }
  }, [history, priceAdjustmentFetchError]);

  const getTabComponentHOC = (Component, title) => TabComponentHOC(Component, priceAdjustment, strings, setPriceAdjustment, () => setPageTitle(title));

  // Rendering
  if (isNull(strings)) {
    return null;
  }

  if (pageAction !== pageActions.creating && isUndefined(priceAdjustment)) {
    return <div>{strings.loading}</div>;
  }

  // Has now checked for price adjustment
  return (
    <div>
      {(pageAction !== pageActions.creating) && (
        <PriceAdjustmentInfoBar
          priceAdjustment={priceAdjustment}
          strings={strings}
          pageAction={pageAction}
          pageTitle={pageTitle}
        />
      )}

      <Space height="2em" />

      {pageAction === pageActions.creating && (
        <div>
          <Switch>
            <Route exact path="/admin/price-adjustment/create" component={getTabComponentHOC(InfoTab, strings.stageTitles.creating)} />
            <Route path="*" component={() => (<Redirect to="/admin/price-adjustment/create" />)} />
          </Switch>
        </div>
      )}
      {pageAction === pageActions.editing && (
        <div>
          <Switch>
            <Route exact path="/admin/price-adjustment/:id/info" component={editableRoute(getTabComponentHOC(InfoTab, strings.stageTitles.creating), priceAdjustment)} />
            <Route exact path="/admin/price-adjustment/:id/items" component={editableRoute(getTabComponentHOC(ItemsTab, strings.stageTitles.editingItemAdjustments), priceAdjustment)} />
            <Route exact path="/admin/price-adjustment/:id/clients" component={editableRoute(getTabComponentHOC(ClientsTab, strings.stageTitles.editingClientAdjustments), priceAdjustment)} />
            <Route exact path="/admin/price-adjustment/:id/schedule" component={editableRoute(getTabComponentHOC(ScheduleTab, strings.stageTitles.scheduling), priceAdjustment)} />
            <Route exact path="/admin/price-adjustment/:id/review" component={editableRoute(getTabComponentHOC(ReviewTab, strings.stageTitles.reviewing), priceAdjustment)} />

            <Route exact path="/admin/price-adjustment/:id/processing" component={processingRoute(getTabComponentHOC(ProcessingTab, strings.stageTitles.processing), priceAdjustment)} />
            <Route exact path="/admin/price-adjustment/:id/summary" component={completedRoute(getTabComponentHOC(SummaryTab, strings.stageTitles.summary), priceAdjustment)} />

            <Route path="*" component={mainRoute(priceAdjustment)} />
          </Switch>
        </div>
      )}
    </div>
  );
};

export default PriceAdjustmentPage;
