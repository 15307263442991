export default {
  Dashboard: '/admin/dashboard',
  MyAccount: '/my-account',
  OpenOrders: '',
  Returns: '',
  Orders: '/admin/orders',
  AccountManagers: '/admin/account-managers',
  Clients: '/admin/clients',
  Catalogue: '',
  Categories: '/admin/categories',
  DeliveryZones: '',
  DimensionTypes: '',
  Products: '',
  Promotions: '',
  Roles: '/admin/roles',
  BrandsReport: '',
  SalesReport: '',
  ProductsReport: '',
  KpisReport: '',
  PriceAdjustments: '/admin/price-adjustments',
  PriceAdjustment: '/admin/price-adjustment/',
};
