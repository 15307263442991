import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import classnames from 'classnames';

import styles from './styles.module.css';
import { isUndefined } from '../../utils/utils';

const Dropdown = ({
  className,
  theme,
  placeholder,
  options,
  value,
  onChange = () => {},
  onChangeRaw = () => {},
}) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
    onChangeRaw(e);
  };

  return (
    <select
      onChange={handleOnChange}
      value={!isUndefined(value) ? value : ''}
      options={options}
      className={classnames(
        styles.dropdown,
        styles[theme],
        className,
      )}
    >
      {isUndefined(value) && (
        <option value="" disabled selected>{placeholder}</option>
      )}
      {options.map((option) => (
        <option
          key={uuidv4()}
          value={option.value}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
};

Dropdown.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'filter']),
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onChangeRaw: PropTypes.func,
};

Dropdown.defaultProps = {
  className: '',
  theme: 'default',
  placeholder: '',
  options: [],
  value: undefined,
  onChange: () => {},
  onChangeRaw: () => {},
};

export default Dropdown;
