const stringsReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    // Fetch Sidebar Strings
    case 'fetch_sidebar_strings_requested':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          loading: true,
        },
      };
    case 'fetch_sidebar_strings_successful':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_sidebar_strings_failed':
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          loading: false,
          strings: null,
        },
      };

    // Fetch Dashboard Strings
    case 'fetch_dashboard_strings_requested':
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
        },
      };
    case 'fetch_dashboard_strings_successful':
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_dashboard_strings_failed':
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          strings: null,
        },
      };

    // Fetch Clients Strings
    case 'fetch_clients_strings_requested':
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: true,
        },
      };
    case 'fetch_clients_strings_successful':
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_clients_strings_failed':
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          strings: null,
        },
      };

    // Fetch Orders Strings
    case 'fetch_orders_strings_requested':
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: true,
        },
      };
    case 'fetch_orders_strings_successful':
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_orders_strings_failed':
      return {
        ...state,
        orders: {
          ...state.orders,
          loading: false,
          strings: null,
        },
      };

    // Fetch Roles Strings
    case 'fetch_roles_strings_requested':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    case 'fetch_roles_strings_successful':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_roles_strings_failed':
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: false,
          strings: null,
        },
      };

    // Fetch Categories Strings
    case 'fetch_categories_strings_requested':
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
        },
      };
    case 'fetch_categories_strings_successful':
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_categories_strings_failed':
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          strings: null,
        },
      };

    // Fetch Account Managers Strings
    case 'fetch_account_managers_strings_requested':
      return {
        ...state,
        accountManagers: {
          ...state.accountManagers,
          loading: true,
        },
      };
    case 'fetch_account_managers_strings_successful':
      return {
        ...state,
        accountManagers: {
          ...state.accountManagers,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_account_managers_strings_failed':
      return {
        ...state,
        accountManagers: {
          ...state.accountManagers,
          loading: false,
          strings: null,
        },
      };

    // Fetch Price Adjustment Strings
    case 'fetch_priceadjustment_strings_requested':
      return {
        ...state,
        priceAdjustment: {
          ...state.priceAdjustment,
          loading: true,
        },
      };
    case 'fetch_priceadjustment_strings_successful':
      return {
        ...state,
        priceAdjustment: {
          ...state.priceAdjustment,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_priceadjustment_strings_failed':
      return {
        ...state,
        priceAdjustment: {
          ...state.priceAdjustment,
          loading: false,
          strings: null,
        },
      };

      // Fetch Price Adjustments Strings
    case 'fetch_priceadjustments_strings_requested':
      return {
        ...state,
        priceAdjustments: {
          ...state.priceAdjustments,
          loading: true,
        },
      };
    case 'fetch_priceadjustments_strings_successful':
      return {
        ...state,
        priceAdjustments: {
          ...state.priceAdjustments,
          loading: false,
          strings: payload.strings,
        },
      };
    case 'fetch_priceadjustments_strings_failed':
      return {
        ...state,
        priceAdjustments: {
          ...state.priceAdjustments,
          loading: false,
          strings: null,
        },
      };

    default:
      return state;
  }
};

export default stringsReducer;
