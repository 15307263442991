import useInput from 'Common/hooks/useInput';

const useCapitalizedInput = (initialValue) => {
  const input = useInput(initialValue);

  const onChange = (newValue) => {
    input.setValue(newValue.toUpperCase());
  };

  const onChangeRaw = (e) => {
    const { target: { value } } = e;
    input.setValue(value.toUpperCase());
  };

  return {
    ...input,
    onChange,
    onChangeRaw,
  };
};

export default useCapitalizedInput;
