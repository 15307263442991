import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isUndefined } from 'Common/utils/utils';

const Image = ({
  className,
  source,
  defaultSource,
  width,
  height,
  alt,
}) => {
  const [useDefaultImage, setUseDefaultImage] = useState(false);

  useEffect(() => {
    if (isUndefined(source)) {
      setUseDefaultImage(true);
    }
  }, [source]);

  return (
    <img
      className={className}
      src={
        useDefaultImage
          ? defaultSource
          : source
      }
      onError={() => setUseDefaultImage(true)}
      alt={alt}
      style={{
        width,
        height,
      }}
    />
  );
};

Image.propTypes = {
  className: PropTypes.string,
  source: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string),
    }),
    // Opaque type returned by require('./image.jpg')
    PropTypes.number,
    // Multiple sources
    PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        headers: PropTypes.objectOf(PropTypes.string),
      }),
    ),
  ]),
  defaultSource: PropTypes.oneOfType([
    PropTypes.shape({
      uri: PropTypes.string,
      headers: PropTypes.objectOf(PropTypes.string),
    }),
    // Opaque type returned by require('./image.jpg')
    PropTypes.number,
    // Multiple sources
    PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        headers: PropTypes.objectOf(PropTypes.string),
      }),
    ),
  ]),
  alt: PropTypes.string,
};

Image.defaultSource = {
  className: '',
  source: undefined,
  defaultSource: undefined,
  alt: '',
};

export default Image;
