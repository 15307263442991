import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useInput from 'Common/hooks/useInput';
import useAuth from 'Common/hooks/useAuth';

import Text from 'Common/components/Text';
import Input from 'Common/components/Input';
import Space from 'Common/components/Space';
import Button from 'Common/components/Button';
import TextArea from 'Common/components/TextArea';

import pageActions from 'Common/constants/pageActions';

import { isUndefined } from 'Common/utils/utils';

const PriceAdjustmentInfoTab = ({
  priceAdjustment,
  updatePriceAdjustment,
  strings: { info: strings },
}) => {
  const { id } = useParams();
  const { tokens: { accessToken } } = useAuth();

  const history = useHistory();
  const { addToast } = useToasts();

  const [pageAction] = useState(isUndefined(id) ? pageActions.creating : pageActions.editing);

  const { value: title, onChange: onTitleChange, setValue: setTitleValue } = useInput('');
  const { value: reason, onChange: onReasonChange, setValue: setReasonValue } = useInput('');

  const [inputErrors, setInputErrors] = useState({
    title: undefined,
  });

  console.log(strings)

  const [isPerformingAction, setIsPerformingAction] = useState(false);

  const validateInputs = () => {
    const newInputErrors = {
      title: (() => (title.length < 5
        ? strings.inputErrors.titleTooSmall
        : undefined))(),
    };

    setInputErrors(newInputErrors);

    return (
      isUndefined(newInputErrors.title)
    );
  };

  // Handlers
  const handleTitleChange = (value) => {
    setInputErrors({
      title: undefined,
    });
    onTitleChange(value);
  };

  const handleBackClick = () => {
    history.push('/admin/price-adjustments');
  };

  const handleCreateClick = async () => {
    setIsPerformingAction(true);

    if (!validateInputs()) {
      setIsPerformingAction(false);
      return;
    }

    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments`, {
        title,
        reason,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      addToast(strings.toastMessages.successfulCreate, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });

      history.push(`/admin/price-adjustment/${data.id}/items`);
    } catch (e) {
      addToast(strings.toastMessages.failedCreate, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setIsPerformingAction(false);
  };

  const handleUpdateClick = async () => {
    setIsPerformingAction(true);

    if (!validateInputs()) {
      setIsPerformingAction(false);
      return;
    }

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}`, {
        title,
        reason,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      updatePriceAdjustment({
        ...priceAdjustment,
        title,
        reason,
      });

      addToast(strings.toastMessages.successfulUpdate, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      addToast(strings.toastMessages.failedUpdate, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setIsPerformingAction(false);
  };

  const handleNextClick = async () => {
    history.push(`/admin/price-adjustment/${priceAdjustment.id}/items`);
  };

  useEffect(() => {
    if (!isUndefined(priceAdjustment)) {
      setTitleValue(priceAdjustment.title);
      setReasonValue(priceAdjustment.reason);
    }
  }, [priceAdjustment, setTitleValue, setReasonValue]);

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12}>
              {pageAction === pageActions.creating && (
                <h2>{strings.subTitles.create}</h2>
              )}

              {pageAction === pageActions.editing && (
                <h2>{strings.subTitles.edit}</h2>
              )}
            </Col>
          </Row>

          <Space height="2em" />

          <Row>
            <Col xs={12}>
              <div>
                <Text
                  theme="error"
                  size="small"
                >
                  {inputErrors.title}
                </Text>
              </div>
              <div><Text bold>{`${strings.inputLabels.title} *`}</Text></div>
              <div>
                <Input
                  width="40em"
                  value={title}
                  onChange={handleTitleChange}
                  disabled={isPerformingAction}
                />
              </div>
            </Col>
          </Row>

          <Space height="2em" />

          <Row>
            <Col xs={12}>
              <div><Text bold>{strings.inputLabels.reason}</Text></div>
              <div>
                <TextArea
                  rows={5}
                  width="100%"
                  resizable={false}
                  value={reason}
                  onChange={onReasonChange}
                  disabled={isPerformingAction}
                />
              </div>
            </Col>
          </Row>

          {pageAction === pageActions.editing && (
            <Row
              className="align-content-right"
            >
              <Col>
                <Space height="1em" />

                <Button
                  theme="blue"
                  onClick={handleUpdateClick}
                  disabled={priceAdjustment.title === title && priceAdjustment.reason === reason}
                >
                  {strings.updateButton}
                </Button>
              </Col>
            </Row>
          )}

          <Space height="2em" />

          <Row
            className="align-content-right"
          >
            <Col>
              {pageAction === pageActions.creating && (
                <>
                  <Button
                    className="mr-2"
                    theme="default"
                    onClick={handleBackClick}
                    disabled={isPerformingAction}
                  >
                    {strings.cancelButton}
                  </Button>
                  <Button
                    theme={title.length < 5 ? "default" : "action"}
                    onClick={handleCreateClick}
                    disabled={isPerformingAction}
                  >
                    {strings.createButton}
                  </Button>
                </>
              )}

              {pageAction === pageActions.editing && (
                <>
                  <Button
                    className="mr-2"
                    theme="default"
                    onClick={handleBackClick}
                  >
                    {strings.backButton}
                  </Button>
                  <Button
                    theme="action"
                    onClick={handleNextClick}
                    disabled={isPerformingAction}
                  >
                    {strings.nextButton}
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PriceAdjustmentInfoTab;
