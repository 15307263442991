import React, {
  createElement, createContext, useReducer, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import authReducer from 'Common/reducers/authReducer';

export const AuthContext = createContext();

const initialState = {
  loggedIn: false,
  loggingIn: false,
  refreshingTokens: false,
  rememberMe: false,
  claims: undefined,
  tokens: {
    accessToken: undefined,
    refreshToken: undefined,
  },
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState, () => {
    const newState = initialState;

    const rememberMe = localStorage.getItem('ePro:rememberMe');
    newState.rememberMe = rememberMe ? JSON.parse(rememberMe) : initialState.rememberMe;

    const tokens = localStorage.getItem('ePro:tokens');
    newState.tokens = tokens ? JSON.parse(tokens) : initialState.tokens;
    return newState;
  });

  useEffect(() => {
    localStorage.setItem('ePro:rememberMe', JSON.stringify(state.rememberMe));
  }, [state.rememberMe]);

  useEffect(() => {
    localStorage.setItem('ePro:tokens', JSON.stringify({
      accessToken: state.tokens.accessToken,
      refreshToken: state.tokens.refreshToken,
    }));
  }, [state.tokens]);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

AuthProvider.defaultProps = {
  children: createElement('div'),
};

export const AuthConsumer = AuthContext.Consumer;
