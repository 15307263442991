import React from 'react';
import PropTypes from 'prop-types';

import Image from 'Common/components/Image';

import defaultProfilePicture from 'Common/assets/images/default-profile-picture.png';

import styles from './styles.module.css';

const HeaderComponent = ({
  fullName,
  username,
  emailAddress,
  accountNumber,
  profilePicture = defaultProfilePicture,
}) => (
  <div className={styles.userInfoWrapper}>
    <Image
      className={styles.profilePicture}
      source={profilePicture}
      defaultSource={defaultProfilePicture}
      alt="profile"
    />
    <div className={styles.userInfo}>
      <p className={styles.name}>{fullName}</p>
      <p className={styles.accountNumber}>{accountNumber}</p>
    </div>
  </div>
);

HeaderComponent.propTypes = {
  fullName: PropTypes.string,
  username: PropTypes.string,
  emailAddress: PropTypes.string,
  accountNumber: PropTypes.string,
  profilePicture: PropTypes.string,
};

export default HeaderComponent;
