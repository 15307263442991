import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Input from 'Common/components/Input';
import Text from 'Common/components/Text';
import Button from 'Common/components/Button';
import Space from 'Common/components/Space';
import AccountManagerInfo from './AccountManagerInfo';
import SearchableDropdown from 'Common/components/SearchableDropdown';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const AccountManagerReplacementForm = ({
  open,
  onRequestClose,
  onReplaceClick,
  sourceAccountManager,
  destinationAccountManager,
  accountManagersOptions,
  onAccountManagerSearchChange,
  onAccountManagerSearchSelected,
  onAccountManagerSearchClick,
  replacing,
}) => (
  <Modal
    isOpen={open}
    contentLabel="Account Manager Replacement"
    onRequestClose={onRequestClose}
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
    <div>
      <h2>Replace Account Manager</h2>
      <p>Donec pellentesque accumsan nisl, a condimentum erat. Curabitur id egestas orci. Pellentesque sodales elementum urna, non euismod justo tincidunt luctus. Donec nisl elit, venenatis at diam eget, semper facilisis arcu. Quisque euismod non urna sodales pretium. Fusce finibus facilisis augue, ut sollicitudin neque sollicitudin in. Duis venenatis leo at urna egestas, quis feugiat tortor interdum. Nunc a lacinia odio, vel ultricies nisi.</p>
    </div>

    <hr />

    {!isUndefined(sourceAccountManager) && (
      <>
        <div className={styles.columnWrapper}>
          <div className={styles.column}>
            <h2>Original</h2>
            <Input
              className={styles.input}
              value={`${sourceAccountManager.firstName} ${sourceAccountManager.lastName}`}
              disabled
            />

            <Space height="2em" />

            <AccountManagerInfo
              accountManager={sourceAccountManager}
            />
          </div>

          <div className={styles.verticalLine} />

          <div className={styles.column}>
            <h2>Replacement</h2>
            <SearchableDropdown
              className={styles.input}
              placeholder="Search Account Manager"
              onSearchChange={onAccountManagerSearchChange}
              onChange={onAccountManagerSearchSelected}
              onClick={onAccountManagerSearchClick}
              options={accountManagersOptions}
            />

            <Space height="2em" />

            <AccountManagerInfo
              accountManager={destinationAccountManager}
            />
          </div>
        </div>

        <div
          className={styles.replaceButtonContainer}
        >
          <Button
            onClick={onReplaceClick}
            theme="blue"
            disabled={isUndefined(destinationAccountManager) || replacing}
          >
            Replace
          </Button>
        </div>
      </>
    )}
  </Modal>
);

AccountManagerReplacementForm.defaultProp = {
  onRequestClose: () => {},
};

AccountManagerReplacementForm.propTypes = {

};

export default AccountManagerReplacementForm;
