import React from 'react';

import { Row, Col } from 'react-bootstrap';

const PrivacyPolicyPage = () => (
  <Row>
    <Col>
      <h2>Terms &amp; Conditions of Use and Sale</h2>

      <p>This statement was last updated on 30/01/2009.</p>

      <p>
        <strong>
          Please read these conditions carefully before using the workwearorderline.com website. By using the workwearorderline.com website, you signify your agreement to be bound by these conditions.
        </strong>
      </p>

      <a href="#use">Terms &amp; Conditions to your use of the website</a><br />
      <a href="#sale">Terms &amp; Conditions to the sale of goods to you</a>

      <h2>Conditions relating to your use of this website</h2>

      <p>
        The Workwearorderline.com website is provided free of charge for your convenience by BACA Safety &amp; Workwear. In consideration for this we require that you agree to the following terms (&#39;the Terms&#39;) for use of the Site. If you do not agree to the terms please cease using this site. If you continue to use the Site you are agreeing to the terms that appear below, together with any specific terms set out on individual pages within the Site. If you have any questions, please contact us at sales@bacasafety.com
      </p>

      <p>
        This Site is not intended to be accessed or used by users in countries other than the United Kingdom and  no representation or warranty is made as to whether the information available in this Site complies with the regulatory regime of other countries.
      </p>

      <h2>1. Your Account</h2>

      <p>
        If you use the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorised access to your account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner.
      </p>

      <p>
        Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering.
        You can access and update much of the information you provided us with in the Your Account area of the website.
      </p>

      <p>
        BACA reserves the right to refuse access to the website, terminate accounts, remove or edit content, or cancel orders at our discretion. If we cancel an order, it will be without charge to you.
      </p>

      <h2>2. Privacy</h2>

      <p>
        Please review our <a href="Privacy-policy.aspx" title="View the privacy policy" target="_blank">Privacy Policy</a>, which also governs your visit to workwearorderline.com, to understand our practices.
      </p>

      <h2>4. Access to Workwearorderline.com</h2>

      <p>
        We will do our utmost to ensure that availability of the website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services. We will attempt to limit the frequency and duration of any such suspension or restriction.
      </p>

      <h2>5. Licence for website access</h2>

      <p>
        BACA grants you a limited licence to access and make use of this website, but not to download (other than page caching) or modify it, or any portion of it, except with express written consent of BACA. This licence does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools.
      </p>

      <p>
        This website or any portion of this website may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without our express written consent.
      </p>

      <p>
        You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of BACA and its affiliates without express written consent. You may not use any meta tags or any other &quot;hidden text&quot; utilising workwearorderline.com&#39;s or trademarks without the express written consent of BACA. Any unauthorised use terminates the permission or license granted by BACA.
      </p>

      <p>
        You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of workwearorerline.com as long as the link does not portray workwearorderline.com, its affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any workwearorderline.com logo or other proprietary graphic or trademark as part of the link without our express written consent.
      </p>

      <h2>6. Your conduct</h2>

      <p>You must not use the website in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way.</p>

      <p>You understand that you, and not workwearorderline.com, are responsible for all electronic communications and content sent from your computer to us and you must use the website for lawful purposes only.</p>

      <p>You must not use the website for any of the following:</p>

      <ul>
        <li>for fraudulent purposes, or in connection with a criminal offence or other unlawful activity</li>
        <li>
          to send, use or reuse any material that is illegal, offensive, abusive, indecent, defamatory, obscene or menacing; or in breach of copyright, trademark, confidence, privacy or any other right; or is otherwise injurious to third parties; or objectionable; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any &quot;spam&quot;
        </li>
        <li>to cause annoyance, inconvenience or needless anxiety</li>
      </ul>

      <h2>7. Copyright, authors&#39; rights and database rights</h2>

      <p>
        All content included on the website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of workwearorderline.com, its affiliates or its content suppliers and is protected by UK and international copyright, authors&#39; rights and database right laws. The compilation of all content on this website is the exclusive property of workwearorderline.com and its affiliates and is protected by United Kingdom and international copyright and database right laws. All software used on this website is the property of workwearorderline.com, our affiliates or our software suppliers and is protected by UK and international copyright and author&#39; rights laws.
      </p>

      <p>
        You may not systematically extract and/or re-utilise parts of the contents of the website without workwearorderline.com&#39;s express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this website, without workwearorderline.com&#39;s express written consent. You also may not create and/or publish your own database that features substantial (eg our prices and product listings) parts of this website without workwearorderline.com&#39;s express written consent.
      </p>

      <h2>11. Trademarks</h2>

      <p>
        WORKWEARORDERLINE.COM, Workwear Orderline, THE WORKWEAR ORDERLINE LOGO, MANPACK, MANPACK EXPRESS, VELTUFF and other marks indicated on our website are trademarks or registered trademarks of BACA or its subsidiaries (collectively &quot;BACA&quot;), in the European Union and/or other jurisdictions. workwearorderline.com&#39;s graphics, logos, page headers, button icons, scripts and service names are the trademarks or trade dress of BACA. BACA&#39;s trademarks and trade dress may not be used in connection with any product or service that is not BACA&#39;s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits BACA. All other trademarks not owned by BACA that appear on this website are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by BACA.
      </p>

      <h2>Conditions relating to the sale of goods to you</h2>

      <p>
        These conditions form an integral part of every contract or agreement for the sale and/or supply of goods and/or services (&quot;the products&quot;) by BACA WORKWEAR AND SAFETY (&quot;BACA&quot;). Unless specific alterations or deletions are expressly agreed in writing on behalf of BACA, these conditions shall apply in their entirety to all quotations made and all orders accepted on behalf of BACA.
        Except as provided above, no servant or agent of BACA has any authority to vary these terms or accept other terms proposed by a
        Customer which are inconsistent with these conditions unless confirmed in writing.
      </p>

      <h2>1. Payment Terms</h2>

      <p>
        (1) Unless otherwise agreed accounts are due and become payable not later than 30 days from delivery of BACA’s invoice.
        Where no account is held Terms are pro-forma or credit card.
      </p>

      <p>(2) Unless otherwise specifically stated the purchase price is deemed to exclude Value Added Tax and this will be added where necessary. All prices quoted are liable to VAT standard rate.</p>

      <p>(3) Interest will be payable at the rate of 2% per month on all overdue debts.</p>

      <p>(4) BACA reserve the right to suspend or terminate supply and lower credit limit in default of payments by customer.</p>

      <h2>2. Delivery</h2>

      <p>
        (1) BACA will endeavour to meet any delivery date quoted but delivery dates are given and intended as an estimate only. If there are any circumstances whatsoever which prevent or delay delivery, BACA shall not be bound to supply or make delivery of any goods ordered nor shall it be liable for any damage or delay in delivery when so prevented or delayed.
      </p>

      <p>
        (2) For delivery costs, please ring the Baca sales office or see the current price list with delivery details.
        All goods must be inspected immediately on arrival. Under no circumstances shall BACA be liable for damage to or from a consignment unless BACA is notified within three days of receipt of the goods or in the case of non-delivery unless notice is given within seven days of the due date of arrival.
      </p>

      <h2>3. Returned Goods</h2>
      <p>
        (1) BACA will not accept the return of goods for any reason unless prior agreement has been received from BACA. Collection will be made only with valid collection note.
      </p>

      <p>
        (2) Goods returned for no reason will incur a 20% handling charge.
        A fee will be charged if collection is required.
      </p>

      <p>
        (3) Goods returned for exchange for different sizes or styles will incur a 10% handling charge.
      </p>

      <h2>4. Risk &amp; Title</h2>

      <p>
        (1) It is a condition of the contract that the property in all the products delivered by BACA to the Customer shall remain vested in BACA until BACA has received in cash or cleared funds payment in full for the products delivered and all products agreed to be sold by BACA to the Customer for which payment is then due.
      </p>

      <p>
        (2) Until payment due under all contracts between the Customer and BACA has been made in full the Customer shall hold the products upon trust for BACA.
      </p>
      <p>
        (3) Until such time as the property in the products passes to the Customer, the Customer shall hold the products as the Company’s fiduciary agent and bailee and shall keep the products separate from those of the Customer and third parties and properly stored, protected and insured and identified as BACA’s property.
      </p>

      <p>
        (4) Notwithstanding that the property in all products to be delivered to the Customer by BACA shall remain vested in BACA until BACA has received payment in full, the risk of damage to or loss of all or any such products shall pass to the Customer forthwith upon delivery or deemed delivery thereof to the Customer and as from such date of delivery or deemed delivery the Customer shall be liable to pay BACA the contract price for such products whether or not the same are damaged or lost prior to this dates that the property therein shall pass to the Customer.
      </p>

      <p>
        (5) Until such time as legal title in the goods passes to the
        Purchaser BACA may at any time require the Purchaser, its liquidator, (administrative) receiver or administrator to return the goods and/or may repossess the goods by entering upon any premises of the Purchaser or any third party where the goods are reasonably believed to be stored.
      </p>

      <h2>5. Warranty and Limitation of Liability</h2>

      <p>
        (1) Save as is specifically set out herein BACA shall have no liability whatsoever (however arising) in relation to any loss suffered by the Customer or any third party arising from the supply of the products (however caused).
      </p>

      <p>
        (2) If loss, damage or deterioration (other than for death or personal injury) is caused by BACA’s negligence, BACA’s liability shall be limited to the invoice value of the goods.
      </p>

      <p>
        (3) The employee of BACA are not authorised to make oral representations as to the quality or fitness for any particular purpose of any products. If a representation is made or an opinion expressed orally which materially affects the customer&#39;s decision to place an order for any products the Customer should ensure that such details are confirmed in writing by a duly authorised officer or employee of BACA so as to form a part of the contract; no liability can otherwise be accepted.
      </p>

      <p>
        (4) BACA shall not be liable to the Customer or be deemed to be in breach of contract by reason of any delay in performing or any failure to perform, any of BACA’s obligations in relation to the products, if the delay or failure was due to any cause beyond BACA’s reasonable control.
      </p>

      <h2>6. Waiver</h2>

      <p>
        (1) The rights of BACA shall not be prejudiced or restricted by any indulgence or forbearance extended by BACA to the Customer and no waiver by BACA in respect of any breach shall operate as a waiver in respect of any subsequent breach.
      </p>

      <h2>7. Jurisdiction</h2>

      <p>
        (1) These conditions shall be governed by, and construed in all respect in accordance with English Law.
      </p>

      <p>
        &copy; BACA Safety &amp; Workwear 2008. All rights reserved. No part of this publication may be reproduced, stored in a retrieval system or transmitted, in any form or by any means, electronic, mechanical, photocopying, recording or otherwise without the prior written permission of BACA.
      </p>

      <p>B.S.T.C. REV: 01-01-08</p>
      <p>
        WHILST EVERY EFFORT HAS BEEN MADE TO ENSURE TRUE COLOUR REPRESENTATION, THE COLOURS ARE ONLY AS ACCURATE AS THE PRINTING PROCESS ALLOWS. E &amp; OE.
      </p>
    </Col>
  </Row>
);

export default PrivacyPolicyPage;
