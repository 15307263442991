import React, { createElement, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import UserReducer from 'Common/reducers/userReducer';

export const UserContext = createContext();

const initialState = {
  loading: false,
  user: undefined,
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  return (
    <UserContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: createElement('div'),
};

export const UserConsumer = UserContext.Consumer;
