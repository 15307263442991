import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useAuth from 'Common/hooks/useAuth';
import useInput from 'Common/hooks/useInput';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useDebounce from 'Common/hooks/useDebounce';

import Space from 'Common/components/Space';
import Input from 'Common/components/Input';
import Text from 'Common/components/Text';
import PageSelector from 'Common/components/PageSelector';
import ClientsTable from 'Admin/components/PriceAdjustments/ClientsTable';
import GlobalClientAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalClientAdjustmentSettingsBox';
import ClientAdjustmentsTable from 'Admin/components/PriceAdjustments/ClientAdjustmentsTable';
import ClientExclusionsTable from 'Admin/components/PriceAdjustments/ClientExclusionsTable';
import AdjustmentSaveChangesBox from 'Admin/components/PriceAdjustments/AdjustmentSaveChangesBox';
import InvalidChangesErrorBox from 'Admin/components/PriceAdjustments/InvalidChangesErrorBox';

import { nestedCopy, isUndefined, delay } from 'Common/utils/utils';

const PriceAdjustmentClientsTab = ({
  priceAdjustment,
  strings: { clients: strings },
}) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    tokens: { accessToken },
  } = useAuth();
  const { addToast } = useToasts();

  const [clientPage, setClientPage] = useState(1);
  const { value: search, onChange: onSearchChange } = useInput('');
  const [searchValue, setSearchedValue] = useState('');

  const { results: clients, loading: loadingClients, currentPage: currentClientPage, totalPages: totalClientPages } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/clients?status=active&status=onstop&pageSize=50&page=${clientPage}&search=${searchValue}`);
  const { results: existingClientAdjustments, loading: loadingClientAdjustments, refetch: refetchClientAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/clients?pageSize=1000`);

  const [clientAdjustments, setClientAdjustments] = useState([]);
  const [clientExclusions, setClientExclusions] = useState([]);

  const [savingChanges, setSavingChanges] = useState(false);

  const getInitialGlobalClientAdjustmentState = () => (
    priceAdjustment.adjustAllClients
      ? priceAdjustment.globalClientAdjustment
      : {
        adjustmentPercentage: '',
      });

  const [savedAdjustAllClients, setSavedAdjustAllClients] = useState(priceAdjustment.adjustAllClients);
  const [savedGlobalClientAdjustment, setSavedGlobalClientAdjustment] = useState(getInitialGlobalClientAdjustmentState());
  const [adjustAllClients, setAdjustAllClients] = useState(priceAdjustment.adjustAllClients);
  const [globalClientAdjustment, setGlobalClientAdjustment] = useState(getInitialGlobalClientAdjustmentState());

  const { results: existingClientExclusions, loading: loadingClientExclusions, refetch: refetchClientExclusions } = usePagedFetch(adjustAllClients
    ? `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/exclusions/clients?pageSize=1000`
    : '');

  const isClientExcluded = (clientId) => clientExclusions.some((x) => x.client.id === clientId);
  const hasAdjustmentForClient = (clientId) => clientAdjustments.some((x) => x.client.id === clientId);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setClientAdjustments(existingClientAdjustments);
  }, [existingClientAdjustments, setClientAdjustments]);

  useEffect(() => {
    setClientExclusions(existingClientExclusions);
  }, [existingClientExclusions, setClientExclusions]);

  const hasGlobalAdjustmentbeenChanged = () => {
    if (savedAdjustAllClients !== adjustAllClients) {
      return true;
    }

    if (adjustAllClients) {
      if (savedGlobalClientAdjustment.adjustmentPercentage !== globalClientAdjustment.adjustmentPercentage) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (debouncedSearch) {
      setSearchedValue(search);
    }
  }, [debouncedSearch, setSearchedValue, search]);

  const haveChangesBeenMade = () => {
    if (hasGlobalAdjustmentbeenChanged()) {
      return true;
    }

    if (clientAdjustments.length !== existingClientAdjustments.length) {
      return true;
    }

    if (clientExclusions.length !== existingClientExclusions.length) {
      return true;
    }

    const hasAnyAdjustmentChanged = clientAdjustments.some((adjustment) => {
      const existingAdjustment = existingClientAdjustments.find((x) => x.client.id === adjustment.client.id);

      if (isUndefined(existingAdjustment)) {
        return true;
      }

      if (existingAdjustment.adjustmentPercentage !== adjustment.adjustmentPercentage) {
        return true;
      }

      return false;
    });

    if (hasAnyAdjustmentChanged) {
      return true;
    }

    const hasAnyExclusionChanged = clientExclusions.some((exclusion) => {
      return existingClientAdjustments.some((x) => x.client.id === exclusion.client.id);
    });

    if (hasAnyExclusionChanged) {
      return true;
    }

    return false;
  };

  const haveInvalidChangesBeenMade = () => {
    if (adjustAllClients && Number(globalClientAdjustment.adjustmentPercentage) === 0) {
      return true;
    }

    if (clientAdjustments.some((adjustment) => Number(adjustment.adjustmentPercentage) === 0)) {
      return true;
    }

    return false;
  };

  const saveGlobalAdjustmentChanges = async () => {
    try {
      if (savedAdjustAllClients !== adjustAllClients) {
        if (adjustAllClients) {
          await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/global`, {
            adjustmentPercentage: globalClientAdjustment.adjustmentPercentage
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        } else {
          await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/global`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      } else {
        await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/global`, {
          adjustmentPercentage: globalClientAdjustment.adjustmentPercentage
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    } catch (e) {
      addToast(strings.toastMessages.failedUpdateGlobalAdjustment, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const createClientAdjustment = async (clientAdjustment) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/${clientAdjustment.client.id}`, {
        adjustmentPercentage: clientAdjustment.adjustmentPercentage
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedAddClientAdjustment}: ${clientAdjustment.client.name}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const updateClientAdjustment = async (clientAdjustment) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/${clientAdjustment.client.id}`, {
        adjustmentPercentage: clientAdjustment.adjustmentPercentage,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedUpdateClientAdjustment}: ${clientAdjustment.client.name}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const deleteClientAdjustment = async (clientAdjustment) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/clients/${clientAdjustment.client.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedRemoveClientAdjustment}: ${clientAdjustment.client.name}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const saveClientAdjustmentChanges = async () => {
    const deletedAdjustments = existingClientAdjustments.filter(
      (existingAdjustment) => !clientAdjustments.some((adjustment) => adjustment.client.id === existingAdjustment.client.id));

    deletedAdjustments.forEach(async (adjustment) => {
      await deleteClientAdjustment(adjustment);
    });

    clientAdjustments.forEach(async (adjustment) => {
      const existingAdjustment = existingClientAdjustments.find((x) => x.client.id === adjustment.client.id);

      if (isUndefined(existingAdjustment)) {
        await createClientAdjustment(adjustment);
      } else if (existingClientAdjustments.adjustmentPercentage !== adjustment.adjustmentPercentage) {
        await updateClientAdjustment(adjustment);
      }
    });
  };

  const createClientExclusion = async (clientExclusion) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/exclusions/clients/${clientExclusion.client.id}`, {
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedAddClientExclusion}: ${clientExclusion.client.name}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const deleteClientExclusion = async (clientExclusion) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${id}/exclusions/clients/${clientExclusion.client.id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      addToast(`${strings.toastMessages.failedRemoveClientExclusion}: ${clientExclusion.client.name}`, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const saveClientExclusionChanges = async () => {
    const deletedExclusions = existingClientExclusions.filter(
      (existingExclusion) => !clientExclusions.some((exclusion) => exclusion.client.id === existingExclusion.client.id));

    deletedExclusions.forEach(async (exclusion) => {
      await deleteClientExclusion(exclusion);
    });

    clientExclusions.forEach(async (exclusion) => {
      if (!existingClientExclusions.some((x) => x.client.id === exclusion.client.id)) {
        await createClientExclusion(exclusion);
      }
    });
  };

  // Handlers
  const handleAdjustAllClientsChange = (value) => {
    setAdjustAllClients(value);
    setGlobalClientAdjustment({
      ...globalClientAdjustment,
      adjustmentPercentage: value ? '3.00' : null,
    });
  };

  const handleAdjustmentPercentageChange = (value) => {
    setGlobalClientAdjustment({
      ...globalClientAdjustment,
      adjustmentPercentage: value,
    });
  };

  const handleClientPageChange = (page) => {
    setClientPage(page);
  };

  const handleIncludeClientClick = (client) => {
    if (isClientExcluded(client.id)) {
      return;
    }

    if (hasAdjustmentForClient(client.id)) {
      return;
    }

    setClientAdjustments([
      ...clientAdjustments, {
        client: {
          id: client.id,
          accountNumber: client.accountNumber,
          name: client.name,
        },
        adjustBasePrice: false,
        adjustmentPercentage: '3.00',
      },
    ]);
  };

  const handleExcludeClientClick = (client) => {
    if (isClientExcluded(client.id)) {
      return;
    }

    if (hasAdjustmentForClient(client.id)) {
      return;
    }

    setClientExclusions([
      ...clientExclusions, {
        client: {
          id: client.id,
          accountNumber: client.accountNumber,
          name: client.name,
        },
      },
    ]);
  };

  const handleDeleteClientExclusion = (clientId) => {
    if (!clientExclusions.some((x) => x.client.id === clientId)) {
      return;
    }

    const filteredClientExclusions = clientExclusions.filter((x) => x.client.id !== clientId);

    setClientExclusions(filteredClientExclusions);
  };

  const handleDeleteClientAdjustment = (clientId) => {
    if (!hasAdjustmentForClient(clientId)) {
      return;
    }

    const filteredClientAdjustments = clientAdjustments.filter((x) => x.client.id !== clientId);

    setClientAdjustments(filteredClientAdjustments);
  };

  const handleClientAdjustmentPercentageChange = (clientId, value) => {
    const editedClientAdjustments = nestedCopy(clientAdjustments);

    const index = editedClientAdjustments.findIndex((x) => x.client.id === clientId);
    editedClientAdjustments[index].adjustmentPercentage = value;

    setClientAdjustments(editedClientAdjustments);
  };

  const handleSaveChangesClick = async () => {
    setSavingChanges(true);

    if (hasGlobalAdjustmentbeenChanged()) {
      await saveGlobalAdjustmentChanges();
      setSavedAdjustAllClients(adjustAllClients);
      setSavedGlobalClientAdjustment(globalClientAdjustment);
    }

    await saveClientAdjustmentChanges();

    if (adjustAllClients) {
      await saveClientExclusionChanges();
    }

    addToast(strings.toastMessages.successSaveChanges, {
      appearance: 'success',
      autoDismiss: true,
      pauseOnHover: false,
    });

    await delay(3000);
    refetchClientAdjustments();
    refetchClientExclusions();

    setSavingChanges(false);
  };

  const _haveChangesBeenMade = haveChangesBeenMade();
  const _haveInvalidChangesBeenMade = haveInvalidChangesBeenMade();

  return (
    <div>
      <Row>
        <Col xs={12}>
          {(_haveChangesBeenMade && _haveInvalidChangesBeenMade) && (
            <InvalidChangesErrorBox
              title={strings.invalidChangesAlert.title}
              message={strings.invalidChangesAlert.message}
            />
          )}

          {_haveChangesBeenMade && (
            <AdjustmentSaveChangesBox
              title={strings.saveChanges.title}
              message={strings.saveChanges.message}
              buttonText={strings.saveChanges.button}
              onSaveChangesClick={handleSaveChangesClick}
              disabled={savingChanges || _haveInvalidChangesBeenMade}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="pr-4">
          <div className="mb-2">
            <Input
              placeholder={strings.placeholders.search}
              value={search}
              onChange={onSearchChange}
            />
          </div>
          <ClientsTable
            headingStrings={{
              accountNumber: strings.clientTable.headings.accountNumber,
              name: strings.clientTable.headings.name,
            }}
            clients={clients.map((client) => ({
              id: client.id,
              accountNumber: client.accountNumber,
              name: client.name,
              excluded: isClientExcluded(client.id),
              hasAdjustment: hasAdjustmentForClient(client.id),
            }))}
            canExclude={adjustAllClients}
            onIncludeClick={handleIncludeClientClick}
            onExcludeClick={handleExcludeClientClick}
            loading={loadingClients}
          />

          <PageSelector
            currentPage={currentClientPage}
            maxPages={totalClientPages}
            onChange={handleClientPageChange}
          />
        </Col>
        <Col xs={12} md={6}>
          <GlobalClientAdjustmentSettingsBox
            applyToAll={adjustAllClients}
            adjustmentPercentage={globalClientAdjustment.adjustmentPercentage}
            onApplyToAllChange={handleAdjustAllClientsChange}
            onAdjustmentPercentageChange={handleAdjustmentPercentageChange}
            strings={{
              title: strings.globalClientAdjustment.title,
              applyToAll: strings.globalClientAdjustment.applyToAll,
              adjustmentPercentage: strings.globalClientAdjustment.adjustmentPercentage,
            }}
            editable={!savingChanges}
          />

          <h3>{strings.subHeadings.clientAdjustments}</h3>
          {loadingClientAdjustments && (
            <Text size="small">{strings.loadingMessages.loadingAdjustments}</Text>
          )}

          {!loadingClientAdjustments && (
            <>
              {clientAdjustments.length > 0 && (
                <ClientAdjustmentsTable
                  headingStrings={{
                    accountNumber: strings.clientAdjustmentTable.headings.accountNumber,
                    name: strings.clientAdjustmentTable.headings.name,
                    adjustmentPercentage: strings.clientAdjustmentTable.headings.adjustmentPercentage,
                  }}
                  clientAdjustments={clientAdjustments}
                  onDeleteClick={handleDeleteClientAdjustment}
                  onAdjustmentPercentageChange={handleClientAdjustmentPercentageChange}
                  editable={!savingChanges}
                  height="10em"
                  loading={loadingClientAdjustments}
                />
              )}

              {clientAdjustments.length === 0 && (
                <Text size="small">{strings.noRecords.noClientAdjustments}</Text>
              )}
            </>
          )}

          {adjustAllClients && (
            <>
              <Space height="3em" />

              <h3>{strings.subHeadings.clientExclusions}</h3>
              {loadingClientExclusions && (
                <Text size="small">{strings.loadingMessages.loadingExclusions}</Text>
              )}

              {!loadingClientExclusions && (
                <>
                  {clientExclusions.length > 0 && (
                    <ClientExclusionsTable
                      headingStrings={{
                        accountNumber: strings.clientExclusionTable.headings.accountNumber,
                        name: strings.clientExclusionTable.headings.name,
                      }}
                      clientExclusions={clientExclusions}
                      onDeleteClick={handleDeleteClientExclusion}
                      editable={!savingChanges}
                      loading={loadingClientExclusions}
                    />
                  )}

                  {clientExclusions.length === 0 && (
                    <Text size="small">{strings.noRecords.noClientExclusions}</Text>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PriceAdjustmentClientsTab;
