import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.module.css';

import Button from '../Button';

const PageSelector = ({
  maxPages,
  currentPage,
  onChange = () => {},
}) => {
  const getPageNumbers = () => {
    const delta = 2;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    let result = [];

    result = Array.from({ length: maxPages }, (v, k) => k + 1)
      .filter(i => i && i >= left && i < right);

    return result;
  };

  const handleClick = (page) => {
    onChange(page);
  };

  return (
    <div className={styles.default}>
      <ul className={styles.pagination}>
        {
          currentPage > 1 ?
            (
              <span>
                <li className={styles.listItem}>
                  <Button
                    disabled={currentPage === 1}
                    onClick={() => handleClick(1)}
                    classes={styles.link}
                  >
                    First
                  </Button>
                </li>
                <li className={styles.listItem}>
                  <Button
                    disabled={currentPage === 1}
                    onClick={() => handleClick(currentPage - 1)}
                    classes={styles.link}
                  >
                    «
                  </Button>
                </li>
              </span>
            ) : ''
        }
        {
          maxPages > 1 ?
            (
              getPageNumbers().map((page) => (
                page === currentPage ? (
                  <li
                    key={page}
                    className={classnames(styles.listItem, styles.selected)}
                  >
                    <Button classes={classnames(styles.selected, styles.link)} key={page}>{page}</Button>
                  </li>
                ) : (
                  <li
                    key={page}
                    className={styles.listItem}
                  >
                    <Button
                      key={page}
                      onClick={() => handleClick(page)}
                      classes={styles.link}
                    >
                      {page}
                    </Button>
                  </li>
                )
              ))
            ) : ''
        }
        {
          maxPages > 1 ?
            (
              <span>
                <li className={styles.listItem}>
                  <Button
                    disabled={currentPage === maxPages}
                    onClick={() => handleClick(currentPage + 1)}
                    classes={styles.link}
                  >
                    »
                  </Button>
                </li>
                <li className={styles.listItem}>
                  <Button
                    disabled={currentPage === maxPages}
                    onClick={() => handleClick(maxPages)}
                    classes={styles.link}
                  >
                    Last
                  </Button>
                </li>
              </span>
            ) : ''
        }
      </ul>
    </div>
  );
};

PageSelector.propTypes = {
  currentPage: PropTypes.number,
  maxPages: PropTypes.number,
  onChange: PropTypes.func,
};

export default PageSelector;
