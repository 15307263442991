export default {
  custom: 'Custom',
  today: 'Today',
  yesterday: 'Yesterday',
  thisWeek: 'This Week',
  thisMonth: 'This Month',
  past3Months: 'Past 3 Months',
  past6Months: 'Past 6 Months',
  pastYear: 'Past Year',
  allTime: 'All Time',
};
