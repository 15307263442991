import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Input from 'Common/components/Input';

import styles from './styles.module.css';

const Login = ({
  username,
  accountNumber,
  password,
  buttonText,
  inputErrors,
  onSubmit = () => {},
  onUsernameChange = () => {},
  onAccountNumberChange = () => {},
  onPasswordChange = () => {},
  onBack = () => {},
  noOfAccounts = 0,
  errorMessage = undefined,
}) => (
  <div>
    <div className={styles.formHeader}>
      <div>
        <h2>Login to our site</h2>
        <p>Enter your username and password to log on:</p>
      </div>
      <div>
        <i className="fa fa-key" />
      </div>
    </div>
    <div className={styles.formBody}>
      {errorMessage && (
        <p>{errorMessage}</p>
      )}

      <div>
        <Input
          className={classnames(
            styles.input,
            'mb-4',
          )}
          type="text"
          name="username"
          onChange={onUsernameChange}
          placeholder="Username..."
          disabled={noOfAccounts > 0}
          value={username}
        />

        {noOfAccounts > 1 && (
          <div>
            <Input
              className={classnames(
                styles.input,
                'mb-4',
              )}
              type="text"
              name="accountNumber"
              onChange={onAccountNumberChange}
              placeholder="Account Number..."
              value={accountNumber}
            />
          </div>
        )}

        {noOfAccounts > 0 && (
          <div>
            <Input
              className={classnames(
                styles.input,
                'mb-4',
              )}
              type="password"
              name="password"
              onChange={onPasswordChange}
              placeholder="Password..."
              value={password}
            />
          </div>
        )}

        {noOfAccounts > 0 && (
          <span
            className="glyphicons glyphicons-arrow-left mb-4"
            onClick={onBack}
            onKeyDown={onBack}
          />
        )}

        <div>
          <Button
            className={styles.button}
            onClick={onSubmit}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

Login.propTypes = {
  username: PropTypes.string.isRequired,
  accountNumber: PropTypes.string,
  password: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  noOfAccounts: PropTypes.number,
  errorMessage: PropTypes.string,
};

export default Login;
