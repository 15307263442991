import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from 'Common/components/Button';
import Text from 'Common/components/Text';

import styles from './styles.module.css';

const InfoBox = ({
  className,
  children,
  theme = "warning",
}) => (
  <div className={classnames(styles.container, styles[theme], className)}>
    {children}
  </div>
);

InfoBox.propTypes = {
};

InfoBox.defaultProps = {
};


export default InfoBox;
