import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import routeMappings from 'Admin/routeMappings';

import OptionGroup from './OptionGroup';

import styles from './styles.module.css';

const Sidebar = ({
  collapsed,
  optionGroups,
}) => {
  // eslint-disable-next-line arrow-body-style
  const groups = useMemo(() => optionGroups.map((group) => ({
    ...group,
    options: group.options.map((option) => ({
      ...option,
      redirect: routeMappings[option.page],
    })),
  })), [optionGroups]);

  return (
    <div
      className={classnames(
        styles.sidebar,
        (collapsed && styles.collapsed),
      )}
    >
      {groups.map((group) => (
        <OptionGroup
          key={group.heading}
          title={group.heading}
          options={group.options}
        />
      ))}
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  optionGroups: PropTypes.arrayOf(PropTypes.shape({
    heading: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      page: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
    })),
  })),
};

Sidebar.defaultProps = {
  collapsed: false,
  optionGroups: [],
};

export default memo(Sidebar);
