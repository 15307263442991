import React from 'react';
import PropTypes from 'prop-types';

import TreeNode from 'Common/components/TreeNode';

import styles from './styles.module.css';

const CategoryTree = ({
  categories,
  onClick,
  onAddClick,
  onDeleteClick,
}) => {
  const handleNodeClick = (id) => {
    onClick(id);
  };

  const handleAddClick = (id) => {
    console.log(id);
    onAddClick(id);
  };

  const handleDeleteClick = (id) => {
    onDeleteClick(id);
  };

  return (
    <div>
      {categories.map((category) => (
        <TreeNode
          key={category.id}
          data={category}
          onClick={handleNodeClick}
          onAddClick={handleAddClick}
          onDeleteClick={handleDeleteClick}
        />
      ))}

      <div
        className={styles.addContainer}
      >
        <span
          className={styles.addText}
          onClick={() => handleAddClick(null)}
        >
          +
        </span>
      </div>
    </div>
  );
};

CategoryTree.propTypes = {
  categories: PropTypes.array,
};


CategoryTree.defaultProps = {
  categories: [],
};

export default CategoryTree;
