import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './styles.module.css';

const Tabs = ({
  onTabClick,
  selectedTab,
  tabs,
  size,
}) => (
  <div className={styles.tabs}>
    {tabs.map((tab) => (
      <div
        className={classnames(
          styles.tab,
          selectedTab === tab.key && styles.active,
          styles[size],
        )}
        onClick={() => onTabClick(tab.key)}
      >
        {tab.title}
      </div>
    ))}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Tabs.defaultProps = {
  selectedTab: undefined,
  tabs: [],
  onTabClick: () => {},
  size: 'medium',
};

export default Tabs;
