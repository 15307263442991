import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { toClassname } from 'Common/utils/stylingUtils';

import styles from './styles.module.css';

const Text = ({
  theme = 'default',
  size = 'medium',
  bold = false,
  italic = false,
  className,
  onClick = () => {},
  children,
}) => (
  <span
    className={classnames(
      styles[theme],
      styles[toClassname(size)],
      bold && styles.bold,
      italic && styles.italic,
      className,
    )}
    onClick={onClick}
  >
    {children}
  </span>
);

Text.propTypes = {
  theme: PropTypes.oneOf(['default', 'alternative', 'light', 'success', 'error', 'black', 'white', 'faded']),
  size: PropTypes.oneOf(['xx-small', 'x-small', 'small', 'medium', 'large', 'x-large', 'xx-large']),
  bold: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Text;
