import React, { useState} from 'react';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import useAuth from 'Common/hooks/useAuth';

import Text from 'Common/components/Text';
import DateTimeInput from 'Common/components/DateTimeInput';
import Button from 'Common/components/Button';
import Checkbox from 'Common/components/Checkbox';

import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { isUndefined, isNull } from 'Common/utils/utils';
import { getDateTomorrow, getDateNextYear, getDate15MinsFromNow } from 'Common/utils/dateUtils';

const PriceAdjustmentScheduleTab = ({
  priceAdjustment,
  updatePriceAdjustment,
  strings: { schedule: strings, executionTypes: executionTypeStrings },
}) => {
  const history = useHistory();
  const { id } = useParams();
  const {
    tokens: { accessToken },
  } = useAuth();
  const { addToast } = useToasts();

  const [currentExecutionType, setCurrentExectionType] = useState(priceAdjustment.executionType);

  const [isPerformingAction, setIsPerformingAction] = useState(false);

  const getScheduleDate = () => {
    if (!isNull(priceAdjustment.scheduledDate)) {
      return Date.parse(priceAdjustment.scheduledDate);
    }
    const tomorrowMidnightDate = getDateTomorrow();
    tomorrowMidnightDate.setHours(0, 0, 0, 0);

    return tomorrowMidnightDate;
  };

  const [scheduledDate, setScheduledDate] = useState(getScheduleDate());

  const canContine = () => {
    if (currentExecutionType === priceAdjustmentExecutionType.immediate) {
      return true;
    }

    if (scheduledDate < getDate15MinsFromNow()) {
      return false;
    }

    return true;
  };

  const hasExectutionSettingsChanged = () => {
    if (priceAdjustment.executionType !== currentExecutionType) {
      return true;
    }
    if (currentExecutionType === priceAdjustmentExecutionType.scheduled && priceAdjustment.scheduledDate !== scheduledDate) {
      return true;
    }

    return false;
  };

  const handleNextClick = async () => {
    if (!hasExectutionSettingsChanged()) {
      history.push(`/admin/price-adjustment/${id}/review`);
      return;
    }

    setIsPerformingAction(true);

    try {
      if (currentExecutionType === priceAdjustmentExecutionType.scheduled) {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/schedule`, {
          executionDate: new Date(scheduledDate).toISOString(),
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/schedule/immediate`, {
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }

      updatePriceAdjustment({
        ...priceAdjustment,
        executionType: currentExecutionType,
      });

      const successMessage = (currentExecutionType === priceAdjustmentExecutionType.scheduled)
        ? strings.toastMessages.successfulSchedule
        : strings.toastMessages.successfulScheduleNow;

      addToast(successMessage, {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      setCurrentExectionType(priceAdjustment.executionType);

      const failedMessage = (currentExecutionType === priceAdjustmentExecutionType.scheduled)
        ? strings.toastMessages.failedSchedule
        : strings.toastMessages.failedScheduleNow;

      addToast(failedMessage, {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }

    setIsPerformingAction(false);

    history.push(`/admin/price-adjustment/${id}/review`);
  };

  const handleDateTimeChange = (e) => {
    setScheduledDate(e);
  };

  const handleBackClick = () => {
    history.push(`/admin/price-adjustment/${id}/clients`);
  };

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Row className="mb-4">
            <Col xs={12}>
              <h2>{strings.subTitle}</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={11} className="ml-5 mb-5" >
              <div>
                <Checkbox
                  checked={currentExecutionType === priceAdjustmentExecutionType.immediate}
                  label={executionTypeStrings.immediate}
                  onChange={() => setCurrentExectionType(priceAdjustmentExecutionType.immediate)}
                  checkable={currentExecutionType !== priceAdjustmentExecutionType.immediate}
                />
              </div>
              <div>
                <Checkbox
                  checked={currentExecutionType === priceAdjustmentExecutionType.scheduled}
                  label={executionTypeStrings.scheduled}
                  onChange={() => setCurrentExectionType(priceAdjustmentExecutionType.scheduled)}
                  checkable={currentExecutionType !== priceAdjustmentExecutionType.scheduled}
                />
              </div>
              {currentExecutionType === priceAdjustmentExecutionType.scheduled && (
                <>
                  <DateTimeInput
                    value={scheduledDate}
                    onChange={handleDateTimeChange}
                  />
                  {scheduledDate < getDate15MinsFromNow() && (
                    <div>
                      <Text theme="error" size="small" italic>
                        {strings.scheduleDateTooEarly}
                      </Text>
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div><Text bold italic theme="error">{'Notice: '}</Text></div>
              {currentExecutionType === priceAdjustmentExecutionType.immediate && (
                <Text italic>{strings.executionOptionDescriptions.immediately}</Text>
              )}

              {currentExecutionType === priceAdjustmentExecutionType.scheduled && (
                <>
                  <Text italic>{`${strings.executionOptionDescriptions.scheduledPreDate} `}</Text>
                  <div><Text bold italic>{`${scheduledDate.toString()}.`}</Text></div>
                  <div><Text italic>{strings.executionOptionDescriptions.scheduledPostDate}</Text></div>
                </>
              )}
            </Col>
          </Row>

          <Row className="align-content-right">
            <Col xs={12}>
              <Button
                className="mr-2"
                theme="default"
                onClick={handleBackClick}
                disabled={isPerformingAction}
              >
                {strings.backButton}
              </Button>
              <Button
                theme="action"
                onClick={handleNextClick}
                disabled={!canContine() || isPerformingAction}
              >
                {strings.nextButton}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PriceAdjustmentScheduleTab;
