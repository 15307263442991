const appReducer = (state, action) => {
  switch (action.type) {
    // Sidebar
    case 'toggle_sidebar':
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };

    default:
      return state;
  }
};

export default appReducer;
