import styled from 'styled-components';

export default styled.div`
  width: 100%;

  ${props => `
    margin-bottom: ${props.height}
    margin-right: ${props.width}
  `}
`;
