import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';

import { isNullOrEmpty } from 'Common/utils/utils';

import editIcon from 'Common/assets/icons/edit.png';
import deleteIcon from 'Common/assets/icons/trash.png';
import replaceIcon from 'Common/assets/icons/trash.png';

import styles from './styles.module.css';

const AccountManagersTable = ({
  headingStrings,
  actionStrings,
  accountManagers,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  onReplaceClick,
  onEditClick,
  onDeleteClick,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.name,
      identifier: 'name',
      width: '15%',
      sortable: true,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: '17%',
      sortable: true,
    },
    {
      heading: headingStrings.emailAddress,
      identifier: 'emailAddress',
      width: '17%',
      sortable: true,
    },
    {
      heading: headingStrings.telephone,
      identifier: 'telephone',
      width: '13%',
      sortable: true,
    },
    {
      heading: headingStrings.mobile,
      identifier: 'mobile',
      width: '12%',
      sortable: true,
    },
    {
      heading: headingStrings.clients,
      identifier: 'clients',
      width: '5%',
      sortable: true,
    },
    {
      heading: headingStrings.branches,
      identifier: 'branches',
      width: '5%',
      sortable: true,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
    {
      heading: '',
      identifier: '',
      width: undefined,
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={accountManagers.map((accountManager) => ({
        identifier: accountManager.id,
        data: [
          `${accountManager.firstName} ${accountManager.lastName}`,
          accountManager.title,
          !isNullOrEmpty(accountManager.emailAddress) ? accountManager.emailAddress : '---------------------------------',
          !isNullOrEmpty(accountManager.telephone) ? accountManager.telephone : '----------------',
          !isNullOrEmpty(accountManager.mobile) ? accountManager.mobile : '----------------',
          accountManager.clients.count,
          accountManager.branches.count,
          '',
          <>
            <span
              className={styles.action}
              onClick={() => onReplaceClick(accountManager.id)}
            >
              <img className={styles.icon} src={replaceIcon} alt="replace" />
              {actionStrings.replace}
            </span>
          </>,
          <>
            <span
              className={styles.action}
              onClick={() => onEditClick(accountManager.id)}
            >
              <img className={styles.icon} src={editIcon} alt="edit" />
              {actionStrings.edit}
            </span>
          </>,
          <>
            <span
              className={styles.action}
              onClick={() => onDeleteClick(accountManager.id)}
            >
              <img className={styles.icon} src={deleteIcon} alt="delete" />
              {actionStrings.delete}
            </span>
          </>,
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
    />
  );
};

AccountManagersTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    name: PropTypes.string,
    title: PropTypes.string,
    emailAddress: PropTypes.string,
    telephone: PropTypes.string,
    mobile: PropTypes.string,
    clients: PropTypes.string,
  }),
  actionStrings: PropTypes.objectOf({
    edit: PropTypes.string,
    delete: PropTypes.string,
    replace: PropTypes.string,
  }),
  accountManagers: PropTypes.arrayOf(PropTypes.shape({
  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

AccountManagersTable.defaultProps = {
  headingStrings: undefined,
  accountManagers: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default AccountManagersTable;
