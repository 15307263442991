const branchReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    // Fetch Branch
    case 'fetch_branch_requested':
      return {
        ...state,
        loading: true,
      };
    case 'fetch_branch_successful':
      return {
        ...state,
        loading: false,
        branch: payload.branch,
      };
    case 'login_branch_failed':
      return {
        ...state,
        loading: false,
        branch: undefined,
      };
    default:
      return state;
  }
};

export default branchReducer;
