import React from 'react';
import PropTypes from 'prop-types';

import Space from 'Common/components/Space';

import styles from './styles.module.css';
import { isUndefined } from 'Common/utils/utils';

const AccountMangerInfo = ({
  accountManager,
  noOfClients = undefined,
  noOfBranches = undefined,
}) => (
  <div>
    {accountManager && (
      <div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>Name</div>
          <div className={styles.fieldValue}>{accountManager.firstName} {accountManager.lastName}</div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>Title</div>
          <div className={styles.fieldValue}>{accountManager.title}</div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>Email</div>
          <div className={styles.fieldValue}>{accountManager.emailAddress}</div>
        </div>

        {accountManager.telephone && (
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>Telephone</div>
            <div className={styles.fieldValue}>{accountManager.telephone}</div>
          </div>
        )}

        {accountManager.mobile && (
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>Telephone</div>
            <div className={styles.fieldValue}>{accountManager.mobile}</div>
          </div>
        )}

        <Space height="30px" />

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>Clients</div>
          <div className={styles.fieldValue}>
            {accountManager.clients.count}
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>Branches</div>
          <div className={styles.fieldValue}>
            {accountManager.branches.count}
          </div>
        </div>
      </div>
    )}
  </div>
);

AccountMangerInfo.propTypes = {
  children: PropTypes.node,
};

export default AccountMangerInfo;
