import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from 'Common/hooks/useAuth';

export default (WrappedComponent) => {
 return () => {
    const { loggedIn } = useAuth();

    if (loggedIn) {
      return <Redirect to="/" />
    }

    return <WrappedComponent />
  };
};
