import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Text from 'Common/components/Text';
import PriceAdjustmentStatusTag from 'Admin/components/PriceAdjustments/PriceAdjustmentStatusTag';

import styles from './styles.module.css';

const PriceAdjustmentInfoBox = ({
  id,
  state,
  stateText,
  scheduledText,
  executionText,
}) => (
  <div className="align-content-right">
    <div>
      <Text size="small">{id}</Text>
    </div>
    <div className="my-2">
      <PriceAdjustmentStatusTag
        status={state}
        statusText={stateText}
      />
    </div>
    <div>
      <Text size="small" bold>{`${scheduledText}:`}</Text>
      <Text size="small">
        {' '}{executionText}
      </Text>
    </div>
  </div>
);

PriceAdjustmentInfoBox.propTypes = {
};

PriceAdjustmentInfoBox.defaultProps = {
};


export default PriceAdjustmentInfoBox;
