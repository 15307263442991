import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Row, Col } from 'react-bootstrap';

import usePagedFetch from 'Common/hooks/usePagedFetch';

import PageSelector from 'Common/components/PageSelector';
import Text from 'Common/components/Text';
import Space from 'Common/components/Space';
import ProgressBar from 'Common/components/ProgressBar';
import HorizontalRule from 'Common/components/HorizontalRule';
import ItemAdjustmentsSummaryTable from 'Admin/components/PriceAdjustments/ItemAdjustmentsSummaryTable';
import ClientItemAdjustmentsSummaryTable from 'Admin/components/PriceAdjustments/ClientItemAdjustmentsSummaryTable';
import PriceAdjustmentStatusTag from 'Admin/components/PriceAdjustments/PriceAdjustmentStatusTag';
import GlobalItemAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalItemAdjustmentSettingsBox';
import ItemExclusionsTable from 'Admin/components/PriceAdjustments/ItemExclusionsTable';
import ItemAdjustmentsTable from 'Admin/components/PriceAdjustments/ItemAdjustmentsTable';
import GlobalClientAdjustmentSettingsBox from 'Admin/components/PriceAdjustments/GlobalClientAdjustmentSettingsBox';
import ClientExclusionsTable from 'Admin/components/PriceAdjustments/ClientExclusionsTable';
import ClientAdjustmentsTable from 'Admin/components/PriceAdjustments/ClientAdjustmentsTable';

import priceAdjustmentExecutionType from 'Admin/constants/priceAdjustmentExecutionType';

import { toLocalDateTime, formatElapseTime } from 'Common/utils/dateUtils';
import { isEmptyOrWhiteSpace } from 'Common/utils/stringUtils';

const PriceAdjustmentSummaryTab = ({
  priceAdjustment,
  strings: { summary: strings, executionTypes: executionTypeStrings },
}) => {
  const history = useHistory();
  const { id } = useParams();
  const { addToast } = useToasts();

  const { results: clientAdjustments, loading: loadingClientAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/clients?pageSize=1000`);

  const { results: itemExclusions, loading: loadingItemExclusions } = usePagedFetch(priceAdjustment.adjustAllItems
    ? `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/exclusions/items?pageSize=1000`
    : '');

  const { results: clientExclusions, loading: loadingClientExclusions } = usePagedFetch(priceAdjustment.adjustAllClients
    ? `${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/exclusions/clients?pageSize=1000`
    : '');

  const [itemAdjustmentsPage, setItemPriceAdjustmentsPage] = useState(1);
  const [clientItemPriceAdjustmentsPage, setClientItemPriceAdjustmentsPage] = useState(1);

  const { results: itemAdjustments, loading: loadingItemAdjustments, totalRecords: totalItemAdjustments, currentPage: currentItemAdjustmentPage, totalPages: totalCurrentItemAdjustmentPages, refetch: refetchItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/items?pageSize=100&page=${itemAdjustmentsPage}`);
  const { results: clientItemAdjustments, loading: loadingClientItemAdjustments, totalRecords: totalClientItemAdjustments, currentPage: currentClientItemAdjustmentPage, totalPages: totalCurrentClientItemAdjustmentPages, refetch: refetchClientItemAdjustments } = usePagedFetch(`${process.env.REACT_APP_API_URL}/v1.0/price-adjustments/${priceAdjustment.id}/client-items?pageSize=100&page=${clientItemPriceAdjustmentsPage}`);

  const handleItemAdjustmentPageChange = (pageNumber) => {
    setItemPriceAdjustmentsPage(pageNumber);
  };

  const handleClientItemAdjustmentPageChange = (pageNumber) => {
    setClientItemPriceAdjustmentsPage(pageNumber);
  };

  return (
    <div>
      <Row className="justify-content-md-center">
        <Col xs={6}>

          <Row>
            <Col xs={9}>
              <Text size="x-large" theme="black">{priceAdjustment.title}</Text>
              <div className="my-4">
                <Text size="x-small">{id}</Text>
              </div>
            </Col>
            <Col xs={3} className="align-content-right">
              <PriceAdjustmentStatusTag
                status={priceAdjustment.state}
                statusText={priceAdjustment.state}
              />
            </Col>
          </Row>

          {!isEmptyOrWhiteSpace(priceAdjustment.reason) && (
            <Row>
              <Col>
                <div><Text size="small" theme="black" bold>{strings.labels.reason}</Text></div>
                <Text size="small">{priceAdjustment.reason}</Text>
              </Col>
            </Row>
          )}

          <Space height="2em" />

          <Row>
            <Col>
              <Text className="mr-2" theme="black" size="small" bold>{`${strings.labels.executionType}:`}</Text>
              <Text size="small">
                {(priceAdjustment.executionType === priceAdjustmentExecutionType.immediate)
                  ? executionTypeStrings.immediate
                  : `${strings.labels.scheduledFor} ${toLocalDateTime(priceAdjustment.scheduledDate)}`}
              </Text>
            </Col>
          </Row>

          <Space height="2em" />

          <Row>
            <Col xs={6} className="text-align-center">
              <Text size="small" theme="black" bold className="mr-3">{strings.labels.dateExecuted}</Text>
              <Text size="small">{toLocalDateTime(new Date(priceAdjustment.dateExecuted))}</Text>
            </Col>
            <Col xs={6} className="text-align-center">
              <Text size="small" theme="black" bold className="mr-3">{strings.labels.dateCompleted}</Text>
              <Text size="small">{toLocalDateTime(new Date(priceAdjustment.dateCompleted))}</Text>
            </Col>
          </Row>

          <Space height="0.5em" />

          <Row>
            <Col className="text-align-center">
              <Text size="small" theme="black" bold className="mr-3">{strings.labels.elapseTime}</Text>
              <Text size="small">{formatElapseTime(new Date(priceAdjustment.dateCompleted) - new Date(priceAdjustment.dateExecuted))}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Space height="2em" />

      <Row className="justify-content-md-center">
        <Col xs={6} className="text-align-center">
          <Text
            size="small"
          >
            {`${priceAdjustment.percentageComplete}%`}
          </Text>
          <ProgressBar
            percentage={priceAdjustment.percentageComplete}
            theme="green"
          />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={6} className="text-align-center">
          <Space height="4em" />
          <h2>{strings.subHeadings.itemAdjustments}</h2>
          <HorizontalRule />
          <Space height="1em" />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={8}>
          {totalItemAdjustments > 0 && (
            <>
              <Row className="mb-2">
                <Col>
                  <h2>{strings.subHeadings.itemAdjustments}</h2>
                </Col>
              </Row>

              <Row>
                <Col>
                  <ItemAdjustmentsSummaryTable
                    headingStrings={{
                      productCode: strings.itemAdjustmentTable.headings.productCode,
                      title: strings.itemAdjustmentTable.headings.title,
                      listPrice: strings.itemAdjustmentTable.headings.listPrice,
                      adjustBasePrice: strings.itemAdjustmentTable.headings.adjustBasePrice,
                      adjustmentPercentage: strings.itemAdjustmentTable.headings.adjustmentPercentage,
                      completed: strings.itemAdjustmentTable.headings.completed,
                    }}
                    itemAdjustments={itemAdjustments}
                    loading={loadingItemAdjustments}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageSelector
                    currentPage={currentItemAdjustmentPage}
                    maxPages={totalCurrentItemAdjustmentPages}
                    onChange={handleItemAdjustmentPageChange}
                  />
                </Col>
              </Row>

              <Space height="3em" />
            </>
          )}

          {totalClientItemAdjustments > 0 && (
            <>
              <Row className="mb-2">
                <Col>
                  <h2>{strings.subHeadings.clientItemAdjustments}</h2>
                </Col>
              </Row>

              <Row>
                <Col>
                  <ClientItemAdjustmentsSummaryTable
                    headingStrings={{
                      accountNumber: strings.clientItemAdjustmentTable.headings.accountNumber,
                      name: strings.clientItemAdjustmentTable.headings.clientName,
                      productCode: strings.clientItemAdjustmentTable.headings.productCode,
                      title: strings.clientItemAdjustmentTable.headings.itemTitle,
                      listPrice: strings.clientItemAdjustmentTable.headings.listPrice,
                      adjustBasePrice: strings.clientItemAdjustmentTable.headings.adjustBasePrice,
                      adjustmentPercentage: strings.clientItemAdjustmentTable.headings.adjustmentPercentage,
                      completed: strings.clientItemAdjustmentTable.headings.completed,
                    }}
                    clientItemAdjustments={clientItemAdjustments}
                    loading={loadingClientItemAdjustments}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <PageSelector
                    currentPage={currentClientItemAdjustmentPage}
                    maxPages={totalCurrentClientItemAdjustmentPages}
                    onChange={handleClientItemAdjustmentPageChange}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={6} className="text-align-center">
          <Space height="4em" />
          <h2>{strings.subHeadings.configuration}</h2>
          <HorizontalRule />
          <Space height="1em" />
        </Col>
      </Row>

      <Row className="justify-content-md-center">
        <Col xs={6}>
          <Row>
            <Col>
              <h2>{strings.subHeadings.itemAdjustments}</h2>

              {priceAdjustment.adjustAllItems && (
                <>
                  <GlobalItemAdjustmentSettingsBox
                    applyToAll={priceAdjustment.adjustAllItems}
                    adjustBasePrice={priceAdjustment.globalItemAdjustment.adjustBasePrice}
                    adjustmentPercentage={priceAdjustment.globalItemAdjustment.adjustmentPercentage}
                    strings={{
                      title: strings.globalItemAdjustment.title,
                      applyToAll: strings.globalItemAdjustment.applyToAll,
                      adjustBasePrice: strings.globalItemAdjustment.adjustBasePrice,
                      adjustmentPercentage: strings.globalItemAdjustment.adjustmentPercentage,
                    }}
                    editable={false}
                  />

                  <Space height="3em" />
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              {priceAdjustment.adjustAllItems && (
                <>
                  <h3>{strings.subHeadings.itemExclusions}</h3>
                  {loadingItemExclusions && (
                    <Text size="small">{strings.loadingMessages.loadingExclusions}</Text>
                  )}

                  {!loadingItemExclusions && (
                    <>
                      {itemExclusions.length > 0 && (
                        <ItemExclusionsTable
                          headingStrings={{
                            productCode: strings.itemExclusionTable.headings.productCode,
                            title: strings.itemExclusionTable.headings.title,
                            listPrice: strings.itemExclusionTable.headings.listPrice,
                          }}
                          itemExclusions={itemExclusions}
                          editable={false}
                        />
                      )}

                      {itemExclusions.length === 0 && (
                        <Text size="small">{strings.noRecords.itemExclusions}</Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Space height="5em" />

          <Row>
            <Col>
              <h2>{strings.subHeadings.clientAdjustments}</h2>

              {priceAdjustment.adjustAllClients && (
                <>
                  <GlobalClientAdjustmentSettingsBox
                    applyToAll={priceAdjustment.adjustAllClients}
                    adjustmentPercentage={priceAdjustment.globalClientAdjustment.adjustmentPercentage}
                    strings={{
                      title: strings.globalClientAdjustment.title,
                      applyToAll: strings.globalClientAdjustment.applyToAll,
                      adjustmentPercentage: strings.globalClientAdjustment.adjustmentPercentage,
                    }}
                    editable={false}
                  />

                  <Space height="3em" />
                </>
              )}

              <h3>{strings.subHeadings.clientAdjustments}</h3>
              {loadingClientAdjustments && (
                <Text size="small">{strings.loadingMessages.loadingAdjustments}</Text>
              )}

              {!loadingClientAdjustments && (
                <>
                  {clientAdjustments.length > 0 && (
                    <ClientAdjustmentsTable
                      headingStrings={{
                        accountNumber: strings.clientAdjustmentTable.headings.accountNumber,
                        name: strings.clientAdjustmentTable.headings.name,
                        adjustmentPercentage: strings.clientAdjustmentTable.headings.adjustmentPercentage,
                      }}
                      clientAdjustments={clientAdjustments}
                      editable={false}
                    />
                  )}

                  {clientAdjustments.length === 0 && (
                    <Text size="small">{strings.noRecords.noClientAdjustments}</Text>
                  )}
                </>
              )}
            </Col>
          </Row>

          <Row>
            <Col xs={8}>
              {priceAdjustment.adjustAllClients && (
                <>
                  <Space height="3em" />

                  <h3>{strings.subHeadings.clientExclusions}</h3>
                  {loadingClientExclusions && (
                    <Text size="small">{strings.loadingMessages.itemExclusions}</Text>
                  )}

                  {!loadingClientExclusions && (
                    <>
                      {clientExclusions.length > 0 && (
                        <ClientExclusionsTable
                          headingStrings={{
                            accountNumber: strings.clientExclusionTable.headings.accountNumber,
                            name: strings.clientExclusionTable.headings.name,
                          }}
                          clientExclusions={clientExclusions}
                          editable={false}
                        />
                      )}

                      {clientExclusions.length === 0 && (
                        <Text size="small">{strings.noRecords.noClientExclusions}</Text>
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PriceAdjustmentSummaryTab;
