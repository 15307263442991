import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Input from 'Common/components/Input';
import Text from 'Common/components/Text';
import Button from 'Common/components/Button';
import Space from 'Common/components/Space';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const AccountManagerCreationForm = ({
  open,
  onRequestClose,
  onEditClick,
  inputErrors,
  errorMessage,
  id,
  firstname,
  lastname,
  title,
  emailAddress,
  telephone,
  mobile,
  onFirstnameChange,
  onLastnameChange,
  onTitleChange,
  onEmailAddressChange,
  onTelephoneChange,
  onMobileChange,
}) => (
  <Modal
    isOpen={open}
    contentLabel="Account Manager Editing"
    onRequestClose={onRequestClose}
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
    <Text
      bold
      size="xxlarge"
    >
      Edit Account Manager
    </Text>

    <Space height="1em" />

    <div>
      <span>{inputErrors.firstname}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Id
      </span>
      <span
        className={styles.input}
      >
        {id}
      </span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Firstname
      </span>
      <Input
        className={styles.input}
        name="firstname"
        state={inputErrors.firstname ? 'issue' : 'none'}
        value={firstname}
        onChange={onFirstnameChange}
      />
    </div>

    <div>
      <span>{inputErrors.lastname}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Lastname
      </span>
      <Input
        className={styles.input}
        name="lastname"
        state={inputErrors.lastname ? 'issue' : 'none'}
        value={lastname}
        onChange={onLastnameChange}
      />
    </div>

    <div>
      <span>{inputErrors.title}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Title
      </span>
      <Input
        className={styles.input}
        name="title"
        state={inputErrors.title ? 'issue' : 'none'}
        value={title}
        onChange={onTitleChange}
      />
    </div>

    <div>
      <span>{inputErrors.emailAddress}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Email
      </span>
      <Input
        className={styles.input}
        name="emailAddress"
        state={inputErrors.emailAddress ? 'issue' : 'none'}
        value={emailAddress}
        onChange={onEmailAddressChange}
      />
    </div>

    <div>
      <span>{inputErrors.telephone}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Telephone
      </span>
      <Input
        className={styles.input}
        name="telephone"
        state={inputErrors.telephone ? 'issue' : 'none'}
        value={telephone}
        onChange={onTelephoneChange}
      />
    </div>

    <div>
      <span>{inputErrors.mobile}</span>
    </div>

    <div className={styles.inputContainer}>
      <span
        className={styles.label}
      >
        Mobile
      </span>
      <Input
        className={styles.input}
        name="mobile"
        state={inputErrors.mobile ? 'issue' : 'none'}
        value={mobile}
        onChange={onMobileChange}
      />
    </div>

    <Space height="1em" />

    {!isUndefined(errorMessage) && (
      <Text>{errorMessage}</Text>
    )}

    <div className={styles.buttonContainer}>
      <Button
        theme="action"
        onClick={onEditClick}
      >
        Submit
      </Button>
    </div>
  </Modal>
);

AccountManagerCreationForm.defaultProp = {
  onRequestClose: () => {},
};

AccountManagerCreationForm.propTypes = {

};

export default AccountManagerCreationForm;
