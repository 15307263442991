import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Table from 'Common/components/Table';
import Link from 'Common/components/Link';
import PriceAdjustmentStatusTag from 'Admin/components/PriceAdjustments/PriceAdjustmentStatusTag';

import { toLocalDateTime, formatElapseTime } from 'Common/utils/dateUtils';

import styles from './styles.module.css';

const HistoricalPriceAdjustmentsTable = ({
  headingStrings,
  priceAdjustments,
  loading,
  sortBy,
  sortDescending,
  onHeadingClick,
  onRowClick,
  skeletonLoadingRows,
}) => {
  const columns = useMemo(() => ([
    {
      heading: headingStrings.id,
      identifier: 'id',
      width: '20%',
      sortable: false,
    },
    {
      heading: headingStrings.title,
      identifier: 'title',
      width: '20%',
      sortable: false,
    },
    {
      heading: headingStrings.type,
      identifier: 'type',
      width: '16%',
      sortable: false,
    },
    {
      heading: headingStrings.status,
      identifier: 'status',
      width: '8%',
      sortable: false,
    },
    {
      heading: headingStrings.issues,
      identifier: 'issues',
      width: '8%',
      sortable: false,
    },
    {
      heading: headingStrings.startTime,
      identifier: 'startTime',
      width: '10%',
      sortable: false,
    },
    {
      heading: headingStrings.completionTime,
      identifier: 'completionTime',
      width: '10%',
      sortable: false,
    },
    {
      heading: headingStrings.elapseTime,
      identifier: 'elapseTime',
      width: '8%',
      sortable: false,
    },
  ]), [headingStrings]);

  return (
    <Table
      columns={columns}
      rows={priceAdjustments.map((priceAdjustment) => ({
        identifier: priceAdjustment.id,
        data: [
          <Link
            to={`/admin/price-adjustment/${priceAdjustment.id}/summary`}
            className={styles.link}
          >
            {priceAdjustment.id}
          </Link>,
          priceAdjustment.title,
          priceAdjustment.type,
          <PriceAdjustmentStatusTag
            status={priceAdjustment.status}
            statusText={priceAdjustment.status}
          />,
          priceAdjustment.issues,
          toLocalDateTime(priceAdjustment.startTime),
          toLocalDateTime(priceAdjustment.completionTime),
          formatElapseTime(priceAdjustment.elapseTime),
        ],
      }))}
      loading={loading}
      sortBy={sortBy}
      sortDescending={sortDescending}
      onHeadingClick={onHeadingClick}
      onRowClick={onRowClick}
      theme="light"
      className={styles.table}
      skeletonLoadingRows={skeletonLoadingRows}
    />
  );
};

HistoricalPriceAdjustmentsTable.propTypes = {
  headingStrings: PropTypes.objectOf({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    issues: PropTypes.string,
    startTime: PropTypes.string,
    completionTime: PropTypes.string,
    elapseTime: PropTypes.string,
  }),
  priceAdjustments: PropTypes.arrayOf(PropTypes.shape({

  })),
  loading: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDescending: PropTypes.bool,
  onHeadingClick: PropTypes.func,
  onRowClick: PropTypes.func,
};

HistoricalPriceAdjustmentsTable.defaultProps = {
  headingStrings: undefined,
  priceAdjustments: [],
  loading: false,
  sortBy: undefined,
  sortDescending: false,
  onHeadingClick: () => {},
  onRowClick: () => {},
};

export default HistoricalPriceAdjustmentsTable;
