import React, {
  createElement, createContext, useReducer, useCallback,
} from 'react';
import PropTypes from 'prop-types';

import accountManagersReducer from '../reducers/accountManagersReducer';

export const AccountManagersContext = createContext();

const initialState = {
  loading: false,
  accountManagers: [],
  deleting: false,
  replacing: false,
};

export const AccountManagersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountManagersReducer, initialState);

  return (
    <AccountManagersContext.Provider
      value={{
        state,
        dispatch: useCallback(
          (...args) => {
            dispatch(...args);
          },
          [dispatch],
        ),
      }}
    >
      {children}
    </AccountManagersContext.Provider>
  );
};

AccountManagersProvider.propTypes = {
  children: PropTypes.node,
};

AccountManagersProvider.defaultProps = {
  children: createElement('div'),
};

export const AccountManagersConsumer = AccountManagersContext.Consumer;
