import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const PageTitle = ({
  children,
}) => (
  <h1 className={styles.h1}>{children}</h1>
);

PageTitle.propTypes = {
  children: PropTypes.node,
};

export default PageTitle;
