import React from 'react';

import Space from 'Common/components/Space';
import Panel from 'Admin/components/Panel';
import Text from 'Common/components/Text';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const AccountDetails = ({
  user,
  branch,
  client,
}) => (
  <Panel
    className={styles.container}
    theme="grey"
    height="50em"
  >
    {!isUndefined(user) && (
      <>
        <div>
          <Text
            bold
            size="x-large"
          >
            {user.firstName} {user.lastName}
          </Text>
        </div>
        <div>
          <Text>Administrator</Text>
        </div>
      </>
    )}

    <Space height="2vh" />

    {!isUndefined(user) && (
      <div>
        <dl className={styles.dataList}>
          <dt><Text size="small">Username</Text></dt>
          <dd><Text size="small">{user.username}</Text></dd>

          <dt><Text size="small">Email</Text></dt>
          <dd><Text size="small">{user.emailAddress}</Text></dd>

          <dt><Text size="small">Mobile</Text></dt>
          <dd><Text size="small">{user.mobile}</Text></dd>

          <dt><Text size="small">Last Logged In</Text></dt>
          <dd><Text size="small">{user.LastLoginDate}</Text></dd>

          <dt><Text size="small">Status</Text></dt>
          <dd><Text size="small">{user.status}</Text></dd>
        </dl>
      </div>
    )}

    <Space height="5vh" />

    {!isUndefined(client) && (
      <div>
        <Text bold size="x-large">Client</Text>

        <dl className={styles.dataList}>
          <dt><Text size="small">Name</Text></dt>
          <dd><Text size="small">{client.name}</Text></dd>

          <dt><Text size="small">Account Number</Text></dt>
          <dd><Text size="small">{client.accountNumber}</Text></dd>

          <dt><Text size="small">Status</Text></dt>
          <dd><Text size="small">{client.status}</Text></dd>
        </dl>
      </div>
    )}

    <Space height="5vh" />

    {!isUndefined(branch) && (
      <div>
        <Text bold size="x-large">Branch</Text>

        <dl className={styles.dataList}>
          <dt><Text size="small">Name</Text></dt>
          <dd><Text size="small">{branch.name}</Text></dd>

          <dt><Text size="small">Account Number</Text></dt>
          <dd><Text size="small">{branch.accountNumber}</Text></dd>

          <dt><Text size="small">Status</Text></dt>
          <dd><Text size="small">{branch.status}</Text></dd>
        </dl>
      </div>
    )}
  </Panel>
);

AccountDetails.propTypes = {

};

AccountDetails.defaultProps = {

};

export default AccountDetails;
