import React, { useState, useEffect, useCallback } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { Row, Col } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { confirmAlert } from 'react-confirm-alert';

import PageTitle from 'Common/components/PageTitle';
import Paragraph from 'Common/components/Paragraph';
import FiltersBox from 'Common/components/FiltersBox';
import Button from 'Common/components/Button';
import Input from 'Common/components/Input';
import Space from 'Common/components/Space';
import AccountManagersTable from 'Admin/components/AccountManagersTable';
import PageSelector from 'Common/components/PageSelector';
import PageSizeSelector from 'Common/components/PageSizeSelector';
import LoadingIcon from 'Common/components/LoadingIcon';

import AccountManagerCreationFormContainer from 'Admin/containers/AccountManagerCreationFormContainer';
import AccountManagerEditingFormContainer from 'Admin/containers/AccountManagerEditingFormContainer';
import AccountManagerReplacementFormContainer from 'Admin/containers/AccountManagerReplacementFormContainer';

import useStrings from 'Admin/hooks/useStrings';
import useUser from 'Common/hooks/useUser';
import usePagedFetch from 'Common/hooks/usePagedFetch';
import useAuth from 'Common/hooks/useAuth';
import usePageSizeSelector from 'Common/hooks/usePageSizeSelector';
import useAccountManagers from 'Admin/hooks/useAccountManagers';

import { isUndefined, isNull } from 'Common/utils/utils';

const defaultAccountManagerFilters = {
  page: 1,
  search: '',
  sortBy: 'name',
  sortDescending: true,
};

const AccountManagersPage = () => {
  const { addToast } = useToasts();

  const { accountManagers: { strings, loading: loadingStrings }, fetchAccountManagersStrings } = useStrings();
  const { user } = useUser();
  const { tokens: { accessToken }} = useAuth();

  const { delete: deleteAccountManager } = useAccountManagers();

  const [accountManagerFilters, setAccountManagerFilters] = useState(defaultAccountManagerFilters);
  const [accountManagersLink, setAccountManagersLink] = useState(null);
  const { results: accountManagers, loading: loadingAccountManagers, currentPage, totalPages, totalResults, refetch: refetchAccountManagers } = usePagedFetch(accountManagersLink);

  const [selectedValues, setSelectedValues] = useState(defaultAccountManagerFilters);
  const { pageSizes, pageSize, setPageSize } = usePageSizeSelector([10, 25, 50, 75, 100], 25);

  const [creationFormOpen, setCreationFormOpen] = useState(false);
  const [editingFormOpen, setEditingFormOpen] = useState(false);
  const [replacementFormOpen, setReplacementFormOpen] = useState(false);

  const [accountManagerReplacing, setAccountManagerReplacing] = useState(undefined);
  const [accountManagerEditing, setAccountManagerEditing] = useState(undefined);

  // Functions
  const getQueryParameters = useCallback(() => {
    const params = {
      page: accountManagerFilters.page,
      pageSize,
      search: accountManagerFilters.search,
    };

    if (!isUndefined(accountManagerFilters.sortBy)) {
      params.sortBy = accountManagerFilters.sortBy;
      params.sortDesc = accountManagerFilters.sortDescending;
    }

    return params;
  }, [accountManagerFilters, pageSize]);

  const updateAccountManagersLink = useCallback(() => {
    const url = new URL(`${process.env.REACT_APP_API_URL}/v1.0/account-managers`);
    const params = getQueryParameters();
    url.search = new URLSearchParams(params);
    setAccountManagersLink(url.href);
  }, [getQueryParameters]);

  const removeAccountManager = async (id) => {
    try {
      const accountManager = accountManagers.find((x) => x.id === id);
      await deleteAccountManager(accountManager.links.find((x) => x.rel === 'delete').href);
      // TODO: Remove Account Manager from store
      refetchAccountManagers();

      addToast('Account Manager Successfully Deleted', {
        appearance: 'success',
        autoDismiss: true,
        pauseOnHover: false,
      });
    } catch (e) {
      addToast('Something went wrong when trying to delete account manager', {
        appearance: 'error',
        autoDismiss: true,
        pauseOnHover: false,
      });
    }
  };

  const closeModals = () => {
    setCreationFormOpen(false);
    setEditingFormOpen(false);
    setReplacementFormOpen(false);
  };

  // Handlers
  const handleSearchChange = (search) => {
    setSelectedValues({ ...selectedValues, search });
  };

  const handleAccountManagerDeleteClick = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this account manager?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => removeAccountManager(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const handleApplySearchClick = () => {
    setAccountManagerFilters({
      ...accountManagerFilters,
      search: selectedValues.search,
      page: 1,
    });
  };

  const handlePageChange = (pageNumber) => {
    setSelectedValues({ ...selectedValues, page: pageNumber });
    setAccountManagerFilters({ ...accountManagerFilters, page: pageNumber });
  };

  const handleResetFiltersClick = () => {
    setAccountManagerFilters(defaultAccountManagerFilters);
  };

  const handleTableHeadingClick = (column) => {
    // TODO: Check this is correct. If new column does it reset?
    const sortDescending = (selectedValues.sortBy === column) ? !selectedValues.sortDescending : selectedValues.sortDescending;

    setSelectedValues({
      ...selectedValues,
      sortBy: column,
      sortDescending,
      page: 1,
    });
    setAccountManagerFilters({
      ...accountManagerFilters,
      sortBy: column,
      sortDescending,
      page: 1,
    });
  };

  const handleAddAccountManagerClick = () => {
    setCreationFormOpen(true);
  };

  const handleEditAccountManagerClick = (id) => {
    const accountManager = accountManagers.find((x) => x.id === id);
    setAccountManagerEditing(accountManager);
    setEditingFormOpen(true);
  };

  const handleReplaceAccountManagerClick = (id) => {
    const accountManager = accountManagers.find((x) => x.id === id);
    setAccountManagerReplacing(accountManager);
    setReplacementFormOpen(true);
  };

  const handleCreationSuccess = () => {
    try {
      refetchAccountManagers();
    } catch {

    }
    closeModals();
  };

  const handleEditingSuccess = () => {
    try {
      refetchAccountManagers();
    } catch {

    }
    closeModals();
  };

  const handleReplacementSuccess = () => {
    try {
      refetchAccountManagers();
    } catch {

    }
    closeModals();
  };

  // Effects
  useEffect(() => {
    if (!isUndefined(user) && !loadingStrings && isNull(strings)) {
      fetchAccountManagersStrings(user.locale);
    }
  }, [user, loadingStrings, strings, fetchAccountManagersStrings]);

  useEffect(() => {
    updateAccountManagersLink();
  }, [accountManagerFilters, updateAccountManagersLink, pageSize]);

  // Rendering
  if (!strings) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col xs="12"><PageTitle>{strings.title}</PageTitle></Col>
      </Row>

      <Row>
        <Col md={10} xs={12}>
          <Space height="20px" />
          <Paragraph>
            {strings.subtitle}
          </Paragraph>
          <Space height="30px" />
        </Col>
      </Row>

      <Row>
        <FiltersBox>
          <Col xs={12}>
            <Row>
              <Col>
                <Row>
                  <Input
                    width={400}
                    className="mr-5 mb-2"
                    onChange={handleSearchChange}
                    onEnterKeyPress={handleApplySearchClick}
                    name="search"
                    value={selectedValues.search}
                    placeholder={strings.searchPlaceholder}
                  />
                  <Button
                    className="mr-5 mb-2"
                    theme="blue"
                    onClick={handleApplySearchClick}
                  >
                    {strings.searchButton}
                  </Button>
                </Row>
              </Col>

              <Col md={{ span: 1, offset: 2 }}>
                <Button
                  onClick={handleResetFiltersClick}
                >
                  {strings.resetButton}
                </Button>
              </Col>
            </Row>
          </Col>
        </FiltersBox>
      </Row>

      <Row
        className="mt-4"
      >
        <Col>
          <Button
            theme="blue"
            onClick={handleAddAccountManagerClick}
          >
            {strings.addAccountManagerButton}
          </Button>
        </Col>
      </Row>


      <Row
        className="mt-4"
      >
        <Col>
          {/* <ButtonDropdown
            btnSize="xs"
            title="Export"
            disable={loadingReport || totalResults === 0}
            onSelect={handleExportReportClick}
          >
            <MenuItem eventKey="xlsx">Excel</MenuItem>
            <MenuItem eventKey="csv">CSV</MenuItem>
          </ButtonDropdown> */}

          <PageSizeSelector
            sizes={pageSizes}
            selectedValue={pageSize}
            onChange={setPageSize}
          />
        </Col>
      </Row>

      <Row
        className="justify-content-center py-3"
        style={{ textAlign: 'center' }}
      >

        <LoadingIcon
          visible={loadingAccountManagers}
        />
      </Row>

      {(accountManagers.length === 0 && !loadingAccountManagers) && (
        <Row>
          <Col xs={12}>
            <Paragraph>{strings.noClientsText}</Paragraph>
          </Col>
        </Row>
      )}

      {(accountManagers.length > 0 && !loadingAccountManagers) && (
        <Row>
          <Col xs="12">
            <Paragraph>
              {strings.showingText
                .replace('{0}', `${(currentPage - 1) * pageSize} - ${currentPage * pageSize > totalResults ? totalResults : currentPage * pageSize}`)
                .replace('{1}', totalResults)}
            </Paragraph>

            <AccountManagersTable
              headingStrings={strings.tableHeadings}
              actionStrings={{
                edit: 'Edit',
                delete: 'Delete',
                replace: 'Replace',
              }}
              accountManagers={accountManagers}
              loading={loadingAccountManagers}
              sortBy={selectedValues.sortBy}
              sortDescending={selectedValues.sortDescending}
              onHeadingClick={handleTableHeadingClick}
              skeletonLoadingRows={pageSize}
              onReplaceClick={handleReplaceAccountManagerClick}
              onEditClick={handleEditAccountManagerClick}
              onDeleteClick={handleAccountManagerDeleteClick}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12}>
          <PageSelector
            currentPage={currentPage}
            maxPages={totalPages}
            onChange={handlePageChange}
          />
        </Col>
      </Row>

      {creationFormOpen && (
        <AccountManagerCreationFormContainer
          open={creationFormOpen}
          onCreationSuccess={handleCreationSuccess}
          onRequestClose={closeModals}
        />
      )}

      {editingFormOpen && (
        <AccountManagerEditingFormContainer
          open={editingFormOpen}
          onEditingSuccess={handleEditingSuccess}
          onRequestClose={closeModals}
          accountManager={accountManagerEditing}
        />
      )}

      {replacementFormOpen && (
        <AccountManagerReplacementFormContainer
          open={replacementFormOpen}
          onReplacementSuccess={handleReplacementSuccess}
          onRequestClose={closeModals}
          sourceAccountManager={accountManagerReplacing}
        />
      )}
    </div>
  );
};

export default AccountManagersPage;
