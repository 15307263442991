import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import useUser from 'Common/hooks/useUser';
import { Row, Col } from 'react-bootstrap';

import Heading from 'Common/components/Heading';
import Space from 'Common/components/Space';
import Text from 'Common/components/Text';
import Button from 'Common/components/Button';
import Input from 'Common/components/Input';

import { isUndefined } from 'Common/utils/utils';

import styles from './styles.module.css';

const Profile = () => {
  const { user } = useUser();

  return (
    <div className={styles.container}>
      <Heading title="Profile" />
      <Space height="3em" />

      <Text bold size="x-large">Account Information</Text>
      <Row>
        {!isUndefined(user) && (
          <>
            <Col sm={12} md={6}>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Firstname</Text></Col>
                <Col sm={8}><Text size="small">{user.firstName}</Text></Col>
              </Row>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Username</Text></Col>
                <Col sm={8}><Text size="small">{user.username}</Text></Col>
              </Row>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Password</Text></Col>
                <Col sm={8}><Text size="small">************</Text></Col>
              </Row>
            </Col>

            <Col sm={12} md={6}>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Lastname</Text></Col>
                <Col sm={8}><Text size="small">{user.lastName}</Text></Col>
              </Row>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Email Address</Text></Col>
                <Col sm={8}><Text size="small">{user.emailAddress}</Text></Col>
              </Row>
            </Col>
          </>
        )}
      </Row>

      <Text bold size="x-large">Contact Information</Text>
      <Row>
        {!isUndefined(user) && (
          <>
            <Col sm={12} md={6}>
              <Row className={styles.row}>
                <Col sm={3}><Text bold size="small">Mobile</Text></Col>
                <Col sm={8}><Text size="small">{user.mobile}</Text></Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Profile;
