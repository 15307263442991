export const login = {
  loginButton: 'Login',
  accessibilityLabels: {
    loginButton: 'Login to VBOP',
  },
  placeholders: {
    username: 'Username',
    accountNumber: 'Account Number',
    password: 'Password',
  },
  validationMessages: {
    emptyUsername: 'Please supply a username',
    emptyAccountNumber: 'An account number must be provided',
    emptyPassword: 'A password must be provided',
  },
  responseMessages: {
    usernameVerification: {
      success: 'Change was successfully made!',
      failed: 'Failed to Login. Please check your credentials and try again.',
      error: 'Unable to Login. Please try again later.',
    },
    login: {
      success: 'Change was successfully made!',
      failed: 'Failed to Login. Please check your credentials and try again.',
      error: 'Unable to Login. Please try again later.',
    },
  },
};

export const logout = {
  text: 'logging out',
};

export const notFound = {

};
