import styled from 'styled-components';

export default styled.div`
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  ${props => `
    background: url(${props.src}) no-repeat center center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
  `}
`;
