import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './styles.module.css';

const CountryFlag = ({
  country,
  className,
}) => (
  <img className={classnames(styles.flag, className)} src={require(`Common/assets/images/flags/${country.toLowerCase()}.svg`)} alt={`${country} country`} />
);

CountryFlag.propTypes = {
  country: PropTypes.string,
  className: PropTypes.string,
};

export default CountryFlag;
